import { AppError } from "./app-error.model";

export abstract class ResultBase {
    errorData?: AppError;
}

export class Result<T> extends ResultBase {
    data: T;
    errors?: any;
}

export class ResultModel extends ResultBase {
    errors?: any;
}

export interface IResult<TData, T extends Result<TData>> {         //IResult<Result<number>>
    error: T;
    data?: TData;
    status?: number;
}

export interface IResultModel<T extends ResultModel> {
    error: T;
    body?: T;
    status?: number;
}