import { GoalTypeEnum } from "../enums/goal-type.enum";

export interface GoalText {
    id?: number;
    name: string;
    goalType: GoalTypeEnum;
    isGlobal: boolean;
}

export class GoalTextModel implements GoalText {
    id? = null;
    name = '';
    goalType = GoalTypeEnum.Goal;    
    isGlobal = false;
}