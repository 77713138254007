import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { PositionService } from 'src/app/services';
import { Observable } from 'rxjs';
import { Position } from 'src/app/models/position.model';
import { PagedListResult } from 'src/app/models/paged-list-result.model';
import { Search } from 'src/app/models/search.model';

@Component({
  selector: 'app-simple-search-test',
  templateUrl: './simple-search-test.component.html',
  styleUrls: ['./simple-search-test.component.scss']
})
export class SimpleSearchTestComponent implements OnInit {

  filteredList: Observable<PagedListResult<Position>>;
  usersForm: FormGroup;
  selectedPosition: Position;

  constructor(private fb: FormBuilder, private positionService: PositionService) {}

  ngOnInit() {
    this.usersForm = this.fb.group({
      userInput: null,
    });

    this.filteredList = this.usersForm.get('userInput').valueChanges.pipe(
      debounceTime(300),
      switchMap((value) => {        
          const searchConfig = { search: value } as Search;
          return this.positionService.getList(searchConfig);
      }  )
    );
  }

  onUserInputChange(value: string) {
    // Handle the input change here, if needed
  }

  onItemSelected(item: Position) {
    this.selectedPosition = item; // Update the selected user
    this.usersForm.get('userInput').setValue(item); // Set the selected item in the form
  }

  change(): void {
    console.log('change');
    this.usersForm.get('userInput').setValue({ id: 1, name: 'Windstorm' });
  }
}
