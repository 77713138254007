export abstract class TextHelper {

    public static formatTextWrap(text: string, maxLineLength: number): string {
        const words = text.replace(/[\r\n]+/g, ' ').split(' ');
        let lineLength = 0;
                
        // use functional reduce, instead of for loop 
        return words.reduce((result, word) => {
          if (lineLength + word.length >= maxLineLength) {
            lineLength = word.length;
            
            return result + `\n${word}`; // don't add spaces upfront
          } else {
            lineLength += word.length + (result ? 1 : 0);
            return result ? result + ` ${word}` : `${word}`; // add space only when needed
          }
        }, '');
      }

      //a function that accepts a string, a maxLineLength as a parameter, and can wrap a string into an array of strings
        
      public static wrapStringIntoArray(str: string, maxLineLength: number): string[] {
        if (!str) return [];
        
        const words = str.split(' ');
        const lines = [];
        let currentLine = words[0];
      
        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          if (currentLine.length + word.length + 1 > maxLineLength) {
            lines.push(currentLine);
            currentLine = word;
          } else {
            currentLine += ' ' + word;
          }
        }
      
        lines.push(currentLine);
        return lines;
      }
 
    
}