import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IResult, Result } from 'src/app/models/general/result.model';
import { Kpi } from 'src/app/models/kpi.model';
import { KpiType } from 'src/app/models/kpiType.model';
import { KpiService } from 'src/app/services';
import { ErrorMessageService } from 'src/app/shared/services';

@Component({
  selector: 'app-kpi-type-add-dialog',
  templateUrl: './kpi-type-add-dialog.component.html',
  styleUrls: ['./kpi-type-add-dialog.component.scss']
})
export class KpiTypeAddDialogComponent implements OnInit {
 
  public mainForm: UntypedFormGroup;
  
  constructor( 
    private fb: UntypedFormBuilder, 
    public dialogRef: MatDialogRef<KpiTypeAddDialogComponent>,
    private kpiService: KpiService,
    private msgService: ErrorMessageService,
    @Inject(MAT_DIALOG_DATA) public data: string) { }

    ngOnInit(): void {
      this.mainForm = this.createForm();
    }
  
    private createForm(): UntypedFormGroup {
      return this.fb.group({        
        'name': [this.data, [Validators.required]],
        'unit': ['', [Validators.required]],
      });
    }
  
    public ok() {
      if (!this.mainForm.invalid) {
        let formData = this.mainForm.value as KpiType;
        formData.name = formData.name.trim();
        formData.unit = formData.unit.trim();
  
        this.kpiService.addKpiType(formData).subscribe({ next:(result) => {
          this.dialogRef.close(result.data);
        }, error: (err: IResult<KpiType, Result<KpiType>>) => {
          this.msgService.handleErrorWithData<KpiType, Result<KpiType>>(err);
        }});
      }
    }

}
