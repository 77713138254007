import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { EmployeeEventType } from 'src/app/enums/employee-event-type.enum';
import { EmployeeStatus } from 'src/app/enums/employee-status.enum';
import { EmployeeDeleteDto } from 'src/app/models/employee-delete.model';
import { IResultModel, Result, ResultModel } from 'src/app/models/general/result.model';
import { UserProfile } from 'src/app/models/user-profile.model';
import { AuthService, EmployeeService, StateService } from 'src/app/services';
import { ConfirmDialogComponent, IConfirmDialogModel } from 'src/app/shared/components/dialogs';
import { TEXTS } from 'src/app/shared/helpers/messages';
import { HtmlListItem } from 'src/app/shared/models/html-list-item.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { EmployeeState } from 'src/app/store/employee.state';

@UntilDestroy()
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  public model$: Observable<Result<boolean>>;
    
  public employeeStatus = EmployeeStatus;

  constructor(
    @Inject(TEXTS) public texts: {[key: string]: string},
    private route: ActivatedRoute, 
    private router: Router,
    private employeeStore: EmployeeState, 
    private stateService: StateService, 
    private employeeService: EmployeeService,
    private msgService: ErrorMessageService,
    private authService: AuthService,
    private dialog: MatDialog,
    )
  { }

  public ownProfile = false;
  public isLoggedInSA = false;  
  public isLoggedInAdmin = false;
  public isLoggedInAssistant = false;
  
  public get isDeleteInProgress(): boolean {
    return this.userProfile?.employee?.employeeEvent?.eventType == EmployeeEventType.Delete;
  }

  public get isSelf(): boolean {
    return this.userProfile?.id === this.stateService.state.me?.value.id;
  }

  public get isEmployeeInactive(): boolean {
    return this.userProfile?.employee?.employeeStatus === EmployeeStatus.Inactive;
  }

  public userProfile: UserProfile;

  // if the user is an sa, these panels should not be visible because they are not relevant (sa has no employee entity)
  public get selectedUserHasEmployee(): boolean {    
    return !!this.userProfile?.employee;
  }

  ngOnInit(): void {    
    this.model$ = this.employeeStore.employee.obs$.pipe(
      mergeMap(userProfile => {
        if (userProfile)
        {

          this.userProfile = userProfile;

          //if the logged in user is an SA or Admin
          this.ownProfile = userProfile.id === this.stateService.state.me.value.id;
          this.isLoggedInSA = this.stateService.state.isSuperAdmin;
          this.isLoggedInAdmin = this.stateService.state.isAdmin;
          this.isLoggedInAssistant = this.stateService.state.isAssistant;
          
          return this.employeeService.isBossOf(userProfile.id);
        }
        else
          return of(null);
      }),
      tap(result => {
        if (result) {
          this.employeeStore.isBoss.value = result.data;
        }
      })
    );    

    this.route.params
    .pipe(untilDestroyed(this))
    .subscribe(params => {
        this.employeeStore.loadEmployee(params['employeeId']).subscribe();
    });
            
  } 
      
  // public isAdminProfile(user: UserProfile): boolean {    
  //   return this.stateService.state.isSuperAdmin && user.id === this.stateService.state.me.value.id;
  // }

  public markForDelete(): void {
      const dto = {
        employeeId: this.userProfile.employee.id
      } as EmployeeDeleteDto;
            
      var lines = this.getDeleteDialogLines();

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "600px",
        data: {
            title: 'Felhasználó törlése',
            lines: lines,
            confirmButtonConfig: {name: 'Törlés', color: 'warn'},
        } as IConfirmDialogModel
      });

      dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
        if (dialogResult)
        {
          this.authService.deleteEmployee(dto).subscribe({next: () => {
            this.msgService.showSnackBarMessage('deleteEmployeeSuccessful');
            this.router.navigate(['/']);
          }, error: (err: IResultModel<ResultModel>) => {
            this.msgService.handleError<ResultModel>(err);
          }});
        }
     });

      
  }

  public getDeleteDialogLines(): HtmlListItem[] {
    let lines: HtmlListItem[] = [];

    lines.push({text: this.texts['deleteEmployeeDialogLine1']} as HtmlListItem);
    lines.push({text: this.texts['deleteEmployeeDialogLine2'], classList: ['text-danger']} as HtmlListItem);
    lines.push({text: this.texts['deleteEmployeeDialogLine3']} as HtmlListItem);
    lines.push({text: this.texts['deleteEmployeeDialogLine4'], classList: ['text-danger']} as HtmlListItem);

    return lines;
  }

  public getDeleteMessage(): string {
    let msg = '';

    if (this.userProfile.company == 'Magánszemélyek')
    {
      msg = 'A dolgozó adatai és Sziluett eredményei törlésre kerülnek a Magánszemélyek csoportból. ';
    } 
    else
    {      
        msg = 'A dolgozó adatai és kitöltött teszteredményei törlésre kerülnek ebből a cégből. A felhasználói fiók átkerül a magánszemélyek csoportba. ';
    }

    msg += 'Folytatja?';
     
    return msg;
  }

  public cancelDelete(): void {
    this.authService.cancelDeleteEmployee(this.userProfile.employee.id).subscribe({next: () => {
      this.msgService.showSnackBarMessage('successfulAction');

      this.employeeStore.loadEmployee(this.userProfile.id).subscribe();
    }, error: () => {
      this.msgService.showSnackBarMessage('unsuccessfulAction');
    }});
  }

}
