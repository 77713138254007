<mat-expansion-panel class="lg-panel" >
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Csapatcélok <span class="text-alt1">({{ groupGoals?.length ?? 0 }})</span></strong>
        </mat-panel-title>
    </mat-expansion-panel-header>
    
    <div *ngIf="loaded && data">
        <div *ngFor="let groupGoal of groupGoals">
            <app-group-goal-edit 
                [groupGoal]="groupGoal"
                [data]="data"
                (added)="itemAdded($event)"
                (deleted)="itemDeleted($event)"
            ></app-group-goal-edit>
        </div>      
    
        <div class="show-add-button" *ngIf="data.isSelf">
            <button mat-flat-button color="primary" (click)="addNew()">
                <mat-icon>add</mat-icon>
                Csapatcél
            </button>
        </div>
    </div>    

</mat-expansion-panel>
