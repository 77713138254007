import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeePosition } from 'src/app/models/employee-position.model';
import { Position } from 'src/app/models/position.model';
import { UserProfile } from 'src/app/models/user-profile.model';
import { CardComponent, SearchComponent } from 'src/app/shared/components';
import { ComponentState } from 'src/app/shared/enum/component-state.enum';
import { ListItem } from 'src/app/shared/models/list-item.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { EmployeeState } from 'src/app/store/employee.state';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { EmployeePositionService, PositionService, StateService } from 'src/app/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmployeePositionCreateDelete, EmployeePositionGet } from 'src/app/models/employee-position-create-delete.model';
import { MatDialog } from '@angular/material/dialog';
import { ActionForReviewPeriodDialogComponent } from 'src/app/dialogs/action-for-review-period-dialog/action-for-review-period-dialog.component';
import { ActionForReviewPeriodDialogConfig } from 'src/app/models/action-for-review-period-dialog-config.model';
import { State } from 'src/app/models/state.model';

@UntilDestroy()
@Component({
  selector: 'app-employee-position',
  templateUrl: './employee-position.component.html',
  styleUrls: ['./employee-position.component.scss']
})
export class EmployeePositionComponent implements OnInit {

  @Input()
  public model: UserProfile;

  public itemsSubject = new BehaviorSubject<ListItem<EmployeePosition>[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();
      
  public searchMemberConfig: SearchConfig<Position, number> = {
    mapItem: (model: Position) => {
      return {
        id: model.id, 
        text: model.name,
        dropText: model.name,
        itemSource: model } as SearchOption<Position, number>;
    },
    getList: this.positionService.getList
  };  
  
  @ViewChild('card')
  card: CardComponent;

  @ViewChild('search')
  search: SearchComponent<Position, number>;

  public state: State;

  public get allowEdit(): boolean {
    return this.state.isSuperAdmin || this.state.isAdmin || (this.employeeStore.isBoss.value && this.employeeStore.employee.value.hasBoss);
  }

  public get allowAdd(): boolean {
    return this.itemsSubject.getValue().length === 0 || this.itemsSubject.getValue().every(x => x.state === ComponentState.View || x.state === ComponentState.Disabled);
  }

  constructor(    
    private employeePositionService: EmployeePositionService, 
    private positionService: PositionService,
    private employeeStore: EmployeeState, 
    stateService: StateService,
    private msgService: ErrorMessageService,
    private dialog: MatDialog,
    ) {
      this.state = stateService.state;
  }

  ngOnInit(): void {

    combineLatest([this.employeeStore.employee.obs$, this.employeeStore.reviewPeriod.obs$])
    .pipe(untilDestroyed(this))
    .subscribe(([emp, rp]) => {
        this.loadList();
    });   
     
  }
  
  private loadList() {
    if (!this.employeeStore.employee.value) return;

    const dto = {
      userRef: this.employeeStore.employee.value.id,      
      reviewPeriodId: this.employeeStore.reviewPeriod?.value?.id
    } as EmployeePositionGet;

    this.employeePositionService.getListByEmployee(dto).pipe(
      map(list => list.map(x => {
        let item = new ListItem(x);

        if (!!item.itemSource.endDate)
          item.state = ComponentState.Disabled;

        return item;
      })),
    )
    .pipe(untilDestroyed(this))
    .subscribe(itemArray => {
      this.itemsSubject.next(itemArray);
    });
  }

  public deleteItem(item: ListItem<EmployeePosition>) {

    const dialogConfig: ActionForReviewPeriodDialogConfig = {
      title: 'Munkakör törlése',
      message: 'Biztosan törölni szeretné a munkakört?',
    }

    const dialogRef = this.dialog.open(ActionForReviewPeriodDialogComponent, {
      minWidth: "500px",
      data: dialogConfig
    });

    dialogRef.afterClosed().subscribe( (dialogResult: number[]) => {
      if (dialogResult)
      { 

        const dto = {
          userRef: item.itemSource.userRef,
          positionId: item.itemSource.position.id,
          reviewPeriodList: dialogResult
        } as EmployeePositionCreateDelete;
    
        this.employeePositionService.deleteData(dto).subscribe(result => {      
          this.loadList();
    
          this.msgService.showSnackBarMessage('deleteSuccessful');
        }, error => {
          this.msgService.showErrorResponse(error);
        });

      }
    });
    
  }
      
  public addItem(option: SearchOption<Position, number>) {
    const dialogConfig: ActionForReviewPeriodDialogConfig = {
      title: 'Munkakör hozzáadása',
      message: 'Biztosan hozzá szeretné rendelni a munkakört?',
    }

    const dialogRef = this.dialog.open(ActionForReviewPeriodDialogComponent, {
      minWidth: "500px",
      data: dialogConfig
    });

    dialogRef.afterClosed().subscribe( (dialogResult: number[]) => {
      if (dialogResult)
      { 

        const dto = {
          userRef: this.employeeStore.employee.value.id,
          positionId: option.itemSource.id,
          reviewPeriodList: dialogResult
        } as EmployeePositionCreateDelete;
    
        this.employeePositionService.addData(dto).subscribe(data => {
          this.loadList();
        }, error => {
          this.msgService.showErrorResponse(error);
        });

      }
    });
    
  }

  public isArchive(item: ListItem<EmployeePosition>): boolean {
    return !!item.itemSource.endDate;
  }

  onOptionSelected() {

  }

}
