import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuestionsForAssessment } from '../models/questions-for-assessment.model';
import { EmployeeForAssessments } from '../models/employee-for-assessments.model';

import { DataService } from './data.service';
import { StateService } from './state.service';
import { Assessment } from '../models/assessment.model';
import { Result, ResultModel } from '../models/general/result.model';
import { CompleteReview } from '../models/complete-review.model';
import { Search } from '../models/search.model';
import { PagedListResult } from '../models/paged-list-result.model';
import { OpenAssessmentEmployee } from '../models/open-assessment-employee.model';
import { EmployeeAssessment } from '../models/employee-assessment.model';
import { ReviewPeriodEmployeeForCreate } from '../models/review-period-employee-for-create.model';
import { ReviewPeriodEmployeeAssignment } from '../models/review-period-employee-assignment.model';

@Injectable({
    providedIn: 'root',
})
export class AssessmentService extends DataService {

    constructor(http: HttpClient, stateService: StateService) {
        super(http, stateService);
    }
            
    public getAssessmentData(reviewPeriodEmployeeId: number): Observable<Result<QuestionsForAssessment>> {
        const url = `/api/assessments/assessment/${reviewPeriodEmployeeId}`;
        return this.get<Result<QuestionsForAssessment>>(url);
    }

    public submit(data: QuestionsForAssessment): Observable<void> {
        return this.post('/api/assessments/submit', data);
    }

    public saveSingleAssessment(data: Assessment): Observable<Result<number>> {
        return this.post('/api/assessments/saveSingleAnswer', data);
    }

    public saveFeedback(reviewPeriodEmployeeId: number, feedback: string): Observable<void> {
        return this.post('/api/assessments/saveFeedback', { reviewPeriodEmployeeId, feedback });
    }

    public completeReview(data: CompleteReview): Observable<ResultModel> {
        return this.post<ResultModel>('/api/assessments/completeReview', data);
    }

    public getName(employee: EmployeeForAssessments): string {
        let name = "";
        if (employee) {
            if (employee.isSelf) return "Önértékelés";
            if (employee.isLeader && employee.email !== "p.szauer@hvg.hu" && !this.state.config.c.assessment.showLeaderName) return "Divízió vezető";
            name = employee.name + " (" + employee.email + ')';
            if (employee.isContractor) name = name + " - szerződéses";
        }
        return name;
    }    

    public getOpen(dto: Search): Observable<PagedListResult<OpenAssessmentEmployee>> {
        return this.post<PagedListResult<OpenAssessmentEmployee>>('/api/assessments/open', dto, {headers: {'X-Skip-Interceptor':'1'}});
    }

    public getCompleted(dto: Search): Observable<PagedListResult<EmployeeAssessment>> {
        return this.post<PagedListResult<EmployeeAssessment>>('/api/assessments/completed', dto);
    }

    public getEmployeesToReview(reviewPeriodId: number): Observable<EmployeeForAssessments[]> {
        return this.get<EmployeeForAssessments[]>('/api/assessments/employeesToReview/' + reviewPeriodId.toString());
    }

    public addReviewPeriodEmployee(rpe: ReviewPeriodEmployeeForCreate): Observable<ResultModel> {
        return this.put<ResultModel>('/api/assessments/add-review-period-employee', rpe);
    }

    public updateReviewPeriodEmployee(rpe: ReviewPeriodEmployeeForCreate): Observable<ResultModel> {
        return this.post<ResultModel>('/api/assessments/update-review-period-employee', rpe);
    }

    // ReviewPeriodEmployee Assignment Dialog

    public getEmployeeAssignmentForAdd(reviewPeriodId: number): Observable<ReviewPeriodEmployeeAssignment> {
        return this.get<ReviewPeriodEmployeeAssignment>('/api/assessments/get-employee-assignment-for-add/' + reviewPeriodId);
    }

    public getEmployeeAssignmentForEdit(reviewPeriodEmployeeId: number): Observable<ReviewPeriodEmployeeAssignment> {
        return this.get<ReviewPeriodEmployeeAssignment>('/api/assessments/get-employee-assignment-for-edit/' + reviewPeriodEmployeeId);
    }

    public deleteReviewPeriodEmployee(reviewPeriodEmployeeId: number): Observable<ResultModel> {
        return this.delete<ResultModel>('/api/assessments/delete-review-period-employee/' + reviewPeriodEmployeeId);
    }
    
}
