import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-only-field',
  templateUrl: './read-only-field.component.html',
  styleUrls: ['./read-only-field.component.scss']
})
export class ReadOnlyFieldComponent implements OnInit {

  @Input()
  public icon: string;

  @Input()
  public label: string;
  
  @Input()
  public value: string;

  public get displayValue(): string {
    return this.value ? this.value : this.label;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
