import { AbstractControl, FormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ISelectable } from 'src/app/models/general/selectable.model';
import { DataTypeHelper } from './data-type.helper';

export abstract class CustomValidators {

    public static numeric(control: AbstractControl): ValidationErrors | null {        
        if (DataTypeHelper.isEmpty(control.value)) { return null; }
    
        const text: string = control.value;
        return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(text) ? null : { numeric: true };
      }
      
    public static integer(control: AbstractControl): ValidationErrors | null {        
        if (DataTypeHelper.isEmpty(control.value)) { return null; }
    
        const text: string = control.value;
        return /^-?\d+$/.test(text) ? null : { notInteger: true };
    }

    public static equalAs(fieldKey: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.parent) return null;
            const otherField = (control.parent as UntypedFormGroup).controls[fieldKey];

            if (control.value != otherField.value)
            {
                let element = (otherField as any).nativeElement; 
                let title = element.attributes['placeholder']?.value;

                return {
                    notEqual: { key: fieldKey, title: title }
                };
            }
            else
                return null;
        };
    }

    public static minSelectedCountArray = (min: number) => {
        return (c: FormArray): {[key: string]: any} => {
          const selectedItems = (c.value as ISelectable[]).filter(x => x.isSelected);
            
          if (selectedItems.length >= min)
            return null;
      
          return { MinSelectedCountArray: true};
        }
    }

    public static minLengthArray = (min: number) => {
        return (c: AbstractControl): {[key: string]: any} => {
          if (c.value.length >= min)
            return null;
      
          return { MinLengthArray: true};
        }
      }

    public static matchValues(field1: string, field2: string): ValidatorFn {
        return (group: UntypedFormGroup): ValidationErrors | null => {
            const control1 = group.controls[field1];
            const control2 = group.controls[field2];
            if (!control1.dirty || !control2.dirty) return null;

            if (control1.value === control2.value)
                return null;
            else
            {
                control2.setErrors({ noPassswordMatch: true });
                return { notMatched: true };
            }
        }
    }

    public static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
          if (!control.value) {
            // if control is empty return no error
            return null;
          }
      
          // test the value of the control against the regexp supplied
          const valid = regex.test(control.value);
      
          // if true, return no error (no error), else return error passed in the second parameter
          return valid ? null : error;
        };
    }
}
