import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ChartLegendItem, PersonLegend } from 'src/app/models/report/person-legend.model';
import { ReportOptions } from 'src/app/models/report/report-options.model';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {
  
  @Input()
  public headerRatio21 = false;

  @Input()
  showLegend = false;

  @Input()
  showLegendIcon = true;
    
  
  @Input() 
  legend: PersonLegend[] | ChartLegendItem[] = [];

  @Input()
  reportOptions: ReportOptions;
  
  constructor() { }

  ngOnInit(): void {
  }

}
