import { GroupGoal, GroupGoalModel } from "./group-goal.model";

export class GroupGoalsData {
    reviewPeriodId: number;
    employeeId: number;

    isSelf: boolean;
    loading: boolean;
    groupGoals: GroupGoal[];
    
    isAddGroupGoal = false;
    newGroupGoal = new GroupGoalModel();
}