import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { ReviewPeriod } from "../models/review-period.model";
import { UserProfile } from "../models/user-profile.model";
import { EmployeeService, StateService } from "../services";
import { ReviewPeriodService } from "../services/review-period.service";
import { ReviewPeriodHelper } from "../helpers/review-period.helper";
import { StateValue } from "../shared/models/state-value.model";
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class EmployeeState {   
    public readonly employee = new StateValue<UserProfile>(null);
    public readonly reviewPeriodList = new StateValue<ReviewPeriod[]>(null);
    public readonly reviewPeriod = new StateValue<ReviewPeriod>(null);

    //is the logged in user boss of the selected "employee"
    public readonly isBoss = new StateValue<boolean>(false);

    public personNameToAdd: string;
  
    constructor(
        private authService: AuthService, 
        private employeeService: EmployeeService,
        private stateService: StateService, 
        private reviewPeriodService: ReviewPeriodService
        ) {
    }

    loadEmployee(id: string): Observable<UserProfile> {        
        let user$: Observable<UserProfile>;
        
        if (id)
        {
            user$ = this.authService.getEmployee(id);
        }
        else
        {
            user$ = this.stateService.state.me.obs$;
        }

        return user$.pipe(
            tap(data => {
                this.employee.value = data;
            })
        );
    }

    loadReviewPeriodList(): Observable<ReviewPeriod[]> {
        if (!this.employee.value) return of([]);

        return this.reviewPeriodService.getReviewPeriodList(this.employee.value.id).pipe(
            tap(list => {
                this.reviewPeriodList.value = list;

                // if (!this.reviewPeriod.value) {
                //     this.reviewPeriod.value = ReviewPeriodHelper.getCurrentReviewPeriod(list);
                // }
            })
        );
    }

    updateEmployeeName(model: UserProfile): Observable<void> {
        return this.employeeService.editName(model).pipe(
            tap(() => {                
                this.employee.value = model;

                if (this.stateService.state.me.value.id == model.id) {
                    this.stateService.updateMyName(model.employee.name);
                }
            })
        );
    }   
}