<app-card #card title="Szerepkörök" [useExternalButtons]="true" [showEdit]="true" [editDisabled]="!allowEdit">

    <div>
        <div>
            <mat-form-field appearance="standard">
                <mat-label>Cégkereső</mat-label>
                <input matInput type="text" [formControl]="searchControl" autocomplete="off">
                <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Törlés" (click)="clearText()">
                  <mat-icon>close</mat-icon>
                </button>                
            </mat-form-field>

            <ng-container *ngIf="items$ | async as items">
                <div class="message-panel">
                    {{ searchMessage }}
                </div>
                <div class="scroll-panel height-medium padding-xs">
                    <app-dynamic-list-item [actionState]="card.actionState" [inlineEdit]="false" [disableActions]="!isAllowChange" disableActionsMessage="Saját szerepkör nem módosítható"
                        (edit)="rowEdit(item.itemSource)"
                        (delete)="rowDelete(item.itemSource)"
                        *ngFor="let item of items; index as i" [index]="i" [source]="item">
                        <div slot="readonly">
                            <div class="flex-horizontal mainitems" >
                                <div class="field-company" title="Cég">{{ item.itemSource.companyName }}</div>
                                <div class="field-role" title="Szerepkör">{{ item.itemSource.roleName | roleDisplayName }}</div>
                            </div>
                        </div>
                    </app-dynamic-list-item>
                </div>
                <mat-paginator #paginator [length]="total"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="pagingSettingsChanged($event)"
                            aria-label="Válasszon oldalt">
                </mat-paginator>

                <button mat-icon-button color="primary" (click)="add()" *ngIf="card.actionState.editMode" [disabled]="!isAllowChange" [title]="isAllowChange ? '' : 'Saját szerepkör nem módosítható'">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
                
            </ng-container>
            
        </div>        
    </div>

</app-card>
