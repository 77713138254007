import { InjectionToken } from '@angular/core';
import { Message, MessageType } from '../models';

const messages: {[key: string]: Message} = {
      addSuccessful: { text: "A hozzáadás sikeresen megtörtént", type: MessageType.success },
      deleteSuccessful: { text: "Sikeres törlés", type: MessageType.success },
      cacheDeleted: { text: "A cache törölve.", type: MessageType.success },
      deleteEmployeeSuccessful: { text: "A felhasználó törlésre került.", type: MessageType.success },
      
      badEmail: { text: "Hibás email cím", type: MessageType.error },
      badDeadline: { text: "Hibás határidő", type: MessageType.error },

      importError: { text: "Hiba történt az importálás során", type: MessageType.error },
      importHasNoPositions: { text: "Az import fájl nem tartalmaz munkaköröket", type: MessageType.error },
      importStructureError: { text: "Hibás import file formátum.", type: MessageType.error },
      importWrongCompany: { text: "Az import fájlban jelzett cégnév nem egyezik a kiválasztott céggel", type: MessageType.error },
      importDataError: { text: "Hibás tételek az import fájlban. Kérem javítsa őket és próbálja újra.", type: MessageType.error },
      importHasSomeError: { text: "Az importálás részlegesen sikerült, de a következő tételek nem lettek importálva, kérem javítsa őket és próbálja újra.", type: MessageType.error },

      emailSendError: { text: "Email küldési hiba", type: MessageType.error },
      emailSendSuccess: { text: "Az email sikeresen elküldve", type: MessageType.success },
      emailSendMassStarted: { text: "Az email küldési folyamat elindítva", type: MessageType.success },
      forgottenPasswordError: {text: "Hibás email cím vagy a felhasználó nem található.", type: MessageType.error},
      passwordChangeSuccess: {text: "A jelszó sikeresen megváltoztatva.", type: MessageType.success},
      employeeExistsInDefaultCompany: {text: "A dolgozó már létezik a Magánszemélyek nevű cégben. Kérem először migrálja át onnan a kiválasztott cégbe, majd ismételje meg az importot.", type: MessageType.error},
      employeeExistsInOtherCompany: {text: "A dolgozó már létezik egy másik cégben is.", type: MessageType.error},

      unsuccessfulAction: { text: 'Sikertelen művelet', type: MessageType.error },
      companyNotFound: { text: 'A cég nem található', type: MessageType.error },
      
      changeReviewPeriodStatusFailed: { text: 'Időszak állapot váltás sikertelen.', type: MessageType.error },

      noUser: { text: 'Nem létező felhasználó.', type: MessageType.error },
      noEmployee: { text: 'Nem létező dolgozó.', type: MessageType.error },
      emailAlreadyExists: { text: 'Hiba: Az email cím már létezik a rendszerben.', type: MessageType.error},
      noToken: { text: 'Hiba: A token nincs megadva.', type: MessageType.error },
      noDeleteEvent: { text: 'A törlési folyamat nem található vagy már korábban le lett állítva.', type: MessageType.error },
      userNotActivated: { text: 'Nem aktivált felhasználó. Lépjen kapcsolatba az adminisztrátorokkal!', type: MessageType.error },
      loginFailed: { text: 'Hibás email és/vagy jelszó', type: MessageType.error },
      insufficientPrivileges: { text: 'Nincs megfelelő jogosultsága a művelet végrehajtásához', type: MessageType.error },
      employeeAlreadyExistsInCompany: { text: 'Ez a dolgozó már létezik ebben a cégben.', type: MessageType.error },
      employeeAlreadyExistsInOtherCompany: { text: 'Ez a dolgozó már létezik egy másik cégben.', type: MessageType.error },
      canNotUpdateOwnRole: {text:'Saját szerepkör nem módosítható', type: MessageType.error },
      canNotDeleteOwnRole: {text:'Saját szerepkör nem törölhető', type: MessageType.error },
      doesNotHaveRoleInCompany: {text: 'Nincs a cégben szerepköre', type:MessageType.error},
      userAlreadyHasRoleInCompany: {text: 'A felhasználó már rendelkezik szerepkörrel a cégnél', type:MessageType.error},
      assessmentExpired: {text: 'Az értékelés határideje lejárt.', type: MessageType.error},
      assessmentClosed: {text: 'Az értékelés lezárult.', type: MessageType.error},
      notAllQuestionHasAnswer: {text: 'Nincs minden kérdés megválaszolva kérem ellenőrizze a kitöltött kérdőívet.', type: MessageType.error},
      kpiTypeAlreadyExist: {text: 'Ez a KPI típus már létezik.', type: MessageType.error},
      kpiUpdateTypeRequired: {text: 'Ez a KPI már használatban van, módosítsuk minden esetben vagy a kívánt változtatások csak ezt a példányt érintsék?', type: MessageType.warning},

      goalPeriodOpen: {text: 'Az egyéni célok szerkesztési lehetősége megnyílt.', type: MessageType.info},
      goalPeriodClosed: {text: 'Az egyéni célok szerkesztési lehetősége bezárult.', type: MessageType.info},      

      canNotDetectAssessmentType: {text: 'A megadott személyek között nem találtunk kapcsolatot, kérjük rögzítse vagy manuálisan adja meg az értékelés irányát.', type: MessageType.error},
      reviewPeriodCreated: {text: 'Az új időszak létrejött', type: MessageType.success},
      canNotCreateInCompany: {text: 'A bejegyzés nem hozható létre a kiválasztott cégben.', type: MessageType.error},
      rpeNotFound: {text: 'Az értékelés nem található.', type: MessageType.error},
      rpeStatusNotNew: {text: 'A műveletet már folyamatban levő értékelésen nem lehet elvégezni.', type: MessageType.error},

      noUserSelected: {text: 'Kérem válasszon ki egy dolgozót a listából.', type: MessageType.error},
      noEmployeeGroupSelected: {text: 'Kérem válasszon ki vagy hozzon létre egy csapatot.', type: MessageType.error},
      noPositionForEmployee: {text: 'A kiválasztott személynek nincs beállított munkaköre.', type: MessageType.error},
      noQuestionSetForPosition: {text: 'A kiválasztott személy munkaköréhez nem tartozik kérdőív', type: MessageType.error},
      importReviewTypeMismatch: {text: 'A kiválasztott értékelési időszak típusa nem egyezik az import típusával.', type: MessageType.error},
      actionFailedBecauseReviewPeriodIsClosed: {text: 'A művelet nem hajtható végre, mert az értékelés lezárt.', type: MessageType.error},
      noDeleteDataExistInRp: {text: 'A törlés nem hajtható végre, mert már rögzítve lettek adatok az értékelésben.', type: MessageType.error},

      passwordReminderSent: {text: 'Az email címre elküldtük a visszaállításhoz szükséges instrukciókat.', type: MessageType.success},
      saveSuccessful: { text: 'A változások mentésre kerültek.', type: MessageType.success },
      saveFailed: { text: 'Sikertelen mentés.', type: MessageType.error },
      successfulAction: { text: 'A művelet sikeresen lefutott.', type: MessageType.success },
      answerNotSaved: { text: 'Hiba: A válasz nem került mentésre. Kérem ismételje meg.', type: MessageType.error },
      errorCreatingReviewPeriod: { text: 'Hiba: Az értékelés nem került létrehozásra.', type: MessageType.error },

      migrateTooManyReviewPeriods: { text: 'Hiba: A kiválasztott dolgozók több értékelésben is részt vesznek, így a migráció nem folytatható.', type: MessageType.error },

      exportError: {text: 'Hiba történt az exportálás során.', type: MessageType.error },
      unknownError: {text: 'Ismeretlen hiba történt.', type: MessageType.error},

      saved: {text: 'mentve', type: MessageType.success, icon: 'check_circle', duration: 2000},
      chooseToProceed: {text: 'kérem válasszon a folytatáshoz', type: MessageType.warning},
};

const texts: {[key: string]: string} = {
  deleteEmployeeBatchLine1: 'Kérjük, hogy a felhasználói fiók törlése funkciót akkor használd, ha megszűnt a jogalapja annak, hogy a kiválasztott személyek személyes adatait (nevét, e-mailcímét) és riportjait a quantille tovább tárolja (pl. ha kollégaként elment a cégtől, vagy jelöltként nem rá esett a választás).',
  deleteEmployeeBatchLine2: "Az 'Indítás' gombra kattintva a kiválasztott személyek felhasználói fiókja véglegesen és visszavonhatatlanul eltávolításra kerül a cégből. Ezzel a művelettel végérvényesen törlöd a quantille céges rendszeréből a kiválasztott személyek személyes adatait és az összes felmérésük/értékelésük riportját.",
  deleteEmployeeBatchLine3: 'A törlésről a felhasználóknak e-mailben értesítést küldünk, és aki 15 napon belül kéri, annak kizárólag a Sziluett személyiségfelmérés riportjait megőrizzük egy cégfüggetlen, személyes felhasználói fiókban. A Sziluett eredményének személyes megőrzéséhez minden felhasználónak joga van.',
  
  deleteEmployeeDialogLine1: 'Kérjük, hogy a felhasználói fiók törlése funkciót akkor használd, ha megszűnt a jogalapja annak, hogy a kiválasztott személyek személyes adatait (nevét, e-mailcímét) és riportjait a quantille tovább tárolja (pl. ha kollégaként elment a cégtől, vagy jelöltként nem rá esett a választás).',
  deleteEmployeeDialogLine2: "Az 'Törlés' gombra kattintva a kiválasztott személy felhasználói fiókja véglegesen és visszavonhatatlanul eltávolításra kerül a cégből. Ezzel a művelettel végérvényesen törlöd a quantille céges rendszeréből a kiválasztott személy személyes adatait és az összes felmérése/értékelése riportját.",
  deleteEmployeeDialogLine3: 'A törlésről a felhasználónak e-mailben értesítést küldünk, és ha 15 napon belül kéri, kizárólag a Sziluett személyiségfelmérés riportjait megőrizzük egy cégfüggetlen, személyes felhasználói fiókban. A Sziluett eredményének személyes megőrzéséhez minden felhasználónak joga van.',
  deleteEmployeeDialogLine4: 'Biztos vagy benne, hogy végérvényesen törlöd a felhasználói fiókot?',
};

const errorList = {
    min: ({ min }) =>           `A szám kisebb mint: ${min}`,
    max: ({ max }) =>           `A szám kisebb mint: ${max}`,
    numeric: () =>          `Egész szám beírása szükséges`,
    required: (error) =>    `A mező kitöltése kötelező`,
    minlength: ({ requiredLength, actualLength }) => `A szöveg rövidebb mint ${requiredLength} karakter`,
    maxlength: ({ requiredLength, actualLength }) => `A szöveg hosszabb mint ${requiredLength} karakter`,
    email: () =>            `Az e-mail cím formátuma nem megfelelő`,
    pattern: () =>          `A beírt szöveg formátuma nem megfelelő`,
    date: () =>             `A dátum nem megfelelő`,
    notMatched: () =>      `A két érték nem egyezik`,
    noPassswordMatch: () => 'A két jelszó nem egyezik'
}

export const  FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => errorList
});

export const MESSAGES = new InjectionToken('MESSAGES', {
  providedIn: 'root',
  factory: () => messages
});

export const TEXTS = new InjectionToken('TEXTS', {
  providedIn: 'root',
  factory: () => texts
});

export { MessageType };
