<h4 *ngIf="pageTitle" class="text-center">{{pageTitle}}</h4>

<validation-messages [msgList]="messages"></validation-messages>

<form name="form" [formGroup]="formGroup" role="form">
    <ng-content></ng-content>
</form>

<div class="row actions" *ngIf="isVisibleActionsBar">
  <div class="col-sm-12">
    <button mat-flat-button color="primary" *ngIf="isVisibleOkButton" [disabled]="formGroup.invalid" (click)="ok($event)" i18n >Ok</button>
    <button mat-flat-button color="primary" *ngIf="isVisibleSaveButton" [disabled]="formGroup.invalid" (click)="save($event)" i18n >Mentés</button>
    <button mat-flat-button (click)="cancel($event)">{{ cancelTitle }}</button>
  </div>

  <div *ngIf="isVisibleCustomButtons">
    <ng-content select="[app-form-custom-buttons]"></ng-content>
  </div>
</div>      