<h1 mat-dialog-title>
    {{title}}
</h1>

<div class="content-panel">
    <mat-selection-list [multiple]="false" class="custom">
        <mat-list-option *ngFor="let item of items" [value]="item" (click)="onSelect(item)">
            <div [innerHTML]="item.text"></div>
        </mat-list-option>
    </mat-selection-list>
</div>

<div mat-dialog-actions class="flex-h flex-end">    
    <button mat-flat-button [mat-dialog-close]>Vissza</button>
</div>
