<div class="frame" [ngClass]="{'no-background': isNoBackground}">
    <header>
        <mat-card class="report">
            <app-header [title]="reportTypeName"></app-header>
            <app-sub-header [headerRatio21]="headerRatio21" [reportOptions]="reportOptions" [legend]="legend" [showLegend]="showLegend" [showLegendIcon]="showLegendIcon"></app-sub-header>
        </mat-card>
    </header>
           
    <main [ngClass]="{'no-content-rules': noContentCss }">
        <table class="print-table">
            <thead>
                <tr>
                    <td>                                
                        <div class="header-space"></div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <ng-content></ng-content>
    
                        <!-- <div class="report-details" [ngClass]="{'no-background': isNoBackground}">
                        </div> -->
    
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <!--placeholder for the fixed-position footer-->
                        <div class="footer-space"></div>
                    </td>
                    </tr>
            </tfoot>
        </table>
    
    </main>
    
    <footer>
        <div class="margin-area"></div>
        <mat-card>
            <mat-card-content>
                <div class="left-block"></div>
                <div class="text-center">
                    © quantille Pro Kft.  minden jog fenntartva
                </div>
                <div class="right-block"></div>
            </mat-card-content>
        </mat-card>
    </footer>
</div>
