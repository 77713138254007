import { Injectable } from "@angular/core";
import { ReviewTypeEnum } from "../enums/review-type.enum";
import { TextGuide } from "../models/text-guide.model";
import { ReportOptions } from "../models/report/report-options.model";

@Injectable({
    providedIn: 'root'
})
export class TextGuideService {

    private textGuides: TextGuide[] = [
        {   key: 'assessment:TER', 
            title: 'Panoráma és Aura',
            titleIndexText: '®',
            subtitle: 'folyamatos teljesítménymenedzsment',
            logoLink: '/assets/img/logo-panorama.png',
            reviewType: ReviewTypeEnum.TER, 
            category: 'assessment', 
            urls: ['/config/html/guide-panorama-tajekoztato.html', '/config/html/guide-panorama-technikai-segitseg.html'],
            rightPanelRatio: 48
        },
        {   key: 'assessment:SZILUETT', 
            title: 'Sziluett',
            titleIndexText: '®',
            subtitle: 'komplex személyiségfelmérés',
            logoLink: '/assets/img/logo-sziluett.png',
            reviewType: ReviewTypeEnum.PersonalityTest,
            category: 'assessment', 
            urls: ['/config/html/guide-sziluett-tajekoztato.html', '/config/html/guide-sziluett-technikai-segitseg.html'],
            rightPanelRatio: 45
        },
        {   key: 'assessment:MilioTeamDysfunction', 
            title: 'Miliő',
            titleIndexText: '®',
            subtitle: 'csapatoptimalizálás',
            logoLink: '/assets/img/logo-milio.png',
            reviewType: ReviewTypeEnum.MilioTeamDysfunction,
            category: 'assessment', 
            urls: ['/config/html/guide-milio-diszfunkciok-tajekoztato.html', '/config/html/guide-milio-diszfunkciok-technikai-segitseg.html'],
            rightPanelRatio: 38
        },
        {   key: 'assessment:MilioStressMap', 
            title: 'Miliő',
            titleIndexText: '®',
            subtitle: 'csapatállapot-felmérés',
            logoLink: '/assets/img/logo-milio.png',
            reviewType: ReviewTypeEnum.MilioStressMap,
            category: 'assessment', 
            urls: ['/config/html/guide-milio-stressmap-tajekoztato.html', '/config/html/guide-milio-stressmap-technikai-segitseg.html'],
            rightPanelRatio: 39
        },
        {   key: 'reports:TER', 
            title: 'Itt nézheted meg az egyéni riportokat.', 
            reviewType: ReviewTypeEnum.TER, 
            category: 'reports',
            urls: ['/config/html/reports.html'],
        },
        {   key: 'goals:TER', 
            title: 'Panoráma és Aura',
            titleIndexText: '®',
            subtitle: 'folyamatos teljesítménymenedzsment',
            logoLink: '/assets/img/logo-panorama.png',
            reviewType: ReviewTypeEnum.TER, 
            category: 'goals', 
            urls: ['/config/html/guide-goals-tajekoztato.html', '/config/html/guide-goals-technikai-segitseg.html']
        }
    ]

    constructor() {}
    
    public getGuide(key: string): TextGuide {
        return this.textGuides.find(x => x.key === key);
    }

    public getGuideForReviewType(category: string, reviewType: ReviewTypeEnum): TextGuide {
        return this.textGuides.find(x => x.category == category && x.reviewType === reviewType);
    }

    public getGuideAsReportOptions(key: string): ReportOptions {
        const guide = this.getGuide(key);

        return {
            title: guide.title,
            subtitle: guide.subtitle,
            titleIndexText: guide.titleIndexText,
            logoLink: guide.logoLink,
        } as ReportOptions;
    }
}