import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GroupGoal } from 'src/app/models/group-goal.model';
import { GroupGoalsData } from 'src/app/models/group-goals-data.model';
import { GroupGoalService } from 'src/app/services/group-goal.service';
import { ISelectorDialogModel, ISelectorDialogResult, SelectorDialogComponent } from 'src/app/shared/components';
import { ISelectorListItem } from 'src/app/shared/models/selector-list-item.model';

@Component({
  selector: 'app-group-goal-field',
  templateUrl: './group-goal-field.component.html',
  styleUrls: ['./group-goal-field.component.scss']
})
export class GroupGoalFieldComponent implements OnInit {

  @Input()
  public data: GroupGoalsData;

  @Input()
  public groupGoal: GroupGoal;    //ez maga az adott felettes csapatcélja

  @Input() 
  public isDisabled: boolean;

  @Output()
  public change = new EventEmitter<GroupGoal>();

  public get groupGoalDisplayText(): string {
    if (!this.groupGoal) return null;
    
    return this.groupGoal.employeeName + ': ' + this.groupGoal.goalText.name;
  }
  
  constructor(public dialog: MatDialog, public groupGoalService: GroupGoalService) { }

  ngOnInit(): void {
  }  

  public toListItem(groupGoal: GroupGoal): ISelectorListItem {
    if (!groupGoal) return null;

    return groupGoal ? {
        id: groupGoal.id,
        text: this.formatGroupGoal(groupGoal)
    } as ISelectorListItem : null;
  }

  selectGroupGoal() {
    //popup
    if (this.isDisabled) return;

    this.groupGoalService.getLeaderGroupGoals(this.data.employeeId, this.data.reviewPeriodId).subscribe((result) => {
        
        let items = result.map((item) => this.toListItem(item));

        items = [{ id: null, text: 'Nincs kiválasztva' }, ...items];

        const dialogRef = this.dialog.open(SelectorDialogComponent, {
            width: '840px',            
            data: { title: 'Felettesem csapatcéljai', selected: this.toListItem(this.groupGoal), items: items } as ISelectorDialogModel
          });
      
          dialogRef.afterClosed().subscribe( (dialogResult: ISelectorDialogResult) => {

            // ok lett nyomva
            if (dialogResult) {
              this.groupGoal = dialogResult.selectedItem ? result.find(x => x.id === dialogResult.selectedItem) : null;              
    
              this.change.emit(this.groupGoal);
            }
          });
    });

  }

  formatGroupGoal(groupGoal: GroupGoal): string {
    let result = '';    
    
    if (groupGoal.parentGroupGoal) {
      result += this.formatGroupGoal(groupGoal.parentGroupGoal);
    }

    result += `<strong>${groupGoal.employeeName}</strong>: ${groupGoal.goalText.name}<br>`;
    return result;
  }
}
