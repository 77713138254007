import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    private tokenKey = 'access_token';
    
    constructor() {
    }

    saveToken(token) {
        localStorage[this.tokenKey] = token;
    }

    getToken() {
        return localStorage[this.tokenKey];
    }

    removeToken() {
        localStorage.removeItem(this.tokenKey);
    }
}
