<div class="content-area" (keydown.enter)="onOk()">
    <ng-content *ngIf="source.state === componentStates.View || source.state === componentStates.Disabled" select="[slot=readonly]"></ng-content>
    <ng-content *ngIf="source.state === componentStates.Edit" select="[slot=edit]"></ng-content>
    <ng-content *ngIf="source.state === componentStates.Add" select="[slot=add]"></ng-content>
</div>
<div class="actions-area" *ngIf="!noActions">
    <div *ngIf="actionState.editMode" class="row-buttons">
        <button mat-icon-button class="icon-bt-md" (click)="onEdit()" *ngIf="source.state === componentStates.View && !noEdit" [disabled]="!allowAction || disableActions" [title]="!disableActions ? 'szerkesztés' : disableActionsMessage">
            <mat-icon>edit</mat-icon>
        </button>
    
        <button mat-icon-button color="primary" (click)="onDelete()" *ngIf="source.state === componentStates.View && !noDelete" [disabled]="!allowAction || disableActions" [title]="!disableActions ? 'törlés' : disableActionsMessage">
            <mat-icon>delete</mat-icon>
        </button>

        <button mat-icon-button class="icon-bt-md" (click)="onOk()" *ngIf="(source.state === componentStates.Edit || source.state === componentStates.Add) && inlineEdit" title="ok">
            <mat-icon>done</mat-icon>
        </button>
    
        <button mat-icon-button color="primary" (click)="onCancel()" *ngIf="(source.state === componentStates.Edit || source.state === componentStates.Add) && inlineEdit" title="mégsem">
            <mat-icon>highlight_off</mat-icon>
        </button>
    </div>
</div>
