<app-card #card title="Feltöltés" [subtitle]="'Értékelés: ' + reviewPeriodName" [useExternalButtons]="false" [showEdit]="false" [disabled]="!appState.company.value">
   
    <div *ngIf="appState.company.value">
        <mat-form-field appearance="fill">
            <mat-label>Típus</mat-label>
            <mat-select [(value)]="docType" (selectionChange)="docTypeChanged($event.value)">
                <mat-option *ngFor="let endpoint of endpointList" [value]="endpoint.id">{{ endpoint.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="options">
            <ng-container *ngIf="docType == 1">
                <div class="margin-top-sm">Minta <a href="/assets/quantille-skalak-import.xlsx" title="minta import file"> skálák import file</a> letöltése.</div>
            </ng-container>
            <ng-container *ngIf="docType == 2">
                <mat-checkbox class="multiline" (change)="updateInReviewPeriodChanged()" [(ngModel)]="updateInReviewPeriod">Frissítés a kiválasztott értékelésben (!)</mat-checkbox>
                <div class="margin-top-sm">Minta <a href="/assets/quantille-kompetenciak-import.xlsx" title="minta import file"> kérdésadatokat tartalmazó import file</a> letöltése.</div>
            </ng-container>
            <ng-container *ngIf="docType == 3">
                <mat-checkbox class="multiline" (change)="assignEmployeesToReviewPeriodChanged()" [(ngModel)]="assignEmployeesToReviewPeriod">Dolgozók hozzárendelése a kiválasztott értékeléshez</mat-checkbox>
                <div class="margin-top-sm">Minta <a href="/assets/quantille-alkalmazott-import.xlsx" title="minta import file">alkalmazott import file</a> letöltése.</div>
            </ng-container>            
        </div>
    
        <angular-file-uploader #fileUpload class="uploader"
            [config]="afuConfig"           
            (ApiResponse)="docUpload($event)">
        </angular-file-uploader>
        <button mat-button (click)="resetUpload()">Reset</button>
    </div>

</app-card>
