<app-card #card title="Cég" [formGroup]="mainForm" (ok)="save()" [showEdit]="showEdit" [actionState]="actionState" (cancel)="cancelClick()">

    <div class="flex-vertical" slot="edit">
        <mat-form-field appearance="standard">
            <mat-label>Cégnév</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
            <mat-error msg></mat-error>
        </mat-form-field>
    </div>

    <div slot="readonly">
        <app-read-only-field icon="domain" label="Cégnév" [value]="initValue"></app-read-only-field>
    </div>

</app-card>
