import * as Color from "color";
import { ChartDataColor } from "../models/general/chart-data-color.model";

export abstract class ColorHelper {
    
    public static getChartLegendColor(colors: string[], index: number, alpha = 47): ChartDataColor {
        const color = Color(colors[index]);
        return {
          backgroundColor: colors[index] + alpha.toString(),
          borderColor: color.darken(0.3).hex(),
          pointBackgroundColor: colors[index],
          pointBorderColor: color.darken(0.3).hex(),
          hoverBackgroundColor: color.darken(0.3).hex(),
          hoverBorderColor: color.darken(0.3).hex(),
          pointHoverBorderColor: colors[index],
          pointHoverBackgroundColor: color.darken(0.2).hex(),
        };
      }
}