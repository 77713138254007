import { Roles } from '../enums/roles.enum';
import { StateValue } from '../shared/models/state-value.model';
import { Config } from './config.model';
import { UserProfile } from './user-profile.model';

export class State {
    config: Config;

    // todo: add login check
    isLoggedIn = false;    
    emailConfirmationSuccess = false;
    emailConfirmationError = false;
        
    public get isSuperAdmin() {
        return this.me?.value?.role === Roles.SuperAdmin;
    }

    public get isAdmin() {
        return this.me?.value?.role === Roles.Admin;
    }

    public get isAssistant() {
        return this.me?.value?.role === Roles.Assistant;
    }
    
    public readonly isLoading = new StateValue<boolean>(false);
    public readonly me = new StateValue<UserProfile>(null);
}