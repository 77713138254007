<div class="container-fluid main-container">
    <div class="w-full-hd">
        <div class="row" *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
            <div class="col-lg-4">
                <app-company></app-company>
            </div>
            <div class="col-lg-4">
                <app-email-admin></app-email-admin>
            </div>
            
        </div>
        <div class="row" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
            <div class="col-lg-4" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
                <app-review-period [configMode]="true"></app-review-period>
            </div>
            <div class="col-lg-4" *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
                <uploads></uploads>
            </div>
            <div class="col-lg-4" *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
                <app-employee-group></app-employee-group>
            </div>
        </div>
        <div class="row" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
            <div class="col-md-4">
                <app-employee-review-period></app-employee-review-period>
            </div>
            <div class="col-md-4" >
                <app-missing-reviews></app-missing-reviews>
            </div>
            <div class="col-md-4" >
                <app-completed-reviews></app-completed-reviews>
            </div>
        </div>
    </div>
</div>
