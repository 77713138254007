import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '../jwt/angular-jwt.module';

import { MaterialModule } from '../material/material.module';

// Directives
import {     
  NativeElementInjectorDirective,
  FormSubmitDirective,  
  MatMsgDirective,
  DisableControlDirective  
} from './directives';

import {
  ConfirmDialogComponent,
  SelectorDialogComponent
} from './components/dialogs';

import { 
  ControlErrorComponent, 
  DynamicTextComponent, 
  FormPageComponent,   
  ValidationMessagesComponent,
  ToggleButtonComponent,
  LoaderComponent,
  CardComponent,
  ReadOnlyFieldComponent,
  DynamicListItemComponent,  
  SearchComponent,
  InputAutocompleteComponent,
  AngularFileUploaderComponent,
  SearchSimpleComponent,
} from './components';

@NgModule({
  declarations: [
    NativeElementInjectorDirective,
    FormSubmitDirective,    
    MatMsgDirective,
    DisableControlDirective,    

    ControlErrorComponent,
    ValidationMessagesComponent,
    FormPageComponent,
    DynamicTextComponent,
    ToggleButtonComponent,
    LoaderComponent,    
    CardComponent,    
    ReadOnlyFieldComponent, 
    DynamicListItemComponent,
    SearchComponent,
    InputAutocompleteComponent,
    ConfirmDialogComponent,
    SelectorDialogComponent,
    AngularFileUploaderComponent,
    SearchSimpleComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,   
    MaterialModule,    
    FormsModule,
    JwtModule,    
  ],
  providers: [],
  exports: [
    MaterialModule,
    JwtModule,

    NativeElementInjectorDirective,
    FormSubmitDirective,
    MatMsgDirective,
    DisableControlDirective,    

    ControlErrorComponent,
    ValidationMessagesComponent,
    FormPageComponent,
    DynamicTextComponent,
    ToggleButtonComponent,
    LoaderComponent,
    CardComponent,    
    ReadOnlyFieldComponent,
    DynamicListItemComponent,
    SearchComponent,
    SearchSimpleComponent,
    InputAutocompleteComponent,
    ConfirmDialogComponent,
    AngularFileUploaderComponent
  ]
})
export class SharedModule {

}
