export interface KpiType {
    id: number;
    name: string;
    unit: string;
    questionId?: number;
}

export class KpiTypeModel implements KpiType {
    id = 0;
    name = '';
    unit = '';
    questionId = null;
}