import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionModel } from 'src/app/models/action.model';
import { GoalPeriod } from 'src/app/models/goalperiod.model';

@Component({
  selector: 'app-goal-action-quarters',
  templateUrl: './goal-action-quarters.component.html',
  styleUrls: ['./goal-action-quarters.component.scss']
})
export class GoalActionQuartersComponent implements OnInit {

  @Input()
  public readonly = true;

  @Input()
  public period: GoalPeriod;

  @Input()
  public action: ActionModel;

  @Input()
  public isSelf = false;
  
  @Output()
  public quarterUpdate = new EventEmitter();

  public quartersOrder = ['q1', 'q2', 'q3', 'q4'];  
  public progressState = [
    { id: null, name: '---', class: ''},
    { id: 1, name: 'nem teljesített', class: 'danger' }, 
    { id: 2, name: 'részben teljesített vagy csúszik', class: 'warning' }, 
    { id: 3, name: 'teljesített', class: 'success' }, 
    { id: 4, name: 'túlteljesített', class: 'info' }
  ];
  
  constructor() {
  }

  ngOnInit(): void {    
  }

  qt(period: any, index: number): string {
    return this.quartersOrder[(index + period.quarterOffset) % 4];
  }

  getClassList(i: number) {
    return {
      'bg-danger': this.action[this.qt(this.period, i)] === 1,
      'bg-warning': this.action[this.qt(this.period, i)] === 2,
      'bg-success': this.action[this.qt(this.period, i)] === 3,
      'bg-info': this.action[this.qt(this.period, i)] === 4,
      'bg-default': this.action[this.qt(this.period, i)] === null,
      'readonly': this.readonly
    };
  }

  quarterDataChanged() {
    this.quarterUpdate.emit();
  }
}
