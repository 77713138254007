import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { debounceTime, map, scan, switchMap, tap } from 'rxjs/operators';
import { IResultModel, Result, ResultModel } from 'src/app/models/general/result.model';
import { Kpi, KpiModel } from 'src/app/models/kpi.model';
import { KpiType } from 'src/app/models/kpiType.model';
import { ReviewPeriodGoalsForEmployee } from 'src/app/models/review-period-goals-for-employee.model';
import { ReviewPeriod } from 'src/app/models/review-period.model';
import { StateService } from 'src/app/services';
import { KpiService } from 'src/app/services/kpi.service';
import { CardComponent, ConfirmDialogComponent, IConfirmDialogModel, SearchComponent } from 'src/app/shared/components';
import { ComponentState } from 'src/app/shared/enum/component-state.enum';
import { UpdateType } from 'src/app/shared/enum/update-type.enum';
import { Message, MessageType } from 'src/app/shared/models';
import { ActionStateModel } from 'src/app/shared/models/action-state.model';
import { ListItem } from 'src/app/shared/models/list-item.model';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ErrorMessageService } from 'src/app/shared/services';

@UntilDestroy()
@Component({
  selector: 'app-goal-kpi-edit',
  templateUrl: './goal-kpi-edit.component.html',
  styleUrls: ['./goal-kpi-edit.component.scss']
})
export class GoalKpiEditComponent implements OnInit {

   // Inputs

    @Input() public currentPeriod: ReviewPeriodGoalsForEmployee;
    @Input() public aboutEmployeeId: number;
    @Input() public kpi: Kpi;
    @Input() public readonly = false;
    @Input() public isSelf = false;
    @Input() public index = 0;
    
    // Outputs

    @Output() public added = new EventEmitter<Kpi>();
    @Output() public updated = new EventEmitter<Kpi>();
    @Output() public deleted = new EventEmitter<number>();

    showGauge = true;
    gaugeType = "full";
    gaugeAppendText = "%";
    gaugeSize = 82;
    isGaugeMedium = false;
    isDialogOpened = false;

    originalFormData: any;

    public actionState: ActionStateModel = {editMode: this.readonly ? false : true, currentItemIndex: null} as ActionStateModel;
    public itemsSubject = new BehaviorSubject<ListItem<Kpi>[]>([]);
    public readonly items$ = this.itemsSubject.asObservable();
    public reviewPeriod$: Observable<ReviewPeriod>;
       
    public kpiForm: FormGroup;

    public get gaugeValue(): number {
      const planned = this.kpiForm.get('planned').value;
      const achieved = this.kpiForm.get('achieved').value;

      if (planned == null || achieved == null)
        return 0;

      return Math.round((achieved/planned) * 100);
    }

    public get gaugeLabel(): string {
      return '';
    }
              
    // public get allowEdit(): boolean {
    //   return (this.stateService.state.isSuperAdmin || this.stateService.state.isAdmin) && this.currentPeriod.isBossOfSelectedEmployee;
    // }
    
    public kpiSearchConfig: SearchConfig<KpiType, number> = {
      mapItem: (model: KpiType) => {
        return {
          id: model.id, 
          text: model.name,
          dropText: model.name,
          itemSource: model } as SearchOption<KpiType, number>;
      },
      getList: this.kpiService.getTypeList
    };
  
    @ViewChild('search')
    search: SearchComponent<KpiType, number>;

    @ViewChild('card')
    card: CardComponent;
  
    // public get allowAdd(): boolean {
    //   return this.itemsSubject.getValue().length === 0 || this.itemsSubject.getValue().every(x => x.state === ComponentState.View);
    // }
  
    constructor(
      public dialog: MatDialog,
      private fb: UntypedFormBuilder, 
      private kpiService: KpiService, 
      private msgService: ErrorMessageService,
      public breakpointObserver: BreakpointObserver,
      private stateService: StateService) {
    }
  
    ngOnInit(): void {
      // form create
      this.kpiForm = this.createForm();

      // this.kpiForm.valueChanges
      // .pipe(
      //   debounceTime(800),
      //   untilDestroyed(this),
      // )
      // .subscribe(formData => {
                
      //   if (this.kpiForm.dirty && !this.kpiForm.invalid)
      //   {
      
      //     if (typeof formData.kpiType === 'string')
      //     {
      //       formData.kpiType = {id: 0, name: formData.kpiType, unit: formData.unit} as KpiType;
      //       formData.kpiTypeId = formData.kpiType.id;
      //     }

      //     this.saveItem(formData as Kpi);
      //   }
      // });

      // this.breakpointObserver
      // .observe(['(max-width: 1199px)'])
      // .subscribe((state: BreakpointState) => {

      //   let newSize: number;

      //   if (state.matches) {          
      //     newSize = 160;
      //   } else {
      //     newSize = 82;
      //   }

      //   if (newSize != this.gaugeSize)
      //   {
      //     this.showGauge = false;
      //     this.gaugeSize = newSize;
      //     this.showGauge = true;
      //   }

      // });

      //this.loadList();
      this.resizeGauge();
      this.loadData();
    }

    public loadData() {

      if (this.kpi != null)
        this.editItem(this.kpi);
    }

    public editItem(item: Kpi) {      
      const formData = this.createFormData(this.kpi);
      this.originalFormData = {...formData};

      this.kpiForm.patchValue(formData);
    }

    private createForm(): FormGroup {
      return this.fb.group({
        'id': 0,        
        'isAssessed': false,
        'noAnswer': false,
        'error': false,
          
        'aboutUserId': '',
        'aboutEmployeeId': null,
        'byEmployeeId': null,
        'reviewPeriodId': null,
    
        'kpiTypeId': null,
        'kpiType': [null, [Validators.required]],
        'planned': ['', [Validators.required]],
        'achieved': [''],
        'unit': ['', [Validators.required]],
      })
    }
    
    // isKpiTypeSaved(): boolean {
    //   const formData = this.kpiForm.value;
    //   return !!formData.kpiType?.itemSource?.id
    // }

    private createFormData(result: Kpi): any {

      let kpiType: SearchOption<KpiType, number> = null;
          
      if (result?.kpiTypeId)
      {
        kpiType = this.kpiSearchConfig.mapItem(result.kpiType);
      }
          
      const formData = {
        id: result.id,
        planned: result.planned,
        achieved: result.achieved,
        byEmployeeId: result.byEmployeeId,
        aboutEmployeeId: result.aboutEmployeeId,
        aboutUserId: result.aboutUserId,
        reviewPeriodId: result.reviewPeriodId,
        isAssessed: result.isAssessed,
        noAnswer: result.noAnswer,                
        kpiTypeId: result.kpiTypeId,
        error: result.error,
        unit: kpiType ? kpiType.itemSource.unit : ''
      } as Kpi;
      
      return {...formData, kpiType: kpiType};
    }

    public onBlur($event) {
      const formData = this.kpiForm.value;
      
      if (this.kpiForm.dirty && !this.kpiForm.invalid && !this.isDialogOpened)
      {
        this.card.displayStatus({type: MessageType.info, text: 'mentés...'} as Message);
        
        if (typeof formData.kpiType === 'string')
        {
          if (formData.kpiTypeId === 0)
          {
            formData.kpiType = {id: 0, name: formData.kpiType, unit: formData.unit} as KpiType;
            formData.kpiTypeId = formData.kpiType.id;
          }
          else
          {
            formData.kpiType = {id: formData.kpiTypeId, name: formData.kpiType, unit: formData.unit} as KpiType;
          }
        }
        else 
        {
          if (formData.kpiType.itemSource)
            formData.kpiType = formData.kpiType.itemSource;

          formData.kpiType.unit = formData.unit;
        }

        console.log(formData);

        this.saveItem(formData as Kpi);
      }
    }

    public saveItem(model: Kpi) {
            
      let action: Observable<Result<KpiModel>>;
                  
      //model.planned = Number(formData.planned);
      //model.achieved = Number(formData.achieved) ? Number(formData.achieved) : null;
      //model.kpiType = formData.kpiType.itemSource;

      if (model.id)
      {
        action = this.kpiService.updateData(model);
      }
      else
      {
        action = this.kpiService.addData(model);
      }       
      
      action.subscribe({ next: (result) => {
        this.card.displaySavedStatus();
        const res = result.data;        
       
        this.kpi.id = res.id;
        this.kpi.kpiType.id = res.kpiType.id;
        this.kpi.kpiType.name = res.kpiType.name;
        this.kpi.kpiType.unit = res.kpiType.unit;
        this.kpi.achieved = res.achieved;
        this.kpi.planned = res.planned;

        this.kpiForm.get('id').setValue(res.id, {emitEvent: false});
        this.kpiForm.get('kpiTypeId').setValue(res.kpiType.id, {emitEvent: false});
        this.kpiForm.get('kpiType').setValue(this.kpiSearchConfig.mapItem(res.kpiType), {emitEvent: false});
        
        this.kpiForm.markAsPristine();

        this.originalFormData = {...this.kpiForm.value};

        //this.search.disabled = false;
                
      }, error:(err: IResultModel<ResultModel>) => {

        const errorKey = err.error?.errorData?.key;
        
        if (errorKey === 'kpiUpdateTypeRequired')
        {
          const msg = this.msgService.getErrorMessage('chooseToProceed');
          this.card.displayStatus(msg);
          
          this.showConfirmDialog(model, errorKey);
        }
        else
          this.msgService.handleError<ResultModel>(err);
      }});
    }
  
    showConfirmDialog(model: Kpi, key: string) {
      this.isDialogOpened = true;
      const msg = this.msgService.getErrorMessage(key);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "500px",
        data: {
            title: 'Kérem válasszon a folytatáshoz',
            message: msg.text,
            choices: [
              {key: 'updateCurrent', name: 'Csak ebben az esetben', color: 'primary'},
              {key: 'updateAll', name: 'Minden esetben', color: 'primary'},
            ]
        } as IConfirmDialogModel
      });

      dialogRef.afterClosed().subscribe( (dialogResult: string) => {
        this.isDialogOpened = false;
        if (dialogResult)
        {
          if (dialogResult == 'updateCurrent')
            model.updateType = UpdateType.UpdateCurrent
          else if (dialogResult == 'updateAll')
            model.updateType = UpdateType.UpdateAll;

          this.saveItem(model);
        }
        else
        {
          this.card.clearStatus();
          this.kpiForm.reset(this.originalFormData);
        }
     });
    }

    changeUnit($event)
    {
      //if (this.kpiForm.get('unit').value != newFormValue.unit)
      this.kpiForm.get('unit').setValue($event.target.value);
    }

    public resizeGauge() {
      const width = window.innerWidth;
            
      if (width < 1060)      
        this.gaugeSize = 160;
      else if (width < 1200)
        this.gaugeSize = 160;
      else 
        this.gaugeSize = 82;
    }
  
    // private loadList() {

    //   this.kpiService.getList(this.currentPeriod.reviewPeriod.id, this.aboutEmployeeId).pipe(
    //     map(list => list.map(x => new ListItem(x)))          
    //   )
    //   .pipe(untilDestroyed(this))
    //   .subscribe(itemArray => {
    //     itemArray.forEach( x => x.state = x.itemSource.kpiType.questionId ? ComponentState.Disabled : x.state );
    //     this.itemsSubject.next(itemArray);
    //   });
      
    //   this.items$
    //     .pipe(untilDestroyed(this))
    //     .subscribe(list => {
    //       this.currentPeriod.kpiList = list.map(x => x.itemSource);
    //       this.listChanged.emit(list);
    //   });
    // }
  
    
    // public cancelItem(item: ListItem<Kpi>) {
    //   if (item.state == ComponentState.Add)
    //   {
    //     // remove added item
    //     let items = this.itemsSubject.getValue().filter(x => x.state === ComponentState.View);
    //     this.itemsSubject.next(items);      
    //   }
  
    //   this.kpiForm.reset();
    //   //this.search.disabled = false;
    // }
     
    public deleteKPI() {
      if (this.kpi.id == 0)
      {
          this.currentPeriod.kpiList = this.currentPeriod.kpiList.filter((item) => { return item.id != this.kpi.id });
          this.deleted.emit(this.kpi.id);
          return;
      }

      if (confirm("Biztosan törli a KPI-t?")) {        
        this.kpiService.deleteData(this.kpi).subscribe({ next: (result) => {
    
          this.currentPeriod.kpiList = this.currentPeriod.kpiList.filter((item) => { return item.id != this.kpi.id });
          this.deleted.emit(this.kpi.id);
          
          this.msgService.showSnackBarMessage('deleteSuccessful');
        }, error: (err: IResultModel<ResultModel>) => {
          this.msgService.handleError<ResultModel>(err);
        }});
      }

    }
      
    
   
    onSearchChange($event) {
      if ($event?.itemSource)
      {
        const item = $event?.itemSource;

        this.kpiForm.get('unit').setValue(item.unit, {emitEvent: false});
        this.kpiForm.get('kpiTypeId').setValue(item.id, {emitEvent: false});
      }         
    }
      
    private createItem(option: string | SearchOption<KpiType, number>): ListItem<Kpi> {
      let item = new KpiModel();
      item.aboutEmployeeId = this.aboutEmployeeId;
      item.reviewPeriodId = this.currentPeriod.reviewPeriod.id;
      
      if (option == null)
      {
        item.kpiType = {id: 0, name: ''} as KpiType;
      }
      else
      {
        if (typeof option === 'string')
        {
          item.kpiType.name = option;
        }
        else
        {
          item.kpiType = option.itemSource;
        }
      }
  
      let listItem = new ListItem<Kpi>(item);
      listItem.state = ComponentState.Add;
  
      return listItem;
    }

    public addKpi() {
      let listItem = this.createItem(null);
      
      //add item    
      let items = this.itemsSubject.getValue();
      this.itemsSubject.next([...items, listItem]);
    }

    public getKpiTypeName($event) {
      let kpiTypeName = '';

      if (typeof $event === 'string')
      {
        kpiTypeName = $event;
      }
      else if (typeof this.search.searchControl.value != 'string')
      {
        if ($event.id && $event.id > 0)
          return '';
        else
          kpiTypeName = $event.text;
      }
      else
      {
        kpiTypeName = this.search.searchControl.value;
      }

      return kpiTypeName;
    }

    public getInputSize(field: string): string {
      let size = 28;
      const value = this.kpiForm.get(field).value;
            
      if (value != null) {
        length = value.toString().length;

        if (length > 7)
        {          
          if (length < 10)
            size = 22;
          else if (length < 13)
            size = 16;
          else
            size = 14;
        }
      }

      return size + 'px';
    }

    // public addKpiType($event) {
    //   let kpiTypeName = this.getKpiTypeName($event);
            
    //   //popup
    //   const dialogRef = this.dialog.open(KpiTypeAddDialogComponent, {
    //     width: '600px',
    //     data: kpiTypeName
    //   });

    //   dialogRef.afterClosed().subscribe( (result: KpiType) => {
    //     if (result)
    //     {
    //       let option = this.kpiSearchConfig.mapItem(result);
    //       this.kpiForm.get('kpiType').setValue(option);
    //       this.unitName = option.itemSource.unit;
    //     }          
    //   });
    // }
}
