import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AddToEmployeeGroupValidation, EmployeeEmailsCheckResult } from 'src/app/models/employee-emails-check.model';
import { EmployeeBulkInsertDialogConfig } from 'src/app/models/emplyee-bulk-insert-dialog-config.model';

@Component({
  selector: 'app-user-bulk-insert',
  templateUrl: './user-bulk-insert.component.html',
  styleUrls: ['./user-bulk-insert.component.scss']
})
export class UserBulkInsertComponent implements OnInit {

  public mainForm: UntypedFormGroup;

  public checkResultSubject = new BehaviorSubject<EmployeeEmailsCheckResult>({} as EmployeeEmailsCheckResult);
  public checkResult$ = this.checkResultSubject.asObservable();
  public inputText: string;

  public get isAllowSubmit(): boolean {
    if (!this.checkResultSubject.value.validEmployees) return false;

    return this.checkResultSubject.value.validEmployees.length > 0 && this.checkResultSubject.value.invalidEmails.length == 0;
  }
      
  constructor(
    public dialogRef: MatDialogRef<UserBulkInsertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmployeeBulkInsertDialogConfig) { }

  ngOnInit(): void {
  }
  
  public parseEmails(text: string): string[] {
    return text.split('\n').map(x => x.trim()).filter(x => x != '');
  }

  public inputFieldChanged() {
    if (this.inputText.trim() === '') return;

    const dto = {
      groupId: this.data.id,
      emails: this.parseEmails(this.inputText)
    } as AddToEmployeeGroupValidation;

    this.data.validateAction(dto).subscribe(result => {
      this.checkResultSubject.next(result);
      this.inputText = '';     
    });
  }

  public ok() {
    const items = this.checkResultSubject.value.validEmployees.map( x => x.id);
    this.dialogRef.close(items);
  }

}
