import { ChartConfiguration } from "chart.js";
import { BarChartDataSource } from "../general/bar-chart-datasource.model";
import { PersonalityTestReport } from "./personality-test-report.model";
import { ChartLegendItem, PersonLegend } from "./person-legend.model";
import { QuestionCategoryResultsForReviewPeriod } from "./question-category-results-for-reviewperiod.model";

export interface StressMapReportItem {
    barChartDs: BarChartDataSource;
    chartData: QuestionCategoryResultsForReviewPeriod;
    barChartOptions: ChartConfiguration<'bar'>['options'];
    legend: ChartLegendItem[];
}

export class StressMapReportItemModel implements StressMapReportItem {
    barChartDs: BarChartDataSource;
    chartData: QuestionCategoryResultsForReviewPeriod;
    barChartOptions: ChartConfiguration<'bar'>['options'];
    legend: ChartLegendItem[] = [];

    constructor() {
        this.barChartDs = new BarChartDataSource();
        this.chartData = null;
    }
}