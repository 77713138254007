import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { State } from 'src/app/models/state.model';
import { UserProfile } from 'src/app/models/user-profile.model';
import { StateService } from 'src/app/services';
import { AuthService } from 'src/app/services/auth.service';
import { CardComponent } from 'src/app/shared/components';
import { ErrorMessageService } from 'src/app/shared/services';
import { EmployeeState } from 'src/app/store/employee.state';

@UntilDestroy()
@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {

  public model$: Observable<UserProfile>;
  public detailForm: UntypedFormGroup;
  public state: State;

  constructor(private fb: UntypedFormBuilder, 
    private employeeStore: EmployeeState, 
    private authService: AuthService, 
    stateService: StateService,
    private msgService: ErrorMessageService) {
      this.state = stateService.state;
    this.model$ = this.employeeStore.employee.obs$;
  }
  
  @ViewChild('card')
  card: CardComponent;

  ngOnInit(): void {
    this.detailForm = this.createForm();

    this.model$
    .pipe(untilDestroyed(this))
    .subscribe(data => {

      if (data) {
        let fdata = {
          'email': data.email,
          'phone': ''
        };
  
        this.detailForm.setValue(fdata);
      }
    });
  }

  createForm(): UntypedFormGroup {
    return this.fb.group({
      'email': ['', [Validators.email, Validators.required]],
      'phone': ['']
    });
  }
  
  save() {
    const formData = this.detailForm.value;

    let data = {
      oldEmail: this.employeeStore.employee.value.email,
      newEmail: formData.email,
      phone: formData.phone
    };

    this.authService.changeContactDetailsForUser(data).subscribe({ next: () => {
      this.card.actionState.editMode = false;
      this.employeeStore.employee.value = {...this.employeeStore.employee.value, email: data.newEmail};
      this.msgService.showSnackBarMessage('saveSuccessful');
    }, error: (err: IResultModel<ResultModel>) => {
      this.msgService.handleError<ResultModel>(err);
    } });
    
  }

}
