<app-card #card title="Új jelszó beállítása" [formGroup]="mainForm" (ok)="changePassword()" [showEdit]="!passwordResetMode" [actionState]="{editMode: passwordResetMode}">
        
    <div class="flex-vertical" slot="edit">
        <div class="alert alert-danger" *ngIf="passwordResetMode && passwordResetFailed">
            A jelszóvisszaállítás link hibás vagy a felhasználás időkorlátja lejárt, kérem kattintson az alábbi linkre és használja a jelszóemlékeztető funkciót. <br>
            <a [routerLink]="['/']">vissza a főoldalra</a>
        </div>

        <input *ngIf="!ownProfile" type="password" style="display:none"/>

        <mat-form-field appearance="standard" *ngIf="!passwordResetMode && ownProfile">
            <mat-label>Régi jelszó</mat-label>
            <input matInput type="password" name="oldPassword" formControlName="oldPassword" autocomplete="off" required>
            <mat-error msg></mat-error>
        </mat-form-field>
           
        <mat-form-field class="username" appearance="standard" *ngIf="passwordResetMode">
            <mat-label>Felhasználónév</mat-label>
            <input matInput type="text" name="username" formControlName="username" autocomplete="username">
            <mat-error msg></mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="standard">
            <mat-label>Új jelszó</mat-label>
            <input matInput type="password" name="newPassword" formControlName="newPassword" autocomplete="new-password" required>
            <mat-error msg></mat-error>
        </mat-form-field>

        <div class="password-rules flex-v" *ngIf="isPasswordNotEmpty">
            <label class="col"
            [ngClass]="mainForm.controls['newPassword'].hasError('required') || mainForm.controls['newPassword'].hasError('minlength')  ? 'text-danger' : 'text-success'"
            >
            <i class="material-icons">
                {{ mainForm.controls['newPassword'].hasError('required') ||
                mainForm.controls['newPassword'].hasError('minlength') ? 'cancel' :
                'check_circle' }}
            </i>
            A jelszónak minimum {{ minCharCount }} karakter hosszúnak kell lennie!
            </label>

            <label class="col"
            [ngClass]="mainForm.controls['newPassword'].hasError('required') || mainForm.controls['newPassword'].hasError('hasNumber')  ? 'text-danger' : 'text-success'"
            >
            <i class="material-icons">
                {{ mainForm.controls['newPassword'].hasError('required') ||
                mainForm.controls['newPassword'].hasError('hasNumber') ? 'cancel' :
                'check_circle' }}
            </i>
            Tartalmaznia kell legalább 1 számot!
            </label>

            <label class="col"
            [ngClass]="mainForm.controls['newPassword'].hasError('required') || mainForm.controls['newPassword'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'"
            >
            <i class="material-icons">
                {{ mainForm.controls['newPassword'].hasError('required') ||
                mainForm.controls['newPassword'].hasError('hasCapitalCase') ? 'cancel' :
                'check_circle' }}
            </i>
            Tartalmaznia kell legalább 1 nagybetűt!
            </label>

            <label class="col"
            [ngClass]="mainForm.controls['newPassword'].hasError('required') || mainForm.controls['newPassword'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'"
            >
            <i class="material-icons">
                {{ mainForm.controls['newPassword'].hasError('required') ||
                mainForm.controls['newPassword'].hasError('hasSmallCase') ? 'cancel' :
                'check_circle' }}
            </i>
            Tartalmaznia kell legalább 1 kisbetűt!
            </label>
            
        </div>
    
        <mat-form-field appearance="standard">
            <mat-label>Jelszó megerősítése</mat-label>
            <input matInput type="password" name="confirmPassword" formControlName="confirmPassword" autocomplete="new-password" required>
            <mat-error msg></mat-error>
        </mat-form-field>

        
    </div>

</app-card>
