import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { State } from '../../models/state.model';
import { AuthService } from '../../services/auth.service';
import { StateService } from '../../services/state.service';

@UntilDestroy()
@Component({
    selector: 'confirm-email',
    templateUrl: './confirm-email.component.html',
})
export class ConfirmEmailComponent implements OnInit {
    
    public title = "";
    public state: State;

    constructor(
        private authService: AuthService, 
        stateService: StateService,        
        private route: ActivatedRoute,
        private router: Router)
    {
        this.state = stateService.state;        
    }

    ngOnInit() {
        this.route.params
        .pipe(untilDestroyed(this))
        .subscribe(params => {

            // todo: [refactor] move logic to authservice
            if (params['userId'])
            {
                const userId = params['userId'] as number;

                if (params['token'])
                {                    
                    const token = params['token'] as string;

                    this.authService.confirmEmail(userId, token).subscribe((data) => {
                        this.state.emailConfirmationSuccess = true;
                        this.router.navigate([`/confirmEmail/${userId}`])
                    }, () => {
                        this.state.emailConfirmationError = true;
                        this.router.navigate([`/confirmEmail/${userId}`])
                    });
                    
                } else if (this.state.emailConfirmationSuccess) {
                    this.title = "Email megerősítése sikeres!";
                } else if (this.state.emailConfirmationError) {
                    this.title = "Email megerősítése sikertelen!";
                } else {
                    this.title = "";
                }
            }
        });
    }
}
