export abstract class DateHelper {
    public static setTimeZoneDiff(value: any): Date {
        let dateValue = DateHelper.toDate(value);

        dateValue = new Date(Date.UTC(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), dateValue.getHours(), dateValue.getMinutes(), dateValue.getSeconds()));
        return dateValue;
    }

    public static isDateExpired(date: Date): boolean {
        
        //get the current date without time
        const today = new Date();
        today.setHours(0,0,0,0);

        //get the date to compare without time
        const dateToCompare = new Date(date);
        dateToCompare.setHours(0,0,0,0);
        
        //if the date to compare is less than today, it is expired
        return dateToCompare < today;
    }
 
    public static getDateWithoutTimezone (value: string | Date): Date {
        let dateValue = DateHelper.toDate(value);
        const offset = dateValue.getTimezoneOffset() * 60000; //offset in milliseconds
        const withoutTimezone = new Date(dateValue.valueOf() - offset)
          .toISOString()
          .slice(0, -1);
        return new Date(withoutTimezone);
    };

    public static toDate(value: string | Date): Date {
        if (!value) return null;

        if (typeof value ==='string')
        {
            return new Date(value);
        }

        return value;
    }
}