<app-card #card title="Csapatok" [useExternalButtons]="true" [showEdit]="isEditable" [disabled]="!settingsState.reviewPeriod.value">
    <div>
        <div>
            <div class="input-with-buttons padding-right-lg" *ngIf="groupList$ | async as groupList">
                <mat-form-field appearance="standard">
                    <mat-select [(value)]="groupState.value" disableOptionCentering class="drop-fix">
                        <mat-option *ngFor="let item of groupList" [value]="item">{{ item.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="action-buttons">
                    <button mat-icon-button color="primary" (click)="addGroup();" *ngIf="card.actionState.editMode">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                </div>
            </div>

            <ng-container *ngIf="items$ | async as items">
                <div class="scroll-panel">
                    <app-dynamic-list-item (delete)="deleteMember(item)" [actionState]="card.actionState" [noEdit]="true" *ngFor="let item of items; index as i" [index]="i" [source]="item">
                        <div slot="readonly" class="flex-horizontal width-max read-only-area">
                            <div class="field-name">{{ item.itemSource.name + ' ' + (item.itemSource.employeeIdentifier ?? '') }}</div>
                        </div>
                    </app-dynamic-list-item>
                </div>
    
                <app-search *ngIf="card.actionState.editMode" (add)="addMember($event)" [inlineButtons]="true" [isAllowSearch]="true" [config]="searchMemberConfig"></app-search>
            </ng-container>

            <button mat-flat-button *ngIf="card.actionState.editMode" class="hidden-print icon-button vertical-middle bg-success" (click)="addMembers()" title="Tömeges dolgozó hozzáadás">
                <mat-icon>queue</mat-icon>
            </button>   
        </div>        
    </div>
</app-card>
