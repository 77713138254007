import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StateService } from './state.service';
import { Config } from '../models/config.model';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
  
  constructor(private http: HttpClient, private stateService: StateService) {}

  load(): Promise<any>  {

      return this.http.get('/config/appConfig.json')
        .toPromise()
        .then(data => {
          //Object.assign(this, data);          
          this.stateService.state.config = data as Config;
          return data;
        });
  }
}