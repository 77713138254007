import { TestBed } from "@angular/core/testing";

export interface PagedListResult<T> {
    list: T[];

    total: number;
    pageCount: number;
    pageSize: number;
    page: number;
}

export class PagedListResultModel<T> implements PagedListResult<T> {
    list: T[];
    total = 0;
    pageCount = 0;
    pageSize = 0;
    page = 0;
    
    constructor() {
        this.list = [];
    }
}