<form class="example-form" [formGroup]="usersForm">

      <app-search-simple [placeholder]="'Choose a user'" [data]="(filteredList | async)?.list" 
        (searchChange)="onUserInputChange($event)" 
        (itemSelected)="onItemSelected($event)" 
        formControlName="userInput">        
            <!-- <ng-container *ngTemplateOutlet="optionTemplate; context: { item: item }"></ng-container> -->
            <div>jello</div>
      </app-search-simple>
      
    <span>Your choice is: {{ selectedPosition | json }}</span>
  
    <button mat-button (click)="change()">Click me!</button>
  </form>
  
  <ng-template #optionTemplate let-item>
    <span>{{ item.name }}</span>
    <small> | ID: {{ item.id }}</small>
  </ng-template>