import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { CompanyService } from '../../services/company.service';

@Component({
    selector: 'create-role-user',
    templateUrl: './create-role-user.component.html',
})
export class CreateRoleUserComponent implements OnInit {
    public companies$: Observable<string[]>;
    public companyGroups$: Observable<string[]>;

    public createRoleUserSuccess = false;
    public createRoleUserError = false;

    public createUserSuccess = false;
    public createUserError = false;
        
    public newUserForm: UntypedFormGroup;

    constructor(private companyService: CompanyService, private authService: AuthService, private fb: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.newUserForm = this.createNewUserForm();       
        this.companies$ = this.companyService.getList().pipe(
            map(result => result.list.map(x => x.name))
        );
        //this.companyGroups$ = this.companyService.getCompanyGroups();
    }

    createNewUserForm(): UntypedFormGroup {
        return this.fb.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            company: [null, [Validators.required]],
            companyGroup: [null],
            role: [null]
        });
    }

    createRoleUser(companyName, role) {
        if (this.newUserForm.invalid)
            return;

        const data = this.newUserForm.value;

        this.createRoleUserSuccess = false;
        this.createRoleUserError = false;
        this.createUserSuccess = false;
        this.createUserError = false;

        this.authService.createRoleUser(companyName, data.name, data.email, role).subscribe((result) => {
            this.createRoleUserSuccess = true;
            this.createRoleUserError = false;

            this.newUserForm.reset();
        }, (result) => {
            this.createRoleUserSuccess = false;
            this.createRoleUserError = true;
        });
    };

    createUser(companyGroupName) {
        if (this.newUserForm.invalid)
        return;

        const data = this.newUserForm.value;

        this.createRoleUserSuccess = false;
        this.createRoleUserError = false;
        this.createUserSuccess = false;
        this.createUserError = false;

        // this.authService.createUser(companyGroupName, data.name, data.email).subscribe((result) => {
        //     this.createUserSuccess = true;
        //     this.createUserError = false;

        //     this.newUserForm.reset();
        // }, (result) => {
        //     this.createUserSuccess = false;
        //     this.createUserError = true;
        // });
    };

    showCreateRoleUserSuccess() {
        return this.createRoleUserSuccess;
    };

    showCreateRoleUserError() {
        return this.createRoleUserError;
    };

    showCreateUserSuccess() {
        return this.createUserSuccess;
    };

    showCreateUserError() {
        return this.createUserError;
    };

}
