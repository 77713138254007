import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRequestOptions } from '.';
import { DataService } from './data.service';
import { StateService } from './state.service';
import { ResultModel } from '../models/general/result.model';

@Injectable({
  providedIn: 'root'
})
export class DefaultDataService<T> extends DataService {
    
  constructor(
      http: HttpClient, 
      stateService: StateService,
  ) 
  {      
      super(http, stateService);
  }
  
  public getItem(id: number | string): Observable<T> {
    return this.get<T>(`/api/${this.controllerName}/${id}`);
  }

  public addItem<TResult>(model: T, options?: IRequestOptions): Observable<TResult> {
    if (!options)
      return this.put<TResult>(`/api/${this.controllerName}/add`, model);
    else
      return this.put<TResult>(`/api/${this.controllerName}/add`, model, options);
  }

  public updateItem<TResult>(model: T, options?: IRequestOptions): Observable<TResult> {
    if (!options)
      return this.post<TResult>(`/api/${this.controllerName}/update`, model );
    else
      return this.post<TResult>(`/api/${this.controllerName}/update`, model, options );
  }

  public deleteItem<TResult>(model: T, options?: IRequestOptions): Observable<TResult> {
    if (!options)
      return this.delete<TResult>(`/api/${this.controllerName}/delete/${model['id']}`);
    else
      return this.delete<TResult>(`/api/${this.controllerName}/delete/${model['id']}`, options);
  }

}
