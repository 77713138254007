<app-card #card title="Értékelésben résztvevő dolgozók" [useExternalButtons]="true" [showEdit]="isEditable" [disabled]="!settingState.reviewPeriod.value">
    <div>
        <div>
            <mat-form-field appearance="standard">
                <mat-label>Kereső</mat-label>
                <input matInput type="text" [formControl]="searchControl" autocomplete="off">
                <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Törlés" (click)="clearText()">
                  <mat-icon>close</mat-icon>
                </button>                
            </mat-form-field>

            <ng-container *ngIf="items$ | async as items">
                <div class="message-panel">
                    {{ searchMessage }}
                </div>
                <div class="scroll-panel">
                    <app-dynamic-list-item (delete)="removeEmployee(item)" [actionState]="card.actionState" [noEdit]="true" *ngFor="let item of items; index as i" [index]="i" [source]="item">
                        <div slot="readonly" class="flex-horizontal" [ngClass]="{'deleted': item.itemSource.employeeStatus == employeeStatusList.Inactive}">
                            <div class="field-index">{{ (page * pageSize) + i + 1 }}</div>
                            <div class="field-text"><span class="name"><strong>{{ item.itemSource.name }}</strong></span><span class="email">({{ item.itemSource.email }})</span></div>
                            <div class="field-id">{{ item.itemSource.employeeIdentifier ?? '' }}</div>
                        </div>
                    </app-dynamic-list-item>
                </div>
                <mat-paginator #paginator [length]="total"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="pagingSettingsChanged($event)"
                            aria-label="Válasszon oldalt">
                </mat-paginator>
                <app-search *ngIf="card.actionState.editMode" (add)="addEmployee($event)" [inlineButtons]="true" [isAllowSearch]="true" [config]="searchEmployeeConfig"></app-search>
            </ng-container>
            
        </div>        
    </div>
</app-card>
