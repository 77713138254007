import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ErrorMessageService } from '../services/error-message.service';

@Component({
    selector: '[msg]',
    template: '{{ error }}'
  })
  export class MatMsgDirective implements AfterViewInit {    
    @Input() customErrors: {[key: string]: string} = {};
    public error = '';
    private inputRef: MatFormFieldControl<MatInput>;
  
    constructor(private inj: Injector, private msgService: ErrorMessageService) {
    }
      
    public ngAfterViewInit() {
      
      let container = this.inj.get(MatFormField);
      this.inputRef = container._control;
        
      this.inputRef.ngControl.statusChanges.subscribe((state: 'VALID' | 'INVALID') => {
        if (state === 'INVALID') {        
          this.error = this.msgService.getFormErrorMessage(this.inputRef.ngControl.errors, this.customErrors);
        }
      });
    }
}