<app-card #card title="Munkakör" [useExternalButtons]="true" [editDisabled]="!allowEdit">
    <div *ngIf="items$ | async as items">
        <div>
            <app-dynamic-list-item (delete)="deleteItem(item)" [actionState]="card.actionState" [noEdit]="true" *ngFor="let item of items; index as i" [index]="i" [source]="item">
                <div slot="readonly" class="flex-horizontal width-max read-only-area" [ngClass]="{'archive': isArchive(item)}">
                    <div class="field-name">{{ item.itemSource.position.name }}</div>
                    <!-- <div class="field-date">{{ item.itemSource.startDate | date:'yyyy.MM.dd' }}</div>
                    <div class="field-date">{{ item.itemSource.endDate | date:'yyyy.MM.dd' }}</div> -->
                </div>
                <!-- <div slot="edit">
                    <div class="flex-horizontal edit-area">
                        <div class="field-name">{{ item.itemSource.position.name }}</div>                           
                        
                        <mat-form-field appearance="standard" class="field-date field-simple">
                            <mat-label>Kezdete</mat-label>
                            <input matInput [matDatepicker]="pickerStartEdit" formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerStartEdit"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStartEdit></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="standard" class="field-date field-simple">
                            <mat-label>Vége</mat-label>
                            <input matInput [matDatepicker]="pickerEndEdit" formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerEndEdit"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEndEdit></mat-datepicker>
                        </mat-form-field>
                                                  
                    </div>
                </div>
                <div slot="add">
                    <div class="flex-horizontal add-area">
                        <div class="field-name">{{ item.itemSource.position.name }}</div>
                            
                        <mat-form-field appearance="standard" class="field-date field-simple">
                            <mat-label>Kezdete</mat-label>
                            <input matInput [matDatepicker]="pickerStartAdd" formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerStartAdd"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStartAdd></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="standard" class="field-date field-simple">
                            <mat-label>Vége</mat-label>
                            <input matInput [matDatepicker]="pickerEndAdd" formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerEndAdd"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEndAdd></mat-datepicker>
                        </mat-form-field>
                        
                    </div>
                </div> -->
            </app-dynamic-list-item>
        </div>
        <app-search #search *ngIf="card.actionState.editMode" (add)="addItem($event)" [inlineButtons]="true" [isAllowSearch]="true" [config]="searchMemberConfig" [disabledAddButton]="!allowAdd"></app-search>
    </div>
</app-card>
