import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { MassMailOptions, SingleMailOptions } from 'src/app/models/mail-options.model';
import { EmployeeService } from 'src/app/services';
import { ConfirmDialogComponent, IConfirmDialogModel, SearchComponent } from 'src/app/shared/components';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { SettingsState } from 'src/app/store/settings.state';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-email-admin',
    templateUrl: './email-admin.component.html',
    styleUrls: ['./email-admin.component.scss']
})
export class EmailAdminComponent implements OnInit {
    public mainForm: UntypedFormGroup;

    isValidSendType = false;
    isValidSingleSettings = false;
    isValidEmail = false;

   // onlyForNewUsers = false;
    
    public searchEmployeeConfig: SearchConfig<Employee, number> = {
        mapItem: (model: Employee) => {
          return {
            id: model.id, 
            text: `${model.name} (${model.email})`,
            dropText: `${model.name} ${model.employeeIdentifier ?? ''}`,
            itemSource: model } as SearchOption<Employee, number>;
        },
        getList: this.employeeService.getList
    };

    @ViewChild('search')
    search: SearchComponent<Employee, number>;

    constructor(
        private authService: AuthService, 
        private employeeService: EmployeeService,        
        public settingState: SettingsState,
        private fb: UntypedFormBuilder, 
        private router: Router, 
        private msgService: ErrorMessageService,
        private dialog: MatDialog,
        ) {
    }
    
    ngOnInit() {        
        this.mainForm = this.createEmailForm();

        this.mainForm.valueChanges.subscribe(data => {
            this.isValidSendType = data.sendType != null;
            this.isValidSingleSettings = data.sendType == 2 || (data.mailType != null);
            this.isValidEmail = this.mainForm.controls.sendType.value != 1 || this.getSingleEmail() != '';

            if (data.sendType == 2) {
                this.mainForm.controls.mailType.setValue(null, {emitEvent:false});
            }
        });
    }

    checkValidity() {

    }

    getSingleEmail(): string {        
        const val = this.search.searchValue as any;

        if (!val) return '';
        
        return val.id ? val.itemSource.email : val;
    }

    createEmailForm() {
        return this.fb.group({
            sendType: [null, [Validators.required]],
            mailType: [null],
            sernumFrom: [null],
            sernumTo: [null],
            onlyForNewEmployees: [false]
        });
    }

    sendSingleEmail() {       
        const val = this.mainForm.value;

        const options = {
            email: this.getSingleEmail(),
            mailType: parseInt(val.mailType)            
        } as SingleMailOptions;

        const mailTypeText = val.mailType == 1 ? 'regisztrációs' : 'jelszó emlékeztető'
        
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: {
                title: 'Email küldés',
                message: `A ${options.email} email címre ${mailTypeText} levelet küldünk. Folytatja?`
            } as IConfirmDialogModel
          });

          dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
            if (dialogResult)
            {
                this.authService.sendSingleEmail(options).subscribe(() => {
                    this.msgService.showSnackBarMessage('emailSendSuccess');
                }, error => {
                    this.msgService.showSnackBarMessage('emailSendError');
                });
            }
         });

        
    };

    sendRegistrationMessages() {
        const val = this.mainForm.value;

        const options = {
            serNumFrom: parseInt(val.sernumFrom),
            serNumTo: parseInt(val.sernumTo),
            reviewPeriodId: this.settingState.reviewPeriod.value.id,
            onlyForNewEmployees: val.onlyForNewEmployees
        } as MassMailOptions;

        const quantityText = options.serNumFrom && options.serNumTo ? options.serNumTo - (options.serNumFrom - 1) : "az összes";

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: {
                title: 'Regisztrációs levél',
                message: `A regisztrációs levél kiküldésre kerül ${quantityText} dolgozó számára. Folytatja?`
            } as IConfirmDialogModel
          });

          dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
             if (dialogResult)
             {
                this.authService.sendRegistrationMessages(options).subscribe(() => {
                    this.msgService.showSnackBarMessage('emailSendMassStarted');
                }, error => {
                    this.msgService.showSnackBarMessage('emailSendError');
                });
             }
          });
    }

    sendMail() {
        if (this.mainForm.invalid)
            return;

        const val = this.mainForm.value;

        if (val.sendType == 1)            
            this.sendSingleEmail();
        else if (val.sendType == 2)            
            this.sendRegistrationMessages();
    }
    
    onSearchChange(event) {       
        this.isValidEmail = this.mainForm.controls.sendType.value != 1 || this.getSingleEmail() != '';
    }
}
