import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Company } from "../models/company.model";
import { QuestionAssessmentStatus } from "../models/question-assessment-status.model";
import { CompanyService } from "../services";
import { StateValue } from "../shared/models/state-value.model";

@Injectable({
    providedIn: 'root'
})
export class AppState {        
    public readonly company = new StateValue<Company>(null);

    // assessment    
    public readonly assessmentCurrentQuestionIndex = new StateValue<number>(-1);
    public readonly questionAssessmentStatusList = new StateValue<QuestionAssessmentStatus[]>([]);

    // goals
    public readonly groupGoalsCount = new StateValue<number>(0);

    public readonly prevLocation = new StateValue<string>(null);
    public readonly currentLocation = new StateValue<string>(null);

    constructor(private companyService: CompanyService) {
                
    }

    loadCompanyInfo(): Observable<Company> {
        console.log('Loading Company Info');
        return this.companyService.getCompanyInfo().pipe(
            tap(data => {
                this.company.value = data;
            })
        )
    }
   
    setNextQuestion(updatedQuestionIndex: number) {        
        const nextIndex = this.getNextQuestionIndex(updatedQuestionIndex);

        if (this.assessmentCurrentQuestionIndex.value != nextIndex)
            this.assessmentCurrentQuestionIndex.value = nextIndex;
    }
    
    // ha változik egy kérdés kitöltöttségének állapota, megkeressük a következő kitöltetlen kérdést
    getNextQuestionIndex(currentIndex: number): number {
        if (currentIndex == -1) return currentIndex;

        let list = this.questionAssessmentStatusList.value
        for (let i = currentIndex; i < list.length; i++) {
            const item = list[i];

            if (!item.isComplete) {
                return i;
            }
        }

        return list.length;
    }
}