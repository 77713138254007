import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoalsHelper } from 'src/app/helpers/goals.helper';
import { Action, ActionModel } from 'src/app/models/action.model';
import { Employee } from 'src/app/models/employee.model';
import { Goal } from 'src/app/models/goal.model';
import { GoalPeriod } from 'src/app/models/goalperiod.model';
import { ListItem } from 'src/app/models/list-item.model';
import { GoalService, StateService } from 'src/app/services';

@Component({
  selector: 'app-goal-action-edit',
  templateUrl: './goal-action-edit.component.html',
  styleUrls: ['./goal-action-edit.component.scss']
})
export class GoalActionEditComponent implements OnInit {

  @Input()
  public loading = true;

  @Input()
  public isSelf = false;

  @Input()
  public action: ActionModel;

  @Input()
  public currentPeriod: GoalPeriod;

  @Input()
  public leaders: Employee[];

  @Input()
  public goal: Goal;  

  @Output()
  public added = new EventEmitter<Action>();

  @Output()
  public updated = new EventEmitter<Action>();

  @Output()
  public deleted = new EventEmitter<number>();

  constructor(private goalService: GoalService, private stateService: StateService)
  { }

  public quarters: ListItem[];

  public get isLeaderOfSelectedEmployee(): boolean {
    const currentEmployeeId = this.stateService.state.me.value.employee.id;
    return currentEmployeeId === this.action.leaderId;
  }

  ngOnInit(): void {
    this.quarters = GoalsHelper.quarters;
  }  

  isValidAddAction(action: Action): boolean {
    return action.name != '';
  }

  addAction(goal: Goal) {                        
    this.loading = true;
    
    this.goalService.insertAction(goal.id, this.action).subscribe((action) => {
        this.loading = false;
        this.action.id = action.id;
        this.added.emit(action);
    }, (error) => {
        console.log(error);
        this.loading = false;
    });        
  };

  updateAction(action: Action) {
    if (action.name) {
        this.loading = true;
        
        this.goalService.updateAction(action).subscribe((result) => {
            this.loading = false;
            this.updated.emit(action);
        }, (error) => {
            if (confirm('Hiba történt a mentés során, megpróbálja újra?')) {
                this.updateAction(action);
            }
            console.log(error);
            this.loading = false;
        });
    }
  };

  saveAction(action: Action, goal: Goal) {
    if (!this.isValidAddAction(action)) return;
    
    action.id > 0 ? this.updateAction(action) : this.addAction(goal);
  }

  deleteAction(goal: Goal, actionId: number) {
    if (actionId == 0) {
      goal.actions = goal.actions.filter(item => {
        return item.id != 0;
      });

      return;
    }

    if (confirm("Biztosan törli a tevékenységet?")) {
        this.loading = true;
        this.goalService.deleteAction(actionId).subscribe((result) => {
            this.deleted.emit(actionId);
            this.loading = false;
        }, (error) => {
            console.log(error);
            this.loading = false;
        });
    }
  };  

  progressChanged(event, action: Action, goal: Goal) {
    this.action.progress = event.value;
    this.saveAction(action, goal);
  }

  leaderProgressChanged(event, action: Action, goal: Goal) {
    this.action.leaderProgress = event.value;
    this.saveAction(action, goal);
  }

}
