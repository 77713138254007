import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeGroup } from 'src/app/models/employee-group.model';
import { IResult, Result } from 'src/app/models/general/result.model';
import { EmployeeGroupService } from 'src/app/services/employee-group.service';
import { ErrorMessageService } from 'src/app/shared/services';

@Component({
  selector: 'app-employee-group-add',
  templateUrl: './employee-group-add.component.html',
  styleUrls: ['./employee-group-add.component.scss']
})
export class EmployeeGroupAddComponent implements OnInit {

  public mainForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder, 
    public dialogRef: MatDialogRef<EmployeeGroupAddComponent>,
    private employeeGroupService: EmployeeGroupService,
    private msgService: ErrorMessageService,
    @Inject(MAT_DIALOG_DATA) public data: EmployeeGroup) { }

  ngOnInit(): void {
    this.mainForm = this.createForm();
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      'id': 0,
      'name': ['', [Validators.required]],
    });
  }

  public ok() {
    if (!this.mainForm.invalid) {
      let formData = this.mainForm.value as EmployeeGroup;
      formData.name = formData.name.trim();

      this.employeeGroupService.addGroup(formData).subscribe({ next:(result) => {
        this.dialogRef.close(result.data);
      }, error: (err: IResult<EmployeeGroup, Result<EmployeeGroup>>) => {
        this.msgService.handleErrorWithData<EmployeeGroup, Result<EmployeeGroup>>(err);
      }});
    }
  }

}
