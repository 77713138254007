import { formatDate } from "@angular/common";
import { ReviewPeriod } from "src/app/models/review-period.model";
import { AssessmentTypeEnum } from "../enums/assessment-type.enum";
import { ReviewPeriodStatusEnum } from "../enums/review-period-status.enum";
import { ReviewTypeEnum } from "../enums/review-type.enum";
import { IdName } from "../models/general/id-name.model";
import { ReviewType } from "../models/review-type.model";
import { SettingsState } from "../store/settings.state";
import { State } from "../models/state.model";

export abstract class ReviewPeriodHelper {

    public static reviewTypes: ReviewType[] = [
      {id: ReviewTypeEnum.TER, name: 'Panoráma és Aura'},
      {id: ReviewTypeEnum.PersonalityTest, name: 'Sziluett'},
      {id: ReviewTypeEnum.MilioStressMap, name: 'Miliő - Stressztérkép'},   
      {id: ReviewTypeEnum.MilioTeamDysfunction, name: 'Miliő - Működési térkép'},   //diszfunkciók, működési zavarok
    ];

    public static reviewPeriodStatusList: ReviewPeriodStatus[] = [
      {id: ReviewPeriodStatusEnum.New, name: 'Előkészítés alatt'},
      {id: ReviewPeriodStatusEnum.Open, name: 'Nyitott'},
      {id: ReviewPeriodStatusEnum.Finished, name: 'Befejezett'},
    ];

    public static assessmentTypeList: AssessmentType[] = [
      {id: 0, name: '-- autodetect --'},
      {id: AssessmentTypeEnum.Self, name: 'Önértékelés'},
      {id: AssessmentTypeEnum.Leader, name: 'Főnök értékeli a beosztottat'},
      {id: AssessmentTypeEnum.Peer, name: 'Peer értékelés'},
      {id: AssessmentTypeEnum.Subordinate, name: 'Beosztott értékeli a vezetőt'},
    ];

    

    public static getCurrentReviewPeriod(list: ReviewPeriod[]): ReviewPeriod {
        let now = new Date();
        
        let result = list.filter(x => {
            //let startDate = Date.parse(x.startDate);
            //let endDate = Date.parse(x.endDate);
            return x.startDate <= now && now <= x.endDate;
        });

        return result.length > 0 ? result[0] : list[0];
    }

    public static isBlockEditable(settingState: SettingsState, state: State): boolean {
      const item = settingState.reviewPeriod.value;
      const items = settingState.reviewPeriodList.value;
  
      if (!state.isSuperAdmin && !state.isAdmin) return false;
  
      if (!item || items.length === 0) return false;
      
      if (item.status == ReviewPeriodStatusEnum.Finished)
        return false;
      
      return true;
    }

    public static getName(rpType: ReviewTypeEnum): string {
        const item = ReviewPeriodHelper.reviewTypes.find(x => x.id === rpType as number);
        return item.name;
    }

    public static getStatusName(status: ReviewPeriodStatusEnum): string {
      const item = ReviewPeriodHelper.reviewPeriodStatusList.find(x => x.id === status as number);
      return item.name;
    }

    public static getDisplayText(rp: ReviewPeriod): string {
        if (!rp) return '';
        if (!rp.id) return rp.name;

        const typeName = ReviewPeriodHelper.getName(rp.reviewType);
        const rpName = rp.name ? rp.name : '';

        return `${typeName} - ${rpName}`;
  }

  public static getDateRange(rp: ReviewPeriod): string {
    if (!rp || !rp.startDate || !rp.endDate) return '';
    return `${formatDate(rp.startDate,'yyyy-MM-dd', 'en-US')} - ${formatDate(rp.endDate,'yyyy-MM-dd', 'en-US')}`;
  }

  public static getReviewTypes(): ReviewType[] {
      return ReviewPeriodHelper.reviewTypes;
  }

  public static getReviewPeriodStatusList(): ReviewPeriodStatus[] {
    return ReviewPeriodHelper.reviewPeriodStatusList;
  }

  public static getAssessmentTypeList(): AssessmentType[] {
    return ReviewPeriodHelper.assessmentTypeList;
  }

  public static getListTER(allReviews: ReviewPeriod[]): ReviewPeriod[] {
      return allReviews.filter(x => x.reviewType == ReviewTypeEnum.TER);
  }

  public static getLastTER(allReviews: ReviewPeriod[]): ReviewPeriod {
    const items = ReviewPeriodHelper.getListTER(allReviews);
    return items[items.length - 1];
  }
}

export interface ReviewPeriodStatus extends IdName {
}

export interface AssessmentType extends IdName {

}