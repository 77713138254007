export interface Assessment {
    assessmentId?: number;
    reviewPeriodEmployeeId?: number;
    questionId: number;

    answerText: string;
    answerId?: number;

    answerIdList: number[];
    maxRating: number;
}

export class AssessmentModel implements Assessment {
    assessmentId = null;
    reviewPeriodEmployeeId = null;
    questionId = 0;

    answerText: '';
    answerId = null;
    noAnswer = false;

    answerIdList = [];
    maxRating = 0;
}