import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { ActionForReviewPeriodDialogConfig } from 'src/app/models/action-for-review-period-dialog-config.model';
import { ReviewPeriod } from 'src/app/models/review-period.model';
import { ReviewPeriodService } from 'src/app/services';
import { CustomValidators } from 'src/app/shared/helpers/custom-validators';
import { EmployeeState } from 'src/app/store/employee.state';

import { ErrorMessageService } from 'src/app/shared/services';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';


@Component({
  selector: 'app-action-for-review-period-dialog',
  templateUrl: './action-for-review-period-dialog.component.html',
  styleUrls: ['./action-for-review-period-dialog.component.scss']
})
export class ActionForReviewPeriodDialogComponent implements OnInit {

  public mainForm: UntypedFormGroup;
  
  public loaded = false;

  public reviewPeriodList: ReviewPeriod[] = [];

  public title: string;
  public message: string;

  public get reviewPeriods(): FormArray {
    return this.mainForm.get('reviewPeriods') as FormArray;
  }
   
  public reviewTypes = ReviewTypeEnum;
  public getDisplayText = ReviewPeriodHelper.getDisplayText;

  constructor(
    private fb: UntypedFormBuilder, 
    public dialogRef: MatDialogRef<ActionForReviewPeriodDialogComponent>,
    private msgService: ErrorMessageService,
    private reviewPeriodService: ReviewPeriodService,
    private employeeStore: EmployeeState, 
    
    @Inject(MAT_DIALOG_DATA) public data: ActionForReviewPeriodDialogConfig) { }

  ngOnInit(): void {    
    this.loaded = false;

    this.title = this.data.title;
    this.message = this.data.message;
    
    this.reviewPeriodService.getListNotClosed(this.employeeStore.employee.value.id).subscribe({ next: result => {
      this.reviewPeriodList = this.setupReviewPeriodList(result); 
                      
      this.mainForm = this.createForm();
      
      this.patchList();

      this.loaded = true;
              
    }, error: () => {

    }});
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({      
      'reviewPeriods': this.fb.array([], CustomValidators.minSelectedCountArray(1))
    });
  }
  
  private setupReviewPeriodList(list: ReviewPeriod[]) {
    const firstItem = {id: null, name: '---'} as ReviewPeriod;
    list = [firstItem, ...list];

    return list.map(x => {      
      const item = {...x, isSelected: false} as ReviewPeriod;

      if (x.id === 0)
        item.isSelected = true;

      return item;
    });
  }

  patchReviewPeriod(item: ReviewPeriod)
  {
    return this.fb.group({
      'id': item.id,
      'name': item.name,
      'isSelected': item.isSelected
    });
  }

  private patchList() {
    const list = <FormArray>this.mainForm.get('reviewPeriods');
    list.clear();

    this.reviewPeriodList.forEach(item => {
      list.push(this.patchReviewPeriod(item));
    });
  }
 
  public ok() {
    if (!this.mainForm.invalid) {

      let formData = (this.mainForm.value.reviewPeriods as ReviewPeriod[]).filter(x => x.isSelected).map(x => x.id);
      this.dialogRef.close(formData);
    }
  }

}
