import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../models/company.model';
import { DataService } from './data.service';
import { StateService } from './state.service';
import { CompanyStat } from '../models/company-stat.model';
import { PagedListResult } from '../models/paged-list-result.model';
import { Search } from '../models/search.model';

@Injectable({
    providedIn: 'root',
})
export class CompanyService extends DataService {
    
    constructor(http: HttpClient, stateService: StateService) {
        super(http, stateService);
        this.controllerName = 'company';
    }
   
    getList = (options?: Search): Observable<PagedListResult<Company>> => {
        if (!options) options = {search: ''} as Search;

        return this.post<PagedListResult<Company>>(`/api/${this.controllerName}/list`, options, {headers: {'X-Skip-Interceptor':'1'}});
    }
    
    getStats(): Observable<CompanyStat[]> {
        return this.get<CompanyStat[]>(`/api/${this.controllerName}/stat-list`);
    }

    add(model: Company): Observable<Company> {
        return this.put<Company>(`/api/${this.controllerName}/add`, model);
    }

    update(model: Company): Observable<void> {
        return this.post<void>(`/api/${this.controllerName}/update`, model);
    }

    getCompanyInfo(): Observable<Company> {
        return this.get<Company>(`/api/${this.controllerName}/company-info`);
    }
}
