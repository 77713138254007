import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-search-simple',
  templateUrl: './search-simple.component.html',
  styleUrls: ['./search-simple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSimpleComponent),
      multi: true,
    },
  ],
})
export class SearchSimpleComponent<T> implements OnInit, ControlValueAccessor {

  @Input() placeholder: string;
  @Input() data: T[]; // Input to pass the generic data
  @Output() searchChange = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter<T>(); // Output event for selected item

  private innerValue: T;

  constructor() {}

  ngOnInit() {}

  onSearchChange(value: string) {
    this.searchChange.emit(value);
  }

  selectItem(item: T) {
    this.innerValue = item;
    this.itemSelected.emit(item); // Emit the selected item
    this.onChange(item); // Notify reactive form
  }

  // Implement ControlValueAccessor methods
  writeValue(value: T) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {}

  private onChange = (value: T) => {};

}
