
<ng-container *ngIf="{ result: checkResult$ | async} as checkResult">
    <h1 mat-dialog-title>Felhasználók hozzáadása a "{{ data.title }}" csoporthoz</h1>
    <div mat-dialog-content>
        
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h2>Email lista</h2>
                    <mat-form-field appearance="fill">
                        <textarea matInput [(ngModel)]="inputText" (ngModelChange)="inputFieldChanged()" placeholder="Illessze be az email listát"></textarea>
                      </mat-form-field>
                </div>
                <div class="col-md-6">
                    <h2>Azonosított dolgozók</h2>
                    <div class="list-items list-employee" >
                        <div *ngIf="checkResult.result">
                            <div *ngFor="let item of checkResult.result.validEmployees">
                                {{ item.name + " " + (item.employeeIdentifier ?? '') }}
                            </div>
                        </div>
                    </div>
        
                    <h2>Nem hozzáadható email címek</h2>
                    <div class="invalid-message">Az email cím nem található a rendszerben,
                        vagy a csoportban már szerepel az adott alkalmazott fönőke.</div>
                    <div class="list-items list-invalid-emails">
                        <div *ngIf="checkResult.result">
                            <div *ngFor="let item of checkResult.result.invalidEmails">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    <div mat-dialog-actions class="flex-h flex-end">
        <button mat-flat-button color="primary" #okButton (click)="ok(checkResult.result.validEmployees)" cdkFocusInitial [disabled]="!isAllowSubmit">Ok</button>
        <button mat-flat-button [mat-dialog-close]>Mégsem</button>
    </div>
</ng-container>
