 <!-- freetext -->
 <div *ngIf="freeTexts && freeTexts.length > 0" class="padding-top-lg break-inside-avoid" [ngClass]="{'side-margins': !noMargin}">
        
    <div class="title text-center">SZABADSZAVAS VISSZAJELZÉSEK</div>
    <div class="sub-title text-center" *ngIf="showSubTitle">{{ subTitle }}</div>
    
    <div class="content">
        <div *ngFor="let item of freeTexts" class="text-line text-justify">
            <p>{{ item }}</p>
        </div>
    </div>
</div>
