export abstract class DataTypeHelper {

    // https://www.webbjocke.com/javascript-check-data-types/

    public static isObject(value: any): boolean {
        return value && typeof value === 'object' && value.constructor === Object;
    }

    public static isArray(value: any): boolean {
        return value && typeof value === 'object' && value.constructor === Array;
    }

    public static isEmpty(e: string): boolean { 
        return e === null || e === undefined || e === '';
    }

}