import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Toggleable } from '../../interfaces/toggleable.interface';

@Component({
    selector: 'dynamic-text',
    templateUrl: './dynamic-text.component.html',
    styleUrls: ['./dynamic-text.component.scss']
})
export class DynamicTextComponent implements OnInit, Toggleable {
    @Input() 
    public content: string;

    @Input() 
    public opened = false;

    @Input()
    public useCard = true;

    public htmlData: string;
    //@HostBinding('class.full-height') get isNoClose() { return this.opened; }

    constructor(private http: HttpClient) {        
    }        

    ngOnInit() {
        if (this.content)
        {
            this.getHtml(this.content).then((html: any) => 
                this.htmlData = html
            );
        }
    }

    getHtml(url: string) {
        return this.http.get(url, {responseType: 'text'}).toPromise();
    }

    toggle() {
        this.opened = !this.opened;
    }

}
