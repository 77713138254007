import { UpdateType } from "../shared/enum/update-type.enum";
import { KpiType, KpiTypeModel } from "./kpiType.model";

export interface Kpi {
    id: number;
    kpiType?: KpiType;
    kpiTypeId?: number;

    planned?: number;
    achieved?: number;

    isAssessed: boolean;
    noAnswer?: boolean;
    error: boolean;

    aboutUserId: string;
    aboutEmployeeId?: number;
    byEmployeeId?: number;
    reviewPeriodId?: number;

    updateType?: UpdateType;
}

export class KpiModel implements Kpi {
    id = 0;
    kpiType?: KpiType;
    kpiTypeId?: number;
    
    planned? = null;
    achieved? = null;

    isAssessed = false;
    noAnswer = false;
    error = false;

    aboutUserId: string;
    aboutEmployeeId?: number;
    byEmployeeId?: number;
    reviewPeriodId?: number;

    updateType?: UpdateType;
     
    constructor() {                
        this.kpiType = new KpiTypeModel();
    }
}