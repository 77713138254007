import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-progress',
  templateUrl: './page-progress.component.html',
  styleUrls: ['./page-progress.component.scss']
})
export class PageProgressComponent implements OnInit {

  @Input() 
  public value = 0;

  @Input()
  public currentItem?: number;

  @Input()
  public maxItems?: number;

  constructor() { }

  ngOnInit(): void {
  }

}
