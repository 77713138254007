import { Component, OnInit } from '@angular/core';
import { SystemService } from 'src/app/services/system-settings.service';
import { ErrorMessageService } from 'src/app/shared/services';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent implements OnInit {

  constructor(private service: SystemService, private msgService: ErrorMessageService) { }

  ngOnInit(): void {
  }

  purgeCache() {
    this.service.purgeCache().subscribe(() => {
      this.msgService.showSnackBarMessage("cacheDeleted");
    });
  }
}
