import { Component, Input, OnInit } from '@angular/core';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { TextGuide } from 'src/app/models/text-guide.model';
import { TextGuideService } from 'src/app/services/text-guide.service';

@Component({
  selector: 'app-product-guide',
  templateUrl: './product-guide.component.html',
  styleUrls: ['./product-guide.component.scss']
})
export class ProductGuideComponent implements OnInit {

  @Input()
  public productType: ReviewTypeEnum;
  
  @Input()
  public category: string;

  public textGuide: TextGuide;

  public visibleGuide = true;

  constructor(private textGuideService: TextGuideService) { }

  ngOnInit(): void {
    this.textGuide = this.textGuideService.getGuideForReviewType(this.category, this.productType);
  }

  toggleGuides()
  {
    this.visibleGuide = !this.visibleGuide;
  }

}
