import { Injectable } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, of } from "rxjs";
import { StateService } from ".";
import { State } from "../models/state.model";
import { AppState } from "../store/app.state";

@Injectable({
    providedIn: 'root'
})
export class AppInitService {
    
    private state: State;

    constructor(
        private appState: AppState,
        stateService: StateService,
        private permissionsService: NgxPermissionsService
        ) 
    {
        this.state = stateService.state;
    }

    load(): Observable<any> {
        console.log('App Initializer, loading data...');
        this.loadRoles();
        return this.state.isLoggedIn ? this.appState.loadCompanyInfo() : of();
    }

    loadRoles() {
        this.permissionsService.loadPermissions([this.state?.me.value?.role]);
    }
}