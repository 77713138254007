import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxRolesService } from 'ngx-permissions';
import { from, Observable, of } from 'rxjs';
import { State } from 'src/app/models/state.model';
import { UserProfile } from 'src/app/models/user-profile.model';
import { StateService } from 'src/app/services';
import { AuthService } from 'src/app/services/auth.service';
import { CardComponent, SearchComponent } from 'src/app/shared/components';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { EmployeeState } from 'src/app/store/employee.state';

@UntilDestroy()
@Component({
  selector: 'app-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss']
})
export class EmployeeSearchComponent implements OnInit, AfterViewInit {

  public model$: Observable<UserProfile>;
  public editControl: UntypedFormControl;   
  public isAllowSearch$: Observable<boolean>;
  public inlineButtons = true;
  public state: State;

  public employeeIdentifier: string;

  @ViewChild('card')
  card: CardComponent;

  @ViewChild('search')
  search: SearchComponent<UserProfile, string>;

  public searchValue: string;

  public get allowEdit(): boolean {
    return this.state.isSuperAdmin || this.state.isAdmin || (this.employeeStore.isBoss.value && this.employeeStore.employee.value.hasBoss);
  }

  public get allowAdd(): boolean {
    return this.search && typeof this.search.searchControl.value === 'string';
  }
  
  public searchConfig: SearchConfig<UserProfile, string> = {
    mapItem: (model: UserProfile) => {
      return {
        id: model.id, 
        text: model.employee ? model.employee.name : model.email,
        dropText: model.employee ? `${model.employee.name} ${model.employee.employeeIdentifier ? model.employee.employeeIdentifier : ''}` : model.email,
        itemSource: model } as SearchOption<UserProfile, string>;
    },
    getList: this.authService.getUsers
  };
   
  constructor(
    public authService: AuthService,
    private employeeStore: EmployeeState, 
    private router: Router, 
    private msgService: ErrorMessageService,     
    private roleService: NgxRolesService,
    private stateService: StateService,
    private cd: ChangeDetectorRef)
  {
    this.state = stateService.state;
    this.model$ = this.employeeStore.employee.obs$;
  }
  ngAfterViewInit(): void {
    // Init Search
    this.model$
    .pipe(untilDestroyed(this))
    .subscribe((data) => {
      if (data) {
        this.employeeIdentifier = data.employee?.employeeIdentifier;
        this.initControls(data);
        this.cd.detectChanges();
      }
    });  
  }

  ngOnInit(): void {
    this.editControl = new UntypedFormControl();    
    this.isAllowSearch$ = of(this.stateService.state.isSuperAdmin || this.stateService.state.isAdmin || this.stateService.state.isAssistant || this.stateService.state.me.value.isBoss);
  }
  
  public initControls(data: UserProfile) {
    let option = this.searchConfig.mapItem(data);
    this.searchValue = option.text;
    this.editControl.setValue(option.text);
    this.search.loadValue(option);
  }

  public addItem(option: string) {
    if (typeof this.search.searchControl.value === 'string') {
      this.employeeStore.personNameToAdd = this.search.searchControl.value;
      this.router.navigate([`/employee-add`]);
    }    
  }

  onOkClick() {
    if (!this.editControl.invalid) {      
      let user = {...this.employeeStore.employee.value};
      user.employee.name = this.editControl.value;
            
      this.employeeStore.updateEmployeeName(user).subscribe(() => {
        this.msgService.showSnackBarMessage('saveSuccessful');
        this.card.actionState.editMode = false;
      }, error => {
        this.msgService.showErrorResponse(error);
      });      
    }    
  }

  onCancelClick() {
    this.card.actionState.editMode = false;
  }

  onOptionClick(option: SearchOption<UserProfile, string>) {    
    if (!!(option as any).type) return;

    this.router.navigate([`/employee/${option.id}`]);
  }

  onCardEditToggle(val: boolean) {
    // if (val)
    // {      
    //     this.initEdit();
    // }
  }


}
