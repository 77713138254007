import { Pipe, PipeTransform } from "@angular/core";
import { ReviewPeriodHelper } from "../helpers/review-period.helper";
import { ReviewPeriod } from "../models/review-period.model";

@Pipe({name: 'reviewPeriodType'})
export class ReviewPeriodTypePipe implements PipeTransform {

  transform(value: number) {
      if (!value) return '';
      
      return ReviewPeriodHelper.getName(value);
  }
}

@Pipe({name: 'periodDate'})
export class PeriodTypeDatePipe implements PipeTransform {

  transform(value: ReviewPeriod) {
      if (!value) return '';
      
      return ReviewPeriodHelper.getDateRange(value);
  }
}

@Pipe({name: 'periodName'})
export class ReviewPeriodNamePipe implements PipeTransform {

  transform(value: ReviewPeriod) {
      if (!value) return '';
      
      return ReviewPeriodHelper.getDisplayText(value);
  }
}