<div *ngIf="!readonly && action.id > 0" class="quarters editmode">
    <div *ngFor="let quarter of quartersOrder; index as i">
        <button mat-flat-button class="icon-button button-menu" [matMenuTriggerFor]="menu" [ngClass]="getClassList(i)"><strong>{{ qt(period, i) | uppercase }}</strong> <mat-icon class="hidden-print">expand_more</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let prState of progressState" (click)="action[qt(period, i)] = prState.id; quarterDataChanged()">{{ prState.name }}</button>
        </mat-menu>
    </div>
</div>
<div *ngIf="readonly" class="quarters display-only">
    <div *ngFor="let quarter of quartersOrder; index as i">
        <button mat-flat-button class="icon-button button-menu vertical-middle" [disableRipple]="true" [ngClass]="getClassList(i)"><strong>{{ qt(period, i) | uppercase }}</strong> </button>
    </div>
</div>

