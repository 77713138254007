
<div class="login-container">
    <div class="site-branding">
        <div class="site-logo">
            <img src="/assets/img/logo-quantille-white-lg.png" [alt]="state.config.t.title">
        </div>
    </div>
    <mat-card class="box">
        <div class="card-title">
            {{ actions[currentMode].title }}
        </div>            
        <mat-card-content>
            <mat-tab-group class="hide-labels" #tabs>
                <mat-tab label="Login Form"> 
                    <form [formGroup]="loginForm">

                        <div class="input-label">Email</div>
                        <mat-form-field appearance="fill">
                            <input matInput class="no-bkg" type="text" name="username" formControlName="username" autocomplete="username" required>
                            <mat-error msg></mat-error>
                        </mat-form-field>
                        
                        <div class="input-label">Jelszó</div>
                        <mat-form-field appearance="fill">
                            <input matInput class="no-bkg" type="password" namme="password" formControlName="password" autocomplete="current-password" required>
                            <mat-error msg></mat-error>
                        </mat-form-field>
                    </form>        
                </mat-tab>
                <mat-tab label="Forgotten Password Form"> 
                    <form [formGroup]="passwordForm">
                        
                        <div class="input-label">E-mail</div>
                        <mat-form-field appearance="fill" class="mb-70">
                            <input matInput class="no-bkg" type="text" name="username" formControlName="username" autocomplete="username" required>
                            <mat-error msg></mat-error>
                        </mat-form-field>                        
                    </form>
                </mat-tab>                
              </mat-tab-group>

            
        </mat-card-content>
            
        <div class="action-buttons">
            <button mat-flat-button color="accent" (click)="ok()">{{ actions[currentMode].okButtonText }}</button>
            <button mat-flat-button color="primary" class="passwordreset button-lg" (click)="switchMode()">{{ actions[currentMode].switchModeButtonText }}</button>
        </div>
            
    </mat-card>    
</div>
