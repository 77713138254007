import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, tap } from "rxjs/operators";
import { ActionStateModel } from "../../models/action-state.model";
import { SearchOption } from "../../models/search-option.model";

@Component({
  selector: 'app-search-base',
  template: '',    
})
export abstract class SearchBaseComponent<T, TId> {

    @Output()
    public add: EventEmitter<string | SearchOption<T, TId>> = new EventEmitter<string | SearchOption<T, TId>>();

    public getList$: Observable<T[]>;
    public options$: Observable<SearchOption<T, TId>[]>;
    public filteredOptions$: Observable<SearchOption<T, TId>[]>;
    public loading$ = new BehaviorSubject(false);
  
    public list: SearchOption<T, TId>[];
    public searchControl: UntypedFormControl;
    public editControl: UntypedFormControl;
    
    //Search config
    public title: string;
    public placeholder: string;
    public addIcon = 'add_circle_outline';
    public showAddButton = true;

    @Input()
    public disabledAddButton = false;
    public disabled = false;
    public showEditButton = false;
    public actionState = new ActionStateModel();
    public inlineButtons = false;     //when editMode is true, this will allow to show the Ok and cancel buttons
    public isAllowSearch = false;
    // --
  
    protected mapItem: (model: T) => SearchOption<T, TId>;
  
    public get searchValue(): string {
      const srcOption = this.findItem(this.list, this.searchControl.value.id);
      return srcOption ? srcOption.text : '';
    }
 
    public displayFn(opt: SearchOption<T, TId>): string {
        return opt && opt.text ? opt.text : '';
    }
    
    protected loadValue(model: T) {
      this.list = new Array<SearchOption<T, TId>>();
      this.list.push(this.mapItem(model));
    } 
  
    protected initEdit() {
      const editText = this.findItem(this.list, this.searchControl.value.id).text;
      this.editControl.setValue(editText);
    }

    protected initComponent() {
      
      // Init Search
      this.searchControl = new UntypedFormControl('');
      // --

      this.loading$.next(true);
      this.options$ = this.getList$.pipe(
          map((items) => {
            return items.map((item) => this.mapItem(item));
          }),
          tap(list => {
            this.loading$.next(false);
          })
      );

      this.options$.subscribe(data => {
        this.filteredOptions$ = this.searchControl.valueChanges.pipe(
          debounceTime(400),
          distinctUntilChanged(),
          startWith(''),
          map(value => typeof value === 'string' ? value : value.text),
          map((srcText: string) => {
            return data.filter(option => this.textContains(option.text, srcText) );
          })
        );
      });
    }
    
    protected findItem(list: SearchOption<T, TId>[], id: TId): SearchOption<T, TId> {
      return Array.isArray(list) ? list.find(x => x.id === id) : null;
    }

    protected textContains(text: string, search: string): boolean {
      return text.toLowerCase().indexOf(search.toLowerCase()) > -1;
    }
  
    protected onAdd() {      
      if (!this.searchControl.value) return;
      if (this.disabled) return;

      this.disabled = true;
      this.add.emit(this.searchControl.value);
      this.searchControl.setValue('');
    } 
  
    protected onOptionSelected(option: SearchOption<T, TId>) {
           
    }   
  
    protected onOk() {
      this.actionState.editMode = false;
      this.disabled = false;
    }
  
    protected onCancel() {
      this.actionState.editMode = false;
      this.disabled = false;
      this.editControl.reset();
    }
  }