export interface ReviewPeriodQuestionSettings {
    id: number;
    reviewPeriodId: number;
    questionId: number;
    
    isLeaderAssessmentAllowed: boolean;
    isPeerAssessmentAllowed: boolean;

    isSelfAssessmentAllowed: boolean;
    isSpecialQuestion: boolean;

    questionName: string;
}

export class ReviewPeriodQuestionSettingsModel implements ReviewPeriodQuestionSettings {
    id = 0;
    reviewPeriodId = 0;
    questionId = 0;
    
    isLeaderAssessmentAllowed = true;
    isPeerAssessmentAllowed = true;

    isSelfAssessmentAllowed = false;
    isSpecialQuestion = false;

    questionName = '';
}