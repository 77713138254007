import { Component, Inject, Input} from '@angular/core';
import { ErrorMessageService } from '../../services';

@Component({
  selector: 'validation-messages',
  templateUrl: './validation-messages.component.html',  
})
export class ValidationMessagesComponent {
  
  constructor(private msgService: ErrorMessageService) {
    
  }
      
  @Input() msgList: string[] = [];
  @Input() displayErrorTitles = false;
    
  getMessage(key: string): string {
      const msg = this.msgService.getErrorMessage(key);
      return msg.text;
  }
}
