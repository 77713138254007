import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyUserRole } from 'src/app/models/company-user-role.model';
import { Company } from 'src/app/models/company.model';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { Role } from 'src/app/models/role.model';
import { CompanyService, UserRolesService } from 'src/app/services';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ErrorMessageService } from 'src/app/shared/services';

@Component({
  selector: 'app-company-user-role-dialog',
  templateUrl: './company-user-role-dialog.component.html',
  styleUrls: ['./company-user-role-dialog.component.scss']
})
export class CompanyUserRoleDialogComponent implements OnInit {

  public mainForm: UntypedFormGroup;

  public companyList: Company[];
  public roleList: Role[];
  
  public loaded = false;

  public newMode = false;

  public searchCompanyConfig: SearchConfig<Company, number> = {
    mapItem: (model: Company) => {
      return {
        id: model.id, 
        text: `${model.name}`,
        dropText: `${model.name}`,
        itemSource: model } as SearchOption<Company, number>;
    },
    getList: this.companyService.getList
};


  constructor(
    private fb: UntypedFormBuilder, 
    public dialogRef: MatDialogRef<CompanyUserRoleDialogComponent>,    
    private msgService: ErrorMessageService,
    private userRoleService: UserRolesService,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: CompanyUserRole) { }

  ngOnInit(): void {
    this.mainForm = this.createForm();

    this.loaded = false;

    this.newMode = this.data.companyId === null;
    
    this.userRoleService.getRoles().subscribe({ next: result => {
      this.roleList = result;
      this.loaded = true;
      const formData = this.createFormData(this.data);

      this.mainForm.patchValue(formData);
    }});  
    
  }
  
  private createForm(): UntypedFormGroup {
    return this.fb.group({
      'userId': ['', [Validators.required]],
      'company': [null, [Validators.required]],
      'roleId': [null, [Validators.required]]
    });
  }

  private createFormData(result: CompanyUserRole): any {

    let company: SearchOption<Company, number> = null;
        
    if (result.companyId)
    {
      company = {
        id: result.companyId, 
          dropText: result.companyName, 
          text: result.companyName,
          itemSource: {id: result.companyId, name: result.companyName} as Company
      } as SearchOption<Company, number>;
    }
        
    const formData = {
      userId: result.userId,
      companyId: result.companyId,
      roleId: result.roleId,
    } as CompanyUserRole;
    
    return {...formData, company: company};
  }
  
  public ok() {
    if (!this.mainForm.invalid) {

      let formData = this.mainForm.value;

      const dto = {
        userId: formData.userId,
        companyId: formData.company.id,
        roleId: formData.roleId
      } as CompanyUserRole;
            
      const action = !this.newMode ? 
        this.userRoleService.updateItem<ResultModel>(dto) : this.userRoleService.addItem<ResultModel>(dto);

      action.subscribe({ 
          next: (data) => {
            this.dialogRef.close(true);
          }, 
          error: (err: IResultModel<ResultModel>) => {
            this.msgService.handleError<ResultModel>(err);
          }
        }
      );      

    }
  }

}
