import { Pipe, PipeTransform } from "@angular/core";
import { ReviewTypeEnum } from "../enums/review-type.enum";
import { AssessmentHelper } from "../helpers/assessment.helper";
import { ReviewPeriodHelper } from "../helpers/review-period.helper";
import { ReviewPeriodForEmployee } from "../models/review-period-for-employee.model";

@Pipe({name: 'assessmentStatus'})
export class AssessmentStatusPipe implements PipeTransform {

  transform(item: ReviewPeriodForEmployee) {
      if (item.reviewPeriod.reviewType === ReviewTypeEnum.TER || !item.reviewPeriodEmployee) {
        return ReviewPeriodHelper.getStatusName(item.reviewPeriod.status);
      }
      else
      {
        return AssessmentHelper.getName(item.reviewPeriodEmployee.status);
      }

  }
}

@Pipe({name: 'assessmentStatusName'})
export class AssessmentStatusNamePipe implements PipeTransform {

  transform(value?: number) {
    if (!value) return '';
    return AssessmentHelper.getName(value);
  }
}