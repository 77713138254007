import { Component, Input } from '@angular/core';
import { Toggleable } from '../../interfaces/toggleable.interface';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent {

  @Input() icon = 'info';
  @Input() block: Toggleable;

  public toggle()
  {
    this.block.toggle();
  }

}
