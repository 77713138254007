<form [formGroup]="mainForm" *ngIf="loaded">
    <h1 mat-dialog-title>{{title}}</h1>
    <div mat-dialog-content>
  
        <div formArrayName="reviewPeriods" class="settings-panel">
            <div class="panel-title">Értékelések</div>
            <div class="panel-content simple-border">
                <ng-container *ngFor="let child of reviewPeriods.controls; let i = index">
                    <ng-container [formGroupName]="i">
                        <mat-checkbox class="multiline" formControlName="isSelected">{{ getDisplayText(reviewPeriodList[i]) }}</mat-checkbox>
                    </ng-container>

                </ng-container>
               
            </div>
        </div>
  
        <p>
            {{ message }}
        </p>

    </div>
    <div mat-dialog-actions class="flex-h flex-end">
      <button mat-flat-button color="primary" (click)="ok()" type="submit">Ok</button>
      <button mat-flat-button [mat-dialog-close]>Mégsem</button>
    </div>
  </form>
