<div class="container padding-top-lg report-container">

    <div class="button-panel no-print">
        <a mat-button [routerLink]="['/assessments']" color="primary">
            <mat-icon>chevron_left</mat-icon>
            Vissza az értékelésekhez
        </a>
    </div>

    <app-multi-item-selector mode="ReviewPeriod" (add)="onAdd($event)" (remove)="onRemove($event)" [rpId]="reviewPeriodId" class="no-print" ></app-multi-item-selector>

    <app-report-frame 
        reportTypeName="állapotfelmérés"
        [reportOptions]="reportOptions"
        [legend]="reportData.legend"
        [showLegend]="true"
        *ngIf="reportData != null && reportData.barChartDs.datasets">
       
        <mat-card class="report-content">
                   
            <mat-card-content>
                <div class="bar-chart-panel">
                                                    
                    <div class="chart-title">
                        <h1>Stressztérkép</h1>
                    </div>
                    
                    <div class="chart bar-chart">
                        <canvas baseChart #barChart
                            [datasets]="reportData.barChartDs.datasets"
                            [labels]="reportData.barChartDs.labels"
                            [options]="reportData.barChartOptions"
                            [legend]="false"
                            [type]="'bar'">
                        </canvas>
                    </div>
                </div>
                
            </mat-card-content>
        </mat-card>

        <div class="margin-area"></div>

        <mat-card class="report-content">
            <mat-card-content>
                <ul class="legend-list">
                    <li><strong>1.0 - 2.9</strong> Valószínűleg nem jelent problémát.</li>
                    <li><strong>3.0 - 4.5</strong> Okozhat gondot a csapatnak, ajánlott a kezelése.</li>
                    <li><strong>4.6 - 6.0</strong> Negatív hatással van a csapat eredményességére, a kezelését érdemes mielőbb elkezdeni.</li>                    
                </ul>                
            </mat-card-content>
        </mat-card>

        <div class="margin-area"></div>

        <!-- freeText -->
        <mat-card class="report-content">
            <mat-card-content>
                <div *ngFor="let item of freeTextLists">
                    <app-freetext-panel [freeTexts]="item.list" [subTitle]="item.title" [showSubTitle]="freeTextLists.length > 1" class="no-margin"></app-freetext-panel>
                </div>
            </mat-card-content>
        </mat-card>

    </app-report-frame>        

</div>