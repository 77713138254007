<div class="container main-container">
    <!-- <h4 class="page-title"></h4> -->
    
    <div *ngIf="reviewPeriodsForEmployee">
        <mat-card class="card-light">
            <mat-card-header>
                <mat-card-title>Értékelések</mat-card-title>                
            </mat-card-header>
            <mat-card-content class="responsive-size">
                <table mat-table [dataSource]="reviewPeriodsForEmployee" class="table-default table-hover hover-pointer" multiTemplateDataRows>
                    
                    <ng-container matColumnDef="reviewType">
                        <th mat-header-cell *matHeaderCellDef>Típus</th>
                        <td mat-cell *matCellDef="let element">{{ element.reviewPeriod.reviewType | reviewPeriodType }}</td>
                    </ng-container>
                    
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Megnevezés</th>
                        <td mat-cell *matCellDef="let element">{{ element.reviewPeriod.name }}</td>
                    </ng-container>                      
                    
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef>Határidő</th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{'date-expired': isDateExpired(element.endDate)}">
                            {{ element.endDate | date:'yyyy.MM.dd' }}
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="reviewPeriodStatus">
                        <th mat-header-cell *matHeaderCellDef>Állapot</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element | assessmentStatus }}
                            <div class="closeDate" *ngIf="element.reviewPeriod.reviewType == reviewTypes.PersonalityTest && element.reviewPeriodEmployee?.status === assessmentStates.Closed">
                                {{ element.reviewPeriodEmployee.closedOn | date:'yyyy.MM.dd' }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button *ngIf="!hasDetails(element.reviewPeriod.reviewType) && !isClosed(element)">
                                <mat-icon title="Kitöltés">edit_note</mat-icon>
                            </button>
                            <ng-container *ngIf="element.reviewPeriodEmployee && element.reviewPeriod.reviewType === reviewTypes.PersonalityTest && isClosed(element)">

                                <a mat-icon-button [routerLink]="['/reports/personality-test/details', element.reviewPeriodEmployee?.reviewPeriodEmployeeId]" *ngIf="element.reviewPeriodEmployee?.status == assessmentStates.Closed">
                                    <mat-icon fontSet="material-icons-outlined" title="Egyéni riport">article_outline</mat-icon>
                                </a>
                                <a mat-icon-button [routerLink]="['/reports/personality-test/diagram', element.reviewPeriodEmployee?.reviewPeriodEmployeeId]" *ngIf="element.reviewPeriodEmployee?.status == assessmentStates.Closed && currentEmployee.isSziluettDiagramAllowed">
                                    <mat-icon fontSet="material-icons-outlined" title="Diagram riport">insert_chart_outline</mat-icon>
                                </a>

                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expand">
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let element">
                          <button *ngIf="hasDetails(element.reviewPeriod.reviewType)" mat-icon-button aria-label="expand row" title="Részletek">
                            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                          </button>
                        </td>
                      </ng-container>

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="masterTableColumns.length">
                            <div class="element-detail"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                
                                <div *ngIf="expandedElement && expandedElement === element">
                                    <div *ngIf="details$ | async as details">
                                        <div *ngIf="details?.length > 0" class="details-panel">

                                                <div>
                                                    <div class="table-title">Értékelendő személyek</div>                                                    
                                                </div>
                                                <table mat-table [dataSource]="details" class="table-default table-hover hover-pointer details-table">

                                                    <ng-container matColumnDef="name">
                                                        <th mat-header-cell *matHeaderCellDef>Név</th>
                                                        <td mat-cell *matCellDef="let element">{{ element | employeeName }}</td>
                                                    </ng-container>
                                    
                                                    <ng-container matColumnDef="email">
                                                        <th mat-header-cell *matHeaderCellDef>Email</th>
                                                        <td mat-cell *matCellDef="let element">{{ element | employeeEmail }}</td>
                                                    </ng-container>
                                
                                                    <ng-container matColumnDef="employeeIdentifier">
                                                        <th mat-header-cell *matHeaderCellDef>Törzsszám</th>
                                                        <td mat-cell *matCellDef="let element">{{ element | employeeIdentifier }}</td>
                                                    </ng-container>
                                    
                                                    <ng-container matColumnDef="isContractor">
                                                        <th mat-header-cell *matHeaderCellDef></th>
                                                        <td mat-cell *matCellDef="let element">{{ element | employeeType }}</td>
                                                    </ng-container>
                                
                                                    <ng-container matColumnDef="status">
                                                        <th mat-header-cell *matHeaderCellDef>Állapot</th>
                                                        <td mat-cell *matCellDef="let element">{{ element | employeeReviewState }}</td>
                                                    </ng-container>
                                
                                                    <ng-container matColumnDef="action">
                                                        <th mat-header-cell *matHeaderCellDef></th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <a mat-icon-button *ngIf="!isDetailRowInactive">
                                                                <mat-icon title="Kitöltés">edit_note</mat-icon>
                                                                <!-- <mat-icon *ngIf="isDetailRowInactive" fontSet="material-icons-outlined" title="Megjelenítés">description_outline</mat-icon> -->
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                    
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                                        [ngClass]="{'inactive': isDetailRowInactive, 'no-click': isDetailRowInactive }" (click)="onDetailRowClick(element, row)"></tr>
                                                </table>
                                                                                
                                        </div>
                                    </div>
                                </div>   

                            </div>
                            </td>
                        </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="masterTableColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: masterTableColumns;" 
                        class="element-row"                         
                        [ngClass]="{'selected': element.isSelected, 'inactive': element.reviewPeriod.status != reviewPeriodStates.Open, 'no-click': !isClickable(element), 'element-expanded-row' : expandedElement === element }"
                        (click)="onMasterRowClick(element)"
                        ></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
                </table>
    
            </mat-card-content>
        </mat-card>        
    </div>

    
    
</div>
