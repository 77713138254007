import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { StateService } from './state.service';
import { State } from '../models/state.model';
import { Observable } from 'rxjs';

export interface IRequestOptions {
    headers?: HttpHeaders | { [header: string]: string | string[] };
    observe?: 'body';
    params?: HttpParams | { [param: string]: string | string[] };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

export abstract class DataService {
    protected apiUrl: string;
    protected state: State;

    public controllerName: string;

    constructor(protected http: HttpClient, protected stateService: StateService) {
        this.state = stateService.state;
        this.apiUrl = this.state.config.rest_end_point;
    }

    protected get<T>(path: string, options?: IRequestOptions): Observable<T> {        
        return this.http.get<T>(this.getUrl(path), options);
    }

    protected post<T>(path: string, body: any, options?: IRequestOptions): Observable<T> {
        return this.http.post<T>(this.getUrl(path), body, options);
    }

    protected put<T>(path: string, body: any, options?: IRequestOptions): Observable<T> {
        return this.http.put<T>(this.getUrl(path), body, options);
    }

    protected delete<T>(path: string, options?: IRequestOptions): Observable<T> {
        return this.http.delete<T>(this.getUrl(path), options);
    }

    private getUrl(path: string): string {
        const url = this.apiUrl + path;
        return url;
    }    
}
