import { Component, Input, OnInit } from '@angular/core';
import { ChartLegendItem, PersonLegend } from 'src/app/models/report/person-legend.model';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  @Input() 
  public data: PersonLegend[] | ChartLegendItem[] = [];

  @Input()
  public showIcon = true;

  public isChartLegend = false;

  constructor() { }

  ngOnInit(): void {
    this.isChartLegend = this.checkIsChartLegend();
  }

  public checkIsChartLegend(): boolean {
    if (!this.data || !Array.isArray(this.data))
      return false;

    if (this.data.length == 0) return false;
    
    for (let i = 0; i < this.data.length; i++) {
      if (!this.instanceOfChartLegend(this.data[i]))
        return false;
    }
    
    return true;
  }

  public instanceOfChartLegend(data: any): boolean {
    return "mainLine" in data;
  }

}
