import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { withLatestFrom } from 'rxjs';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { AuthService } from 'src/app/services';
import { ErrorMessageService } from 'src/app/shared/services';

@UntilDestroy()
@Component({
  selector: 'app-cancel-user-delete',
  templateUrl: './cancel-user-delete.component.html',
  styleUrls: ['./cancel-user-delete.component.scss']
})
export class CancelUserDeleteComponent implements OnInit {
  
  public result = false;
  public msg = '';
  
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private msgService: ErrorMessageService,
    ) { }

  ngOnInit(): void {
   
    this.route.params.pipe(
      withLatestFrom(this.route.queryParamMap),
      untilDestroyed(this)
    ).subscribe( ([url, queryParamMap]) => {      
      let token = queryParamMap['params'].token;
      
      if (!token)
      {
        this.msg = this.msgService.getErrorText('noToken');
        this.result = true;
        return;
      }

      this.cancelDelete(token);
    });
  }

  public cancelDelete(token: string): void {
    this.authService.cancelDeleteEmployeeWithToken(token).subscribe({next: () => {
      this.result = true;
    }, error: (err: IResultModel<ResultModel>) => {
      this.msg = this.msgService.getError<ResultModel>(err);
      this.result = true;
    }});
  }

}
