import { ChartDataset } from "chart.js";
import { ChartDataSource } from "./chart-datasource.model";

export class RadarChartDataSource implements ChartDataSource {
    datasets?: ChartDataset<'radar', number[]>[] = [];
    labels?: string[] = [];
    columnCodes?: string[] = [];

    constructor() {
        this.datasets = [];
        this.labels = [];
        this.columnCodes = [];
    }
}