import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config.model';
import { State } from '../models/state.model';

@Injectable({
    providedIn: 'root',    
})
export class StateService {
    public state: State;
      
    constructor() {
        this.state = new State();        
        this.state.config = environment.config as Config;
    }  

    updateMyName(name: string) {
        let user = {...this.state.me.value};
        user.employee.name = name;

        this.state.me.value = user;
    }   
}
