import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { State } from '../../models/state.model';
import { StateService } from '../../services/state.service';
import { AppState } from 'src/app/store/app.state';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    
    @Input()
    public isNav = false;
    
    @Input()
    public title: string;

    public state: State;
    
    public isMenuOpen = false;

    public get isTestMode(): boolean {
        return this.appState?.company.value?.config?.testMode;
    }
 
    constructor(stateService: StateService, private router: Router, private appState: AppState) {
        this.state = stateService.state;
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }

    menuItemClicked() {
        this.isMenuOpen = false;
    }
}
