import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActionStateModel } from '../../models/action-state.model';
import { Message, MessageType } from '../../models';
import { ErrorMessageService } from '../../services';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public lightCard = true;           //mat-card class: card-light
  
  @Input() public showEdit = true;            // show pen icon in the top right corner
  @Input() public showDelete = false;            // show delete icon in the top right corner
  @Input() public showStatus = false;

  @Input() public useExternalButtons = false;   // when this is true, the footer with the build-in action buttons will not be visible in edit mode
  @Input() formGroup: UntypedFormGroup;
  @Input() public actionState = new ActionStateModel();
  @Input() public editDisabled = false;
  @Input() public customTitle = false;
  @Input() public noContentBottomMargin: false;
  
  @Input() @HostBinding('class.disabled') public disabled = false;
  
  @Output() public ok = new EventEmitter();
  @Output() public cancel = new EventEmitter();
  @Output() public delete = new EventEmitter();
  @Output() public editToggle = new EventEmitter<boolean>();
  
  public statusMsg: Message;
    
  public messageTypes = MessageType;

  //public editMode = false;
  public get formMode(): boolean {
    return Object.keys(this.formGroup.controls).length > 0;
  }
  
  constructor(private fb: UntypedFormBuilder, private msgService: ErrorMessageService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    if (!this.formGroup)
    {
      this.formGroup = this.fb.group({});
    }
  }
  
  onToggleEdit() {
    this.actionState.editMode = !this.actionState.editMode;
    this.editToggle.emit(this.actionState.editMode);
  }

  onOk() {
    if (!this.formGroup.invalid) {
      this.ok.emit();
    }
  }

  onCancel() {
    this.actionState.editMode = false;
    this.cancel.emit();
  }

  onDelete() {
    this.delete.emit();
  }

  public displayStatus(msg: Message){
    this.statusMsg = msg;

    if (msg.duration) {
      setTimeout(() => {
        this.clearStatus();
      }, msg.duration);
    }
  
  }

  public clearStatus()
  {
    this.statusMsg = null;
  }

  public displaySavedStatus() {
    const msg = this.msgService.getErrorMessage('saved');
    this.displayStatus(msg);
  }

}
