<app-card #card [title]="title" [useExternalButtons]="true" [disabled]="!appState.company.value" [showEdit]="false">

    <div slot="readonly">
        <ng-container *ngIf="list$ | async as list">
            <ng-container>
                <div class="flex-horizontal align-items-center top-margin-off">
                    <mat-form-field appearance="standard" class="flex-fill">
                        <mat-select [(value)]="reviewPeriod" disableOptionCentering class="drop-fix select-multi-line" [readonly]="true" (selectionChange)="changePeriod($event)">                            
                            <mat-option *ngFor="let rp of list" [value]="rp">
                                {{ getDisplayText(rp) }}
                            </mat-option>                            
                        </mat-select>
                    </mat-form-field>
                    
                    <ng-container *ngIf="configMode">
                        <ng-container *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
                            <button mat-icon-button color="primary" (click)="add()">
                                <mat-icon>add_circle_outline</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="edit()" [disabled]="!selectedReviewPeriod.value">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="delete(selectedReviewPeriod.value)" [disabled]="selectedReviewPeriod.value == null" title="Értékelés törlése">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
        
                <ng-container *ngxPermissionsOnly="['SuperAdmin', 'Admin']">
                    <div *ngIf="configMode && selectedReviewPeriod.value && reviewPeriod" class="status">
                        <mat-form-field appearance="standard" class="flex-fill">
                            <mat-label>Állapot</mat-label>
                            <mat-select [(value)]="reviewPeriod.status" disableOptionCentering class="drop-fix" (selectionChange)="changeStatus($event)">
                                <mat-option *ngFor="let status of statusList" [value]="status.id">{{ status.name }}</mat-option>
                            </mat-select>                            
                        </mat-form-field>
                    </div>
                </ng-container>

            </ng-container>
        </ng-container>
    </div>

    <div slot="edit">
        <app-review-period-edit #editComponent [selectedReviewPeriod]="selectedReviewPeriod.value" (closeEdit)="closeEdit()" (saved)="dataSaved($event)"></app-review-period-edit>
    </div>
</app-card>
