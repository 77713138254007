import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeCreateDto } from 'src/app/models/employee-create.model';
import { Result } from 'src/app/models/general/result.model';
import { Position } from 'src/app/models/position.model';
import { Search } from 'src/app/models/search.model';
import { AuthService } from 'src/app/services/auth.service';
import { PositionService } from 'src/app/services/position.service';
import { CustomValidators } from 'src/app/shared/helpers/custom-validators';
import { ActionStateModel } from 'src/app/shared/models/action-state.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { EmployeeState } from 'src/app/store/employee.state';

@Component({
  selector: 'app-employee-add',
  templateUrl: './employee-add.component.html',
  styleUrls: ['./employee-add.component.scss']
})
export class EmployeeAddComponent implements OnInit {

  public actionState: ActionStateModel = {editMode: true, currentItemIndex: null};
  public mainForm: UntypedFormGroup;
  public positions$: Observable<Position[]>;

  constructor(
    private authService: AuthService,
    private employeeStore: EmployeeState,
    private positionService: PositionService,
    private fb: UntypedFormBuilder, 
    private msgService: ErrorMessageService,
    private router: Router) { }

  ngOnInit(): void {
    this.positions$ = this.positionService.getList({search: ''} as Search).pipe(
      map(result => result.list )
    );
    this.mainForm = this.createForm();
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({      
      'name': [this.employeeStore.personNameToAdd],               // todo: legyen konfigurálható hogy null engedélyezett-e
      'email': [null, [Validators.email, Validators.required]],
      'employeeIdentifier': [null],                               // todo: legyen konfigurálható hogy null engedélyezett-e
      'positionId': [null],                // todo: legyen konfigurálható hogy null engedélyezett-e
      'password': [null],
      'passwordConfirm': [null],
    }, {validator: CustomValidators.matchValues('password', 'passwordConfirm') })
  }

  public onSave() {
    const data = this.mainForm.value as EmployeeCreateDto;
    this.authService.createUser(data).subscribe({next: (result) => {
      const newEmployeeId = result.data;
      this.employeeStore.personNameToAdd = "";
      this.actionState.editMode = false;      
      this.router.navigate([`/employee/${newEmployeeId}`]);
      
      this.msgService.showSnackBarMessage('saveSuccessful');
    }, error: (err) => {
      this.msgService.handleErrorWithData<string, Result<string>>(err);      
    }});
  }

  public onCancel() {
    this.employeeStore.personNameToAdd = "";
    this.router.navigate(['/employee']);
  }

}
