<div class="container main-container">
    <!-- <h4 class="page-title"></h4> -->
    
    <div>
        <mat-card class="card-light">
            <mat-card-header>
                <mat-card-title>Cégek listája</mat-card-title>
            </mat-card-header>
            <mat-card-content class="responsive-size">

                <!-- <mat-list role="list" *ngIf="companies$ | async as items">
                    <mat-list-item role="listitem" *ngFor="let company of items">{{ company.name }}</mat-list-item>
                  </mat-list> -->

                  <table mat-table [dataSource]="companies" class="table-default table-hover hover-pointer" multiTemplateDataRows>
                                                            
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Megnevezés</th>
                        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                    </ng-container>                      
                    
                    <ng-container matColumnDef="employeeCount">
                        <th mat-header-cell *matHeaderCellDef>Dolgozók</th>
                        <td mat-cell *matCellDef="let element">{{ showEmployeeCount(element.employeeCount) }}</td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="masterTableColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: masterTableColumns;" 
                        class="element-row"                         
                        [ngClass]="{'selected': element.isSelected }"
                        (click)="onMasterRowClick(element)"
                        ></tr>
                </table>
    
            </mat-card-content>
        </mat-card>

        <div class="action-buttons mobile-page-bottom" *ngIf="showAdminFunctions">
            <button mat-flat-button color="primary" (click)="createCompany()">
                <mat-icon>add</mat-icon>
                Cég
            </button>
        </div>

        
    </div>

    
    
</div>

