import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionStateModel } from 'src/app/shared/models/action-state.model';

@Component({
  selector: 'app-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.scss']
})
export class CompanyPageComponent implements OnInit {

  public createCompanyState: ActionStateModel;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.createCompanyState = {editMode: true, currentItemIndex: null};
  }

  created() {
    this.router.navigate(['/company-selector']);
  }

  cancel() {
    this.router.navigate(['/company-selector']);
  }
}
