import { Roles } from "../enums/roles.enum";
import { Employee } from "../models/employee.model";
import { KeyText } from "../models/general/key-text.model";

export abstract class EmployeeHelper {
    
    public static roleList: KeyText<Roles>[] = [
        { key: Roles.SuperAdmin, text: 'Szuper Adminisztrátor'},
        { key: Roles.Admin, text: 'Adminisztrátor'},
        { key: Roles.Assistant, text: 'HR Adminisztrátor'},
        { key: Roles.User, text: 'Felhasználó'}
      ];

    public static getDisplayName(employee: Employee, separator = ' '): string {
        const text = !employee.employeeIdentifier ? employee.email : employee.employeeIdentifier;
        return `${employee.name}${separator}${text}`;
    }

    public static getRoleDisplayName(role: Roles): string {
        return this.roleList.find(x => x.key === role)?.text;
    }
}