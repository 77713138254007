<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>

    <div class="msg">
        {{ showMainMessage() }}
    </div>

    <div class="details">                
            <div class="details-content-item" *ngFor="let key of errorKeys">
                <div class="detail-message">{{ getDetailMessage(key) }}</div>

                <ul *ngIf="data.employeeErrorList[key] && data.employeeErrorList[key].length > 0" class="employee-list">
                    <li *ngFor="let employee of data.employeeErrorList[key]" class="employee-item">
                        {{ employee.name}} ({{ employee.email }})
                    </li>
                </ul>
            </div>
    </div>
</div>
<div mat-dialog-actions class="flex-h flex-end">
  <button mat-flat-button color="primary" (click)="ok()" type="submit">Ok</button>
</div>
