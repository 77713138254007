import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Company } from 'src/app/models/company.model';
import { CompanyService } from 'src/app/services';
import { CardComponent } from 'src/app/shared/components';
import { ActionStateModel } from 'src/app/shared/models/action-state.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  public mainForm: UntypedFormGroup;

  public get initValue(): string {
    return this.type === 'edit' ? this.appState.company.value?.name : '';
  }

  @Input() public type: 'edit' | 'create' = 'edit';
  @Input() public showEdit = true;
  @Input() public actionState = new ActionStateModel();
  
  @Output() public ok = new EventEmitter();
  @Output() public cancel = new EventEmitter();
  
  constructor(private fb: UntypedFormBuilder, public appState: AppState, private companyService: CompanyService, private msgService: ErrorMessageService) { }

  @ViewChild('card')
  card: CardComponent;

  ngOnInit(): void {
    this.mainForm = this.createForm();

    if (this.type === 'edit') {
      this.appState.company.obs$.subscribe(data => {
        this.mainForm.patchValue(data);
      });
    }
  }

  createForm(): UntypedFormGroup {
    return this.fb.group({
      'name': ['', [Validators.required]],
    });
  }

  save() {    
    let formValue = this.mainForm.value;

    //if update
    if (this.type === 'edit' && this.appState.company.value)
    {
      let model = { ...this.appState.company.value, name: formValue.name } as Company;

      this.companyService.update(model).subscribe(data => {        
        this.editComplete(model, 'A cég módosítva.');
        this.ok.emit();
      }, error => {
        this.msgService.showErrorResponse(error);
      });
    }
    else
    {
      let model = { name: formValue.name } as Company;

      this.companyService.add(model).subscribe(data => {
        this.editComplete(data, 'A cég sikeresen létrehozva.');
        this.ok.emit();
      }, error => {
        this.msgService.showErrorResponse(error);
      });
    }
        
  }

  cancelClick() {    
    this.cancel.emit();
  }

  private editComplete(data: Company, msg: string) {
    if (data.id === this.appState.company.value?.id)
      this.appState.company.value = data;
      
    this.card.actionState.editMode = false;
    this.msgService.showSnackBarInfo(msg);
  }
}
