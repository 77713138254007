import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogData } from 'src/app/models/dialog/error-dialog.model';
import { ErrorMessageService } from 'src/app/shared/services';

@Component({
  selector: 'app-error-details-dialog',
  templateUrl: './error-details-dialog.component.html',
  styleUrls: ['./error-details-dialog.component.scss']
})
export class ErrorDetailsDialogComponent implements OnInit {

  public title: string;
  public errorKeys: string[];

  constructor(    
    public dialogRef: MatDialogRef<ErrorDetailsDialogComponent>,
    public msgService: ErrorMessageService,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;   
    this.errorKeys = Object.keys(this.data.employeeErrorList);    
  }

  public showMainMessage() {
    const error = this.data.errorData;
    let message = '';

    if (error.key)
    {
      const msg = this.msgService.getErrorMessage(error.key);
      message = msg.text;
    }
    else
    {
      message = error.msg;
    }

    return message;
  }

  public getDetailMessage(key: string): string {
    const msg = this.msgService.getErrorMessage(key);
    return msg.text;
  }

  public showDetail(data: any)
  {
    return '';
  }
  
  public ok() {
    this.dialogRef.close(true);
  }

}
