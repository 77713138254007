<mat-card>
    <mat-card-content>
        <div class="flex-h edit-row" *ngIf="groupGoal">
            <div class="flex1">
                <mat-form-field appearance="fill" class="width-max hidden-print">
                    <mat-label>Csapatcél</mat-label>
                    <textarea matInput 
                        [disabled]="!data.isSelf" rows="3" 
                        name="groupGoalName" 
                        [(ngModel)]="goalText" title="{{ goalText }}" autocomplete="off" (change)="groupGoalChanged($event)" required
                    ></textarea>
                </mat-form-field>
                <p class="form-control visible-print">{{ goalText }}</p>
            </div>
            
            <div class="flex1 parent-group-goal-panel">
                <app-group-goal-field             
                    [data]="data" 
                    [groupGoal]="groupGoal.parentGroupGoal"
                    (change)="parentGroupGoalChanged($event)"
                ></app-group-goal-field>
                
                <!-- floating button -->
                <button mat-icon-button color="primary" class="goal-delete hidden-print icon-button" *ngIf="data.isSelf" [disabled]="data.loading" (click)="deleteGroupGoal()" title="Csapatcél törlése">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
