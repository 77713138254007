import { GoalText, GoalTextModel } from "./goaltext.model";

export interface GroupGoal {
    id: number;
    employeeId: number;
    reviewPeriodId: number;
    goalTextId: number;
    parentGroupGoalId?: number;

    //clientdata
    employeeName: string;
    goalText: GoalText;
    parentGroupGoal: GroupGoal;
}

export class GroupGoalModel {
    id = 0;
    employeeId = 0;
    reviewPeriodId = 0;
    goalTextId = 0;
    parentGroupGoalId = null;

    //clientdata
    employeeName = '';
    goalText = new GoalTextModel();
    parentGroupGoal: GroupGoal = null;         // for tooltip
}