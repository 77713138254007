import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FileService {

    constructor(private http: HttpClient) {
    }

    public downloadFile(url: string)  {
        return this.http.get(url, { responseType: 'blob', observe: 'response' });
    };

    public saveAs(filename: string, data: Blob) {
        const objectUrl = URL.createObjectURL(data);

        const a = document.createElement('a');
        a.href = objectUrl;
        a.download = filename;
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        a.click();
        URL.revokeObjectURL(objectUrl);
    }
}