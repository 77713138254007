<div class="container padding-top-lg report-container">

    <app-back-nav linkText="Vissza az értékelésekhez"></app-back-nav>
    
    <app-report-frame 
        class="header-ratio-2-1"
        reportTypeName="egyéni riport"        
        [reportOptions]="reportOptions"
        [legend]="legend" 
        [showLegend]="true"
        [showLegendIcon]="false"
        [isNoBackground]="true"
        *ngIf="data$ | async as data">
    
        <div class="content">
            <div *ngFor="let category of data; index as i">                
                <div class="category-title break-after-avoid">{{ category.subtitle ? category.subtitle : '' }} <span>{{ category.title }}</span></div>
                <div *ngFor="let item of category.items; index as j" [ngClass]="{'separator': !item.questionCategory }">
                    <div class="report-row break-inside-avoid" *ngIf="item.questionCategory">
                        <div class="row-title">{{ item.questionCategory.name }}</div>
                        <div class="col-separator">
                            <div class="separator-block"></div>
                        </div>
                        <div class="row-text">
                            <div class="value-line" *ngIf="!category.rowResultBottom" [innerHtml]="item.resultText"></div>
                            {{ item.questionCategory.description }}
                            <div class="value-line" *ngIf="category.rowResultBottom" [innerHtml]="item.resultText"></div>
                        </div>
                    </div>
                </div>
            </div>
                        
        </div>
    
    </app-report-frame>

</div>
