import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { StateService } from 'src/app/services';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  public loading = false;

  private timerSubscription$: Subscription;

  constructor(stateService: StateService) {
    stateService.state.isLoading.obs$.subscribe(loadingState => {      

      if (this.timerSubscription$)
        this.timerSubscription$.unsubscribe();

      if (loadingState)
      {        
        this.timerSubscription$ = timer(200).subscribe(data => {
          this.loading = true;
        });
      }
      else
      {
        this.loading = false;
      }

    });

  }
  
}
