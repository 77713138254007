<mat-form-field>
    <input
      matInput
      type="text"
      [matAutocomplete]="auto"
      matAutocompletePosition="below"
      [formControl]="inputControl"
      [placeholder]="placeholder"
      required
      (blur)="onTouched()"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-error *ngIf="!inputControl.valid && inputControl.errors?.required">
      This field is required
    </mat-error>
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption="true"
    [displayWith]="displayFn"
  >
    <mat-option *ngIf="isSearching; else optionsTemplate" disabled="true">
      <em>Searching...</em>
    </mat-option>
  
    <ng-template #optionsTemplate>
      <mat-option
        *ngFor="let option of options"
        [value]="option"
        class="provided"
      >
        {{ option.label }}
      </mat-option>
    </ng-template>
  
    <mat-option *ngIf="!isSearching && noResults" value="" disabled="true">
      <b>No results found</b>
    </mat-option>
  </mat-autocomplete>