import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jsSHA from 'jssha';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { JwtHelperService } from '../jwt/jwthelper.service';
import { Token } from '../models/token.model';
import { EmployeeCreateDto } from '../models/employee-create.model';
import { UserProfile } from '../models/user-profile.model';
import { TokenService } from '../shared/services/token.service';
import { DataService } from './data.service';
import { StateService } from './state.service';
import { IResult, Result, ResultModel } from '../models/general/result.model';
import { PagedListResult } from '../models/paged-list-result.model';
import { AppState } from '../store/app.state';
import { IChangeMyPassword, IChangePasswordForUser, IResetPassword } from '../models/change-password.model';
import { MassMailOptions, SingleMailOptions } from '../models/mail-options.model';
import { Search } from '../models/search.model';
import { IChangeContactDetails } from '../models/change-contact-details.model';
import { SettingsState } from '../store/settings.state';
import { LoginResult } from '../models/other/login-result.model';
import { Roles } from '../enums/roles.enum';
import { EmployeeDeleteDto } from '../models/employee-delete.model';
import { GroupOperationOption } from '../models/other/group-operation-option.model';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends DataService {
    
    constructor(
        http: HttpClient, 
        stateService: StateService,
        private appState: AppState,
        private settingState: SettingsState,
        private tokenService: TokenService,
        public jwtHelper: JwtHelperService, 
        private permissionsService: NgxPermissionsService
    ) 
    {
        super(http, stateService);
        this.state.me.value = null;
    }

    public loadUser(): Observable<UserProfile> {
        this.state.isLoggedIn = this.isAuthenticated();
        return this.state.isLoggedIn ? this.getMe() : of();
    }

    public hasRole(user: UserProfile, role: Roles): boolean {
        return user.role === role;
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('access_token');
        // Check whether the token is expired and return
        // true or false
        const isExpired = this.jwtHelper.isTokenExpired(token);
        return !isExpired;
      }

    public hash(text: string):string {
        const shaObj = new jsSHA ('SHA-256', 'TEXT');
        shaObj.update (text);
        return shaObj.getHash ('HEX');
    }

    public logIn(loginData): Observable<UserProfile> {
        const passhash = this.hash(loginData.password);
        return this.post<LoginResult>('/api/accounts/login', {email: loginData.username, password: passhash})
            .pipe(                 
                mergeMap(response => { 
                    this.tokenService.saveToken(response.token);
                    this.state.isLoggedIn = true;

                    return forkJoin([                        
                        this.getMe().pipe(
                            catchError(error => of(null))
                        ),
                        this.appState.loadCompanyInfo().pipe(
                            catchError(error => of(null))
                        )
                    ]).pipe(
                            map(([userProfile, companyInfo]) => {
                                return userProfile;
                            })
                        );
                })
            );
    };
    
    public changeCompany(companyId: number): Observable<UserProfile> {
        return this.post<Token>('/api/accounts/change-company', companyId)
            .pipe(                 
                mergeMap(response => { 
                    this.tokenService.saveToken(response.access_token);

                    return forkJoin([
                        this.getMe().pipe(
                            catchError(error => of(null))
                        ),
                        this.appState.loadCompanyInfo().pipe(
                            catchError(error => of(null))
                        )
                    ]).pipe(
                            map(([userProfile, companyInfo]) => {
                                return userProfile;
                            }),
                            tap(() =>{
                                this.settingState.reviewPeriod.value = null;
                            })
                        );
                })
            );
    };


    public logOut() {
        this.tokenService.removeToken();
        this.state.me.value = null;
        this.state.isLoggedIn = false;
    };

    public getMe(): Observable<UserProfile> {        
        console.log('Get User');
        return this.get<UserProfile>('/api/accounts/me').pipe(
            map(result => {                                           
                this.state.me.value = result;
                this.permissionsService.loadPermissions([this.state?.me.value?.role]);
                return result;
            })
        );
    }
    
    public changeMyPassword(data: IChangeMyPassword): Observable<void> {
        data.oldPassword = this.hash(data.oldPassword);
        data.newPassword = this.hash(data.newPassword);
        
        return this.post('/api/accounts/change-my-password', data);            
    };

    public changePasswordForUser(data: IChangePasswordForUser): Observable<void> {
        data.newPassword = this.hash(data.newPassword);
        
        return this.post('/api/accounts/change-password', data);            
    };

    public changeContactDetailsForUser(data: IChangeContactDetails): Observable<ResultModel> {
        return this.post<ResultModel>('/api/accounts/change-contact-details', data);
    };

    public resetPassword(data: IResetPassword): Observable<void> {
        data.password = this.hash(data.password);
        
        return this.post('/api/accounts/reset-password', data);
    };

    public forgottenPassword(email: string): Observable<void> {
        return this.post('/api/accounts/forgotten-password', { email } );
    }
  
    public sendRegistrationMessages(options: MassMailOptions): Observable<void> {
        return this.post('/api/accounts/registration-messages', options );
    }

    getUsers = (options?: Search): Observable<PagedListResult<UserProfile>> => {
        return this.post<PagedListResult<UserProfile>>('/api/accounts/getusers', options, {headers: {'X-Skip-Interceptor':'1'}});
    }
  
    public getEmployee(id: string): Observable<UserProfile> {
        return this.get<UserProfile>(`/api/accounts/employee/${id}`)
    }
    
    public createUser(model: EmployeeCreateDto): Observable<IResult<string, Result<string>>> { 
        if (model.password) {
            model.password = this.hash(model.password);
        }
        
        return this.put<IResult<string, Result<string>>>('/api/accounts/create', model);
    }

    public deleteEmployee(model: EmployeeDeleteDto): Observable<ResultModel> {
        return this.post('/api/accounts/delete', model);
    }

    public deleteEmployeeInBatch(model: GroupOperationOption): Observable<Result<number[]>> {
        return this.post('/api/accounts/delete-batch', model);
    }

    public cancelDeleteEmployee(employeeId: number): Observable<void> {
        return this.get('/api/accounts/cancel-delete/' + employeeId);
    }

    public cancelDeleteEmployeeWithToken(token: string): Observable<ResultModel> {
        return this.get('/api/accounts/cancel-delete-with-token/' + token);
    }
    
    public createRoleUser(companyName: string, name: string, email: string, role): Observable<void> {        
        return this.post('/api/accounts/roleUsers', { companyName, name, email, role });
    }

    public confirmEmail(userId: number, token: string): Observable<void> {        
        return this.post('/api/accounts/confirmEmail', { userId, token });
    }

    public sendSingleEmail(options: SingleMailOptions): Observable<void> {
        return this.post('/api/accounts/singleEmail', options);
    }
       
}
