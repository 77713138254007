import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { withLatestFrom } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  token = '';
  email = '';
  isReg = false;

  ngOnInit(): void {
    this.route.url.pipe(
      withLatestFrom(this.route.paramMap, this.route.queryParamMap),
      untilDestroyed(this)
    ).subscribe(([url, paramMap, queryParamMap]) => {
      if (paramMap['params'].email && queryParamMap['params'].token)
      {
          this.email = paramMap['params'].email;
          this.token = queryParamMap['params'].token;

          if (queryParamMap['params'].reg && queryParamMap['params'].reg === "1")    // ha regisztrációs mailből jött.
            this.isReg = true;
      }
  });
  }

}
