import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService, StateService } from ".";

@Injectable({
    providedIn: 'root',
})
export class SystemService extends DataService {
        
    constructor(http: HttpClient, stateService: StateService) {
        super(http, stateService);

        this.controllerName = "system";
    }

    public purgeCache(): Observable<void> {
        return this.get(`/api/${this.controllerName}/purge-cache`);
    }
}