import { ComponentState } from "../enum/component-state.enum";

export class ListItem<TItem> {
    public itemSource: TItem;

    public state = ComponentState.View;
    public cancelAction = false;
    
    constructor(data: TItem) {
        this.itemSource = data;
    }
}