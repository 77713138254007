import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'form-page',
  templateUrl: './form-page.component.html'
})
export class FormPageComponent implements OnInit {

  @Input() pageTitle: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() isVisibleActionsBar = true; 
  @Input() isVisibleOkButton = true;
  @Input() isVisibleSaveButton = true;
  @Input() isVisibleCustomButtons = false;
  @Input() cancelTitle = "Mégsem";

  @Output() onSave = new EventEmitter();
  @Output() onOk = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  public messages: string[] = [];

  ngOnInit() {
    this.formGroup.valueChanges.subscribe((v) => {
      this.clearMessages();
    });
  }
  
  save(event) {
    this.onSave.emit(event);
  }

  ok(event) {
    this.onOk.emit(event);
  }

  cancel(event) {
    this.onCancel.emit(event);
  }

  addMessage(key: string) {
    if (!this.messages.includes(key))
      this.messages.push(key);
  }

  clearMessages() {
    this.messages = [];
  }

}
