import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GoalsHistory } from "../models/goals-history.model";
import { GroupGoal } from "../models/group-goal.model";
import { DataService } from "./data.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root',
})
export class GroupGoalService extends DataService {
    constructor(http: HttpClient, stateService: StateService) {
        super(http, stateService);
    }

    getGroupGoals(reviewPeriodId: number, employeeId: number): Observable<GroupGoal[]>  {
        return this.get(`/api/group-goals/${reviewPeriodId}/${employeeId}`);
    }

    addGroupGoal(groupGoal: GroupGoal): Observable<GroupGoal> {
        return this.put(`/api/group-goals/add`, groupGoal);
    }
    
    updateGroupGoal(groupGoal: GroupGoal): Observable<any> {
        return this.post('/api/group-goals/update', groupGoal);
    }

    deleteGroupGoal(id: number): Observable<any> {
        return this.delete('/api/group-goals/delete/' + id);
    }

    getLeaderGroupGoals(employeeId: number, reviewPeriodId: number): Observable<GroupGoal[]> {
        return this.get(`/api/group-goals/leader-group-goals/${reviewPeriodId}/${employeeId}`);
    }

    getHistory(reviewPeriodId: number, employeeId: number): Observable<GoalsHistory>  {
        return this.get(`/api/group-goals/history/${reviewPeriodId}/${employeeId}`);
    }
}