<app-header *ngIf="showHeader" [isNav]="true" class="no-print"></app-header>
<div class="content-wrap" [ngClass]="{'login': isLoginPage}">
    <router-outlet></router-outlet>
</div>

<app-loader></app-loader>

<footer class="no-print">
    <div class="footer-container">
        <div class="footer-panels flex-h">
            <div class="footer-panel flex-fill">
                <span>© {{year}}</span>&nbsp;<a href="https://quantille.hu" _target="_blank">Quantille</a> <span class="inline">&nbsp;•&nbsp;</span><span>Minden jog fenntartva!</span>
            </div>
            <div class="footer-panel flex-fill flex-h flex-end">
                <div class="footer-menu">
                    <ul class="flex-h gap-10">
                        <li><a href="https://quantille.hu/impresszum/" _target="_blank">Impresszum</a></li>
                        <li><a rel="privacy-policy" href="/assets/adatkezelesi-tajekoztato.pdf" target="_blank">Adatkezelési tájékoztató</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>  
</footer>
