import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KpiAchieved } from '../models/kpi-achieved.model';
import { Kpi, KpiModel } from '../models/kpi.model';
import { KpiType } from '../models/kpiType.model';
import { PagedListResult } from '../models/paged-list-result.model';
import { Search } from '../models/search.model';
import { ApiHelper } from '../shared/helpers/api.helper';
import { DefaultDataService } from './default-data.service';
import { StateService } from './state.service';
import { Result, ResultModel } from '../models/general/result.model';

@Injectable({
  providedIn: 'root'
})
export class KpiService extends DefaultDataService<Kpi> {
    
  constructor(
      http: HttpClient, 
      stateService: StateService,      
  ) 
  {
      super(http, stateService);
      this.controllerName = "kpi";
  }
  
  getTypeList = (options?: Search): Observable<PagedListResult<KpiType>> => {
    return this.post<PagedListResult<KpiType>>(`/api/${this.controllerName}/typelist`, options, {headers: {'X-Skip-Interceptor':'1'}} );
  }

  public getList(reviewPeriodId: number, aboutEmployeeId: number): Observable<Kpi[]> {
    return this.post<Kpi[]>(`/api/${this.controllerName}/list`, {reviewPeriodId: reviewPeriodId, aboutEmployeeId: aboutEmployeeId}, {headers: {'X-Skip-Interceptor':'1'}} );
  }

  public addData(model: Kpi): Observable<Result<KpiModel>> {
    return this.addItem(model, ApiHelper.addTargetEmployeeId(model.aboutEmployeeId));
  }

  public updateData(model: Kpi): Observable<Result<KpiModel>> {
    return this.updateItem(model, ApiHelper.addTargetEmployeeId(model.aboutEmployeeId));
  }

  public updateAchieved(model: KpiAchieved, targetUserId: string): Observable<ResultModel> {
    return this.post(`/api/${this.controllerName}/update-achieved`, model, ApiHelper.addTargetUserId(targetUserId));
  }

  public deleteData(model: Kpi): Observable<ResultModel> {
    return this.deleteItem(model, ApiHelper.addTargetEmployeeId(model.aboutEmployeeId));
  }
  
  public addKpiType(model: KpiType): Observable<Result<KpiType>> {
    return this.put(`/api/${this.controllerName}/add-kpi-type`, model);
  }

}
