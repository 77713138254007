<form [formGroup]="mainForm">
  <h1 mat-dialog-title>Új csoport létrehozása</h1>
  <div mat-dialog-content>

    <mat-form-field appearance="standard">
      <mat-label>Megnevezés</mat-label>
      <input matInput formControlName="name" cdkFocusInitial>
    </mat-form-field>

  </div>
  <div mat-dialog-actions class="flex-h flex-end">
    <button mat-flat-button color="primary" (click)="ok()" type="submit">Ok</button>
    <button mat-flat-button [mat-dialog-close]>Mégsem</button>
  </div>
</form>
