import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AssessmentStatusEnum } from 'src/app/enums/assessment-status.enum';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { EmployeeForAssessments } from 'src/app/models/employee-for-assessments.model';
import { ReviewPeriodForEmployee } from 'src/app/models/review-period-for-employee.model';
import { ReviewPeriod } from 'src/app/models/review-period.model';
import { TextGuide } from 'src/app/models/text-guide.model';
import { TextGuideService } from 'src/app/services/text-guide.service';
import { State } from '../../models/state.model';
import { ReportService, StateService } from '../../services';

export interface ReportData {
    employees: any[],
    reviewPeriods: any[]
}

@Component({
    selector: 'reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({height: '0px', minHeight: '0'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ]
})
export class ReportsComponent implements OnInit { 
    public state: State;
    
    public reportList: ReviewPeriodForEmployee[];
    public masterTableColumns = ['reviewType', 'name', 'period', 'action', 'expand'];

    public employeeReports$: Observable<EmployeeForAssessments[]>;
    public displayedColumns = ['name', 'email', 'employeeIdentifier', 'isContractor', 'action'];
    
    public expandedElement: ReviewPeriodForEmployee;

    public textGuide: TextGuide;

    public reviewTypes = ReviewTypeEnum;
    public assessmentStates = AssessmentStatusEnum;

    public reviewTypeDisplayName(model: ReviewPeriod): string {
        return ReviewPeriodHelper.getDisplayText(model);
    }  
        
    constructor(private reportService: ReportService, stateService: StateService, private textGuideService: TextGuideService, private router: Router) {
        this.state = stateService.state;
    }

    ngOnInit() {
        this.reportService.getReportsForReviewPeriod().subscribe(result => {
            this.reportList = result;

            this.textGuide = this.textGuideService.getGuide("reports:TER");
        });
    }

    hasDetails(reviewType: ReviewTypeEnum): boolean {
        return reviewType == ReviewTypeEnum.TER;
    }

    isClickable(item: ReviewPeriodForEmployee): boolean {
        return this.hasDetails(item.reviewPeriod.reviewType);
    }

    loadDetails(reportItem: ReviewPeriodForEmployee) {
        this.employeeReports$ = this.reportService.getReportsForEmployees(reportItem.reviewPeriod.id);
    }

    onMasterRowClick(reportItem: ReviewPeriodForEmployee) {
        if (this.hasDetails(reportItem.reviewPeriod.reviewType))
        {
            this.expandedElement = this.expandedElement === reportItem ? null : reportItem;
            this.loadDetails(reportItem);
        }
    }

}
