<mat-card>
    <mat-card-content>
        <div class="form-row flex-h">
            <div class="{{ action.name ? '' : 'has-error' }} action-col1">
                <mat-form-field appearance="fill" class="width-max hidden-print">
                    <mat-label>Mit és hogyan kell konkrétan megtenned a célkitűzés eléréséért?</mat-label>
                    <textarea matInput [readonly]="!isSelf || currentPeriod.isClosed" name="actionName" 
                        [(ngModel)]="action.name" 
                        title="{{action.name}}" 
                        [ngModelOptions]="{debounce: 1000, allowInvalid: false, standalone: true}" 
                        autocomplete="off" 
                        rows="6"
                        [disabled]="loading || !isSelf"
                        (change)="saveAction(action, goal)" 
                        required></textarea>
                </mat-form-field>
                <p class="form-control visible-print">{{ action.name }}</p>
            </div>               
            <div class="action-col2">                
                <mat-form-field appearance="fill" class="width-max">
                    <mat-label>Határidő</mat-label>
                    <mat-select 
                        [(value)]="action.deadline" 
                        disableOptionCentering class="drop-fix" 
                        (selectionChange)="saveAction(action, goal)" 
                        [disabled]="loading || !isSelf">
                        <mat-option *ngFor="let item of quarters" [value]="item.id">{{ item.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
        
                <div class="slider-component">
                    <div class="slider-labels flex-h">
                        <label class="slider-text-label">Önértékelés</label>
                        <label class="slider-value-label">{{ action.progress }}%</label>
                    </div>
                    <mat-slider
                        max="200"
                        min="0"
                    
                        [value]="action.progress" step="10"
                        (input)="action.progress = $event.value"
                        [disabled]="loading || !isSelf"
                        (change)="progressChanged($event, action, goal)"
                        >
                    </mat-slider>           
                </div>

                <div class="slider-component" *ngIf="action.leader">
                    <div class="slider-labels flex-h">                        
                        <label class="slider-text-label" [title]="action.leader.name">{{ action.leader.name }}</label>
                        <label class="slider-value-label">{{ action.leaderProgress }}%</label>
                    </div>
                    <mat-slider
                        max="200"
                        min="0"
                        step="10"
                        [value]="action.leaderProgress"
                        (input)="action.leaderProgress = $event.value"
                        [disabled]="loading || isSelf || !isLeaderOfSelectedEmployee"
                        (change)="leaderProgressChanged($event, action, goal)"
                        >
                    </mat-slider>           
                </div>
            </div>        
            <button mat-icon-button color="primary" class="action-delete hidden-print icon-button" *ngIf="isSelf && !currentPeriod.isClosed"  [disabled]="loading" (click)="deleteAction(goal, action.id)" title="Tevékenység törlése">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </mat-card-content>
</mat-card>

