import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Export } from 'src/app/models/export.model';
import { State } from '../../models/state.model';
import { StateService, StatisticsService } from '../../services';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { ErrorMessageService } from 'src/app/shared/services';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';

@Component({
    selector: 'statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
        
    public state: State;
    public exports$: Observable<Export[]>;    
    public displayedColumns = ['reviewType', 'reviewPeriodName', 'reviewPeriodDateText', 'export'];

    constructor(private statisticsService: StatisticsService, stateService: StateService, private msgService: ErrorMessageService) {
        this.state = stateService.state;
    }

    ngOnInit() {
        this.exports$ = this.statisticsService.getExports();
    }

    downloadExport(reviewPeriodId: number) {
        this.statisticsService.getExport(reviewPeriodId).subscribe({next: (response: HttpResponse<Blob>) => {
            this.statisticsService.saveStatistics(response);
        }, error: (err: any) => {
            this.msgService.showSnackBarMessage('exportError');
        }})
    };
    
}
