import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AssessmentStatusEnum } from 'src/app/enums/assessment-status.enum';
import { ReviewPeriodStatusEnum } from 'src/app/enums/review-period-status.enum';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { AssessmentHelper } from 'src/app/helpers/assessment.helper';
import { ReviewPeriodForEmployee } from 'src/app/models/review-period-for-employee.model';
import { AssessmentService, ReviewPeriodService } from 'src/app/services';
import { EmployeeForAssessments } from '../../models/employee-for-assessments.model';
import { State } from '../../models/state.model';
import { StateService } from '../../services/state.service';
import { Employee } from 'src/app/models/employee.model';
import { DateHelper } from 'src/app/shared/helpers/date.helper';
import { ErrorMessageService } from 'src/app/shared/services';

@Component({
    selector: 'assessments',
    templateUrl: './assessments.component.html',
    styleUrls: ['./assessments.component.scss'],
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({height: '0px', minHeight: '0'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ]
})
export class AssessmentsComponent implements OnInit {
    public reviewPeriodsForEmployee: ReviewPeriodForEmployee[];
    public masterTableColumns = ['reviewType', 'name', 'endDate', 'reviewPeriodStatus', 'action', 'expand'];

    public details$: Observable<EmployeeForAssessments[]>;
    public displayedColumns = ['name', 'email', 'employeeIdentifier', 'status', 'isContractor', 'action'];

    public state: State;

    public selectedReviewPeriod: ReviewPeriodForEmployee;

    public expandedElement: ReviewPeriodForEmployee;

    public reviewTypes = ReviewTypeEnum;
    public assessmentStates = AssessmentStatusEnum;
    public reviewPeriodStates = ReviewPeriodStatusEnum;

    public get isDetailRowInactive(): boolean {
        return this.expandedElement == null || this.expandedElement.reviewPeriod.status != ReviewPeriodStatusEnum.Open;
    }
  
    public get currentEmployee(): Employee {
        return this.state?.me.value?.employee;
    }

    constructor(stateService: StateService, 
        private assessmentService: AssessmentService, 
        private reviewPeriodService: ReviewPeriodService,
        private router: Router,
        private msgService: ErrorMessageService) 
    { 
        this.state = stateService.state;        
    }
    
    ngOnInit()
    {
        this.reviewPeriodService.getListForEmployee().subscribe(result => {
            this.reviewPeriodsForEmployee = result;
        });
    }

    hasDetails(reviewType: ReviewTypeEnum): boolean {
        return reviewType == ReviewTypeEnum.TER;
    }

    isClosed(reviewPeriod: ReviewPeriodForEmployee): boolean {
        return AssessmentHelper.isClosed(reviewPeriod);
    }

    isPreparationState(reviewPeriod: ReviewPeriodForEmployee): boolean {
        return reviewPeriod.reviewPeriod.status == ReviewPeriodStatusEnum.New;
    }

    isClickable(item: ReviewPeriodForEmployee): boolean {
        const res = this.hasDetails(item.reviewPeriod.reviewType) || (!this.isClosed(item) && !this.isPreparationState(item));
        return res;
    }

    isNavigationEnabled(reviewPeriod: ReviewPeriodForEmployee): boolean {
        const rt = reviewPeriod.reviewPeriod.reviewType;
        if (this.isPreparationState(reviewPeriod))
          return false;

        if (rt == ReviewTypeEnum.PersonalityTest || rt == ReviewTypeEnum.MilioStressMap || rt == ReviewTypeEnum.MilioTeamDysfunction) {
            let msg = '';
            const allowedStatus = !reviewPeriod.reviewPeriodEmployee || reviewPeriod.reviewPeriodEmployee.status == AssessmentStatusEnum.New || reviewPeriod.reviewPeriodEmployee.status == AssessmentStatusEnum.InProgress;

            if (!allowedStatus) 
            {
                //this.msgService.showSnackBarMessage('assessmentClosed');
                return false;
            }
            
            if (rt != ReviewTypeEnum.PersonalityTest && reviewPeriod.endDate && DateHelper.isDateExpired(reviewPeriod.endDate))
            {
                this.msgService.showSnackBarMessage('assessmentExpired');
                return false;
            }

            return true;
        }
        return false;
    }

    isDateExpired(date: Date): boolean {
        if (!date) return false;
        
        return DateHelper.isDateExpired(date);
    }

    loadDetails(reviewPeriod: ReviewPeriodForEmployee) {
        this.details$ = this.assessmentService.getEmployeesToReview(reviewPeriod.reviewPeriod.id);
    }

    onMasterRowClick(item: ReviewPeriodForEmployee) {
        if (this.hasDetails(item.reviewPeriod.reviewType))
        {
            this.expandedElement = this.expandedElement === item ? null : item;
            this.loadDetails(item);
        }
        else if (this.isNavigationEnabled(item))
        {
            this.router.navigate(this.getAssessmentLink(item.reviewPeriodEmployee.reviewPeriodEmployeeId));
        }        
    }

    onDetailRowClick(rpRow: ReviewPeriodForEmployee, item: EmployeeForAssessments) {
        if (rpRow.reviewPeriod.status != ReviewPeriodStatusEnum.Open) return;

        const url = this.getAssessmentLink(item.reviewPeriodEmployeeId);
        this.router.navigate(url);
    }
   
    clearRowSelection() {
        this.reviewPeriodsForEmployee.forEach(item => item.isSelected = false);
    }

    getAssessmentLink(reviewPeriodEmployeeId: number) {
        return ['/assessment', reviewPeriodEmployeeId];
    }  
}
