import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Roles } from 'src/app/enums/roles.enum';
import { CompanyStat } from 'src/app/models/company-stat.model';
import { State } from 'src/app/models/state.model';
import { AuthService, CompanyService, StateService } from 'src/app/services';
import { ActionStateModel } from 'src/app/shared/models/action-state.model';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {

  public companies: CompanyStat[];
  public masterTableColumns = ['name', 'employeeCount'];
  public state: State;

  public forbiddenReturnUrls = [
    '/company/create',
    'assessment/',
    'personality-test/diagram/',
    'personality-test/details/',
    'team-dysfunctions/',
    'team-stressmap/',
    'reports/',
    'goals/',
    'employee-add',
    'employee/'
  ];

  public get showAdminFunctions(): boolean {    
    return this.state?.isAdmin || this.state?.isSuperAdmin;
  }

  constructor(private companyService: CompanyService, 
    private authService: AuthService, 
    private router: Router,
    public appState: AppState,
    stateService: StateService
    ) { 
      this.state = stateService.state;
    }

  ngOnInit(): void {
    //this.companies$ = this.companyService.getStats();  
    this.loadList();    
  }

  loadList() {
    this.companyService.getStats().subscribe(data => {
      this.companies = data;
    });
  }

  onMasterRowClick(item: CompanyStat) {

    if (item.id !== this.appState.company.value.id)
    {
      this.authService.changeCompany(item.id).subscribe({ next: (data) => {        
        this.router.navigate([this.getLocation()]);
      }});
    }
    else
      this.router.navigate([this.getLocation()]);
  }

  getLocation() {
    if (!this.appState.prevLocation?.value) return '/';

    const isForbidden = this.forbiddenReturnUrls.find(x => this.appState.prevLocation?.value.includes(x) );

    if (this.appState.prevLocation?.value === this.appState.currentLocation?.value || isForbidden )
      return '/';

    return this.appState.prevLocation?.value;
  }

  createCompany() {
    this.router.navigate(['/company/create']);
  }

  showEmployeeCount(count: number) {
    if (this.state?.me?.value?.role != Roles.User)
      return count;
    else
      return '';
  }
  
}
