import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IdName } from 'src/app/models/general/id-name.model';
import { Search } from 'src/app/models/search.model';
import { EmployeeService, ReviewPeriodService } from 'src/app/services';
import { ActionStateModel, ListItem, SearchConfig, SearchOption } from 'src/app/shared/models';

@Component({
  selector: 'app-multi-item-selector',
  templateUrl: './multi-item-selector.component.html',
  styleUrls: ['./multi-item-selector.component.scss']
})
export class MultiItemSelectorComponent implements OnInit {

  private readonly itemsSubject = new BehaviorSubject<ListItem<IdName>[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();
  public actionState = {editMode: true, currentItemIndex: null} as ActionStateModel

  @Input()
  public rpId = 0;

  @Input()
  public mode: 'ReviewPeriod' | 'Employee';

  @Output()
  public add = new EventEmitter<IdName>();

  @Output()
  public remove = new EventEmitter<IdName>();

  public searchConfig: SearchConfig<IdName, number>;

  public searchRpConfig: SearchConfig<IdName, number> = {
    mapItem: (model: IdName) => {
      return {
        id: model.id, 
        text: `${model.name} (${model.description})`,
        dropText: `${model.name} (${model.description})`,
        itemSource: model } as SearchOption<IdName, number>;
    },
    getList: this.reviewPeriodService.getListAsIdName,
  };

  public searchEmployeeConfig: SearchConfig<IdName, number> = {
    mapItem: (model: IdName) => {
      return {
        id: model.id, 
        text: model.name,
        dropText: `${model.name} ${(model.description)}`,
        itemSource: model } as SearchOption<IdName, number>;
    },
    getList: this.employeeService.getList
  };

  constructor(private reviewPeriodService: ReviewPeriodService, private employeeService: EmployeeService) { 

  }

  ngOnInit(): void {

    if (this.mode === 'ReviewPeriod') {
      this.searchConfig = this.searchRpConfig;
      this.searchRpConfig.options = {reviewPeriodId: this.rpId} as Search;
    } else if (this.mode === 'Employee') {
      this.searchConfig = this.searchEmployeeConfig;
    }
    
  }

  public addItem(option: SearchOption<IdName, number>) {
      let list = this.itemsSubject.getValue();

      this.add.emit(option.itemSource);

      let item = new ListItem<IdName>(option.itemSource);
      list.push(item);

      this.itemsSubject.next(list);
  }

  public removeItem(item) {
    this.remove.emit(item.itemSource);

    let list = this.itemsSubject.getValue();
    list = list.filter(x => x.itemSource.id !== item.itemSource.id);
    this.itemsSubject.next(list);
  }
}
