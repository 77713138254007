<app-card #card title="Email küldés" [formGroup]="mainForm" [showEdit]="false" [disabled]="!settingState.reviewPeriod.value">
    
    <mat-radio-group formControlName="sendType">
        <mat-radio-button value="1">Egyéni</mat-radio-button>
        <div class="single-mail-panel sub-panel">
            <mat-radio-group formControlName="mailType">
                <mat-radio-button value="1">Regisztrációs levél</mat-radio-button>
                <mat-radio-button value="2">Elfelejtett jelszó</mat-radio-button>
            </mat-radio-group>
            <div>
                <app-search #search (change)="onSearchChange($event)" title="Dolgozó név vagy email" [isAllowSearch]="true" [showAddButton]="false" [config]="searchEmployeeConfig"></app-search>
            </div>
        </div>
        <mat-radio-button value="2">Tömeges</mat-radio-button>
        <div class="mass-mail-panel sub-panel">
            <div class="row-title margin-bottom-sm">Regisztrációs levél</div>
            <div class="margin-bottom-sm">
                <mat-checkbox formControlName="onlyForNewEmployees" title="Csak az új regisztráltaknak küldjük (akik még nem kaptak levelet)">Csak az új regisztráltaknak</mat-checkbox>
            </div>
            <div class="flex-h margin-bottom-sm">
                <mat-form-field appearance="standard" class="field-simple">
                    <mat-label>Sorsz. -tól</mat-label>
                    <input matInput type="text" formControlName="sernumFrom" autocomplete="off">
                    <mat-error msg></mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="field-simple">
                    <mat-label>Sorsz. -ig</mat-label>
                    <input matInput type="text" formControlName="sernumTo" autocomplete="off">
                    <mat-error msg></mat-error>
                </mat-form-field>
            </div>            

        </div>
    </mat-radio-group>
    <div class="action-buttons flex-end">
        <button mat-flat-button color="primary" [disabled]="!isValidSendType || !isValidSingleSettings || !isValidEmail" (click)="sendMail()">Küldés</button>
    </div>
    
</app-card>
