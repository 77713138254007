import { Employee } from "./employee.model";

export interface Action {
    id: number;
    name: string;
    deadline?: number;
    progress: number;

    leaderId?: number;
    leaderProgress?: number;
    leader: Employee;
}

export class ActionModel implements Action {
    id = 0;
    name = '';
    deadline?: number = null;
    progress = 0;

    leaderId = null;
    leaderProgress = null;
    leader = null;
}