<div class="container main-container">
    <h4>Felhasználó hozzáadása</h4>

    <app-card #card (ok)="onSave()" (cancel)="onCancel()" [formGroup]="mainForm" [actionState]="actionState" [showEdit]="false">
        
        <div class="form-container">
            <mat-form-field appearance="standard">
                <mat-label>Név</mat-label>
                <input matInput formControlName="name" autocomplete="off">
                <mat-error msg></mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Törzsszám</mat-label>
                <input matInput formControlName="employeeIdentifier" autocomplete="off">
                <mat-error msg></mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" autocomplete="off" required>
                <mat-error msg></mat-error>
            </mat-form-field>
                       
            <mat-form-field appearance="standard">
                <mat-label>Munkakör</mat-label>
                <mat-select formControlName="positionId">
                  <mat-option [value]="null">-</mat-option>
                  <mat-option *ngFor="let position of positions$ | async" [value]="position.id">{{ position.name }}</mat-option>
                </mat-select>
            </mat-form-field>
    
            <!-- <mat-form-field appearance="standard">
                <mat-label>Szerepkör</mat-label>
                <mat-select formControlName="role">
                  <mat-option value="5d6ae1fa-59e5-42f6-be68-b638f66ad925">Normál felhasználó</mat-option>
                  <mat-option value="f475f794-536e-4208-b8d7-e599aa255ad1">Aszisztens</mat-option>
                  <mat-option value="64cc75c1-f8f7-4d99-8d64-ba976868d864">Adminisztrátor</mat-option>
                </mat-select>
            </mat-form-field> -->
                    
            <mat-form-field appearance="standard">
                <mat-label>Jelszó</mat-label>
                <input matInput type="password" formControlName="password" autocomplete="off">
                <mat-error msg></mat-error>
            </mat-form-field>
    
            <mat-form-field appearance="standard">
                <mat-label>Jelszó megerősítés</mat-label>
                <input matInput type="password" formControlName="password" autocomplete="off">
                <mat-error msg></mat-error>
            </mat-form-field>
            
        </div>    
        
    </app-card>
</div>