import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployeeBuilkInsert } from '../models/employee-bulk-insert.model';
import { AddToEmployeeGroupValidation, EmployeeEmailsCheckResult } from '../models/employee-emails-check.model';
import { EmployeeGroupMember } from '../models/employee-group-member.model';
import { EmployeeGroup } from '../models/employee-group.model';
import { PagedListResult } from '../models/paged-list-result.model';
import { DefaultDataService } from './default-data.service';
import { StateService } from './state.service';
import { Result } from '../models/general/result.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGroupService extends DefaultDataService<EmployeeGroup> {
    
  constructor(
      http: HttpClient, 
      stateService: StateService,
  ) 
  {      
      super(http, stateService);
      this.controllerName = 'employee-group';
  }
    
  public getList(): Observable<PagedListResult<EmployeeGroup>> {
    return this.get<PagedListResult<EmployeeGroup>>(`/api/${this.controllerName}/list`, {headers: {'X-Skip-Interceptor':'1'}} );
  }

  public addGroup(model: EmployeeGroup): Observable<Result<EmployeeGroup>> {
    return this.put<Result<EmployeeGroup>>(`/api/${this.controllerName}/add`, model);
  }

  // Members

  public getMembers(groupId: number, reviewPeriodId: number): Observable<EmployeeGroup[]> {
    return this.get<EmployeeGroup[]>(`/api/${this.controllerName}/list-members/${groupId}/${reviewPeriodId}`, {headers: {'X-Skip-Interceptor':'1'}} );
  }

  public addMember(model: EmployeeGroupMember): Observable<void> {
    return this.put<void>(`/api/${this.controllerName}/add-member`, model);
  }

  public addMembers(groupId: number, employeeList: number[], reviewPeriodId: number): Observable<void> {
    const model = {
      parentId: groupId,
      employeeIdList: employeeList,
      reviewPeriodId: reviewPeriodId
    } as EmployeeBuilkInsert;

    return this.put<void>(`/api/${this.controllerName}/add-members`, model);
  }

  public deleteMember(groupId: number, memberId: number, reviewPeriodId: number): Observable<void> {
    const model = {
      employeeId: memberId,
      groupId: groupId,
      reviewPeriodId: reviewPeriodId
    };

    return this.post<void>(`/api/${this.controllerName}/delete-member`, model);
  }
  
  checkAddMembers = (data: AddToEmployeeGroupValidation): Observable<EmployeeEmailsCheckResult> => {
    return this.post<EmployeeEmailsCheckResult>(`/api/${this.controllerName}/check-add-members`, data);
  }
  
}
