import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PagedListResult } from "../models/paged-list-result.model";
import { Position } from "../models/position.model";
import { Search } from "../models/search.model";
import { DefaultDataService } from "./default-data.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root'
})
export class PositionService extends DefaultDataService<Position> {
    
    constructor(
        http: HttpClient, 
        stateService: StateService,
    ) 
    {
        super(http, stateService);
        this.controllerName = 'position';
    }
    
    getList = (options?: Search): Observable<PagedListResult<Position>> => {
        return this.post<PagedListResult<Position>>(`/api/${this.controllerName}/list`, options, {headers: {'X-Skip-Interceptor':'1'}} );
    }
}