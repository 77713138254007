import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { map, scan, switchMap, tap } from 'rxjs/operators';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { Kpi, KpiModel } from 'src/app/models/kpi.model';
import { KpiType } from 'src/app/models/kpiType.model';
import { ReviewPeriod } from 'src/app/models/review-period.model';
import { StateService } from 'src/app/services';
import { KpiService } from 'src/app/services/kpi.service';
import { CardComponent, DynamicListItemComponent, SearchComponent } from 'src/app/shared/components';
import { ComponentState } from 'src/app/shared/enum/component-state.enum';
import { ListItem } from 'src/app/shared/models/list-item.model';
import { SearchConfig } from 'src/app/shared/models/search-config.model';
import { SearchOption } from 'src/app/shared/models/search-option.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { EmployeeState } from 'src/app/store/employee.state';

@UntilDestroy()
@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit {

  @Input()
  public readonly = false;

  public itemsSubject = new BehaviorSubject<ListItem<Kpi>[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();
  public reviewPeriod$: Observable<ReviewPeriod>;
    
  public kpiForm: UntypedFormGroup;

  public get allowEdit(): boolean {
    return (this.stateService.state.isSuperAdmin || this.stateService.state.isAdmin || this.employeeStore.isBoss.value) && this.employeeStore.employee.value.hasBoss;
  }
  
  public kpiSearchConfig: SearchConfig<KpiType, number> = {
    mapItem: (model: KpiType) => {
      return {
        id: model.id, 
        text: model.name,
        dropText: model.name,
        itemSource: model } as SearchOption<KpiType, number>;
    },
    getList: this.kpiService.getTypeList
  };

  @ViewChild('card')
  card: CardComponent;

  @ViewChild('search')
  search: SearchComponent<KpiType, number>;

  public get allowAdd(): boolean {
    return this.itemsSubject.getValue().length === 0 || this.itemsSubject.getValue().every(x => x.state === ComponentState.View);
  }

  constructor(private fb: UntypedFormBuilder, private kpiService: KpiService, public employeeStore: EmployeeState, private msgService: ErrorMessageService, private stateService: StateService) {
  }

  ngOnInit(): void {
    // form create
    this.kpiForm = this.createForm();

    combineLatest([this.employeeStore.employee.obs$, this.employeeStore.reviewPeriod.obs$])
    .pipe(untilDestroyed(this))
    .subscribe(([employee, rp]) => {
      
      if (rp != null && rp.id != null)
      {
          this.loadList();
      }
      else
      {
        this.itemsSubject.next([]);
      }
    });        
     
  }

  private loadList() {
    if (!this.employeeStore.employee.value) return;
    
    this.kpiService.getList(this.employeeStore.reviewPeriod.value.id, this.employeeStore.employee.value.employee.id).pipe(
      map(list => list.map(x => new ListItem(x)))          
    )
    .pipe(untilDestroyed(this))
    .subscribe(itemArray => {
      itemArray.forEach( x => x.state = x.itemSource.kpiType.questionId ? ComponentState.Disabled : x.state );
      this.itemsSubject.next(itemArray);
    });
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({      
      'planned': ['', [Validators.required]],
      'unit': ['', [Validators.required]]
    })
  }

  public saveItem(listItemComponent: DynamicListItemComponent<Kpi>, item: ListItem<Kpi>) {
    let data = this.kpiForm.value;
    let action: Observable<ResultModel>;
    let model = item.itemSource;
    
    if (item.state === ComponentState.Add)
    {
      model.planned = data.planned;
      model.kpiType.unit = data.unit;
      action = this.kpiService.addData(model);
    }
    else if (item.state === ComponentState.Edit)
    {
      model.planned = data.planned;
      action = this.kpiService.updateData(model);
    }
    else
    {
      this.msgService.showSnackBarMessage('saveFailed');
      return;
    }
      
    action.subscribe({ next: (data) => {
      this.msgService.showSnackBarMessage('saveSuccessful');
      this.kpiForm.reset();
      this.search.disabled = false;
      listItemComponent.close();
      this.loadList();
    }, error:(err: IResultModel<ResultModel>) => {
      this.msgService.handleError<ResultModel>(err);
    }});
  }

  public cancelItem(item: ListItem<Kpi>) {
    if (item.state == ComponentState.Add)
    {
      // remove added item
      let items = this.itemsSubject.getValue().filter(x => x.state === ComponentState.View);
      this.itemsSubject.next(items);      
    }

    this.kpiForm.reset();
    this.search.disabled = false;
  }

  public deleteItem(item: ListItem<Kpi>) {
    this.kpiService.deleteData(item.itemSource).subscribe({ next: (result) => {

      //remove deleted item
      let items = this.itemsSubject.getValue().filter(x => x.itemSource.id != item.itemSource.id);
      this.itemsSubject.next(items);

      this.msgService.showSnackBarMessage('deleteSuccessful');
    }, error: (err: IResultModel<ResultModel>) => {
      this.msgService.handleError<ResultModel>(err);
    }});
  }
  
  public addItem(option: string | SearchOption<KpiType, number>) {
    let listitem = this.createItem(option);
    
    //add item    
    let items = this.itemsSubject.getValue();
    this.itemsSubject.next([...items, listitem]);
    this.card.actionState.currentItemIndex = items.length;
  }

  public editItem(item: Kpi) {
    this.kpiForm.setValue({ planned: item.planned, unit: item.kpiType.unit });
    this.search.disabled = true;
  }

  onOptionSelected() {

  }

  private createItem(option: string | SearchOption<KpiType, number>): ListItem<Kpi> {
    let item = new KpiModel();
    item.aboutUserId = this.employeeStore.employee.value.id;
    item.reviewPeriodId = this.employeeStore.reviewPeriod.value.id;
    
    if (typeof option === 'string')
    {
      item.kpiType.name = option;
    }
    else
    {
      item.kpiType = option.itemSource;
    }      

    let listitem = new ListItem<Kpi>(item);
    listitem.state = ComponentState.Add;

    return listitem;
  }

}
