import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonModel } from 'src/app/shared/models';
import { HtmlListItem } from 'src/app/shared/models/html-list-item.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  title: string;
  message: string;
  lines: HtmlListItem[];
  confirmButtonConfig: ButtonModel;
  choices: ButtonModel[] = [];
  showCancel = true;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogModel) {
    
    this.title = data.title;
    this.message = data.message;
    this.lines = data.lines;
    this.confirmButtonConfig = data.confirmButtonConfig ?? {name: 'Ok', color: 'primary'} as ButtonModel
    this.choices = data.choices ?? [];

    if (data.showCancel != null)
      this.showCancel = data.showCancel;
  }
  
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onCustom(key: string): void {
    this.dialogRef.close(key);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}

export interface IConfirmDialogModel {
  title: string;
  message?: string;
  lines?: HtmlListItem[];
  confirmButtonConfig?: ButtonModel;
  choices?: ButtonModel[];
  showCancel?: boolean;
}
