// do not commit this file

export const environment = {
    production: false,
    config: {
        rest_end_point: "#{rest_endpoint}#",
        domain: "#{domain}#",
        logo: '#{logo}#',
        assessment_menu_title: '#{assessment_menu_title}#',
      c: {
          assessment: {
              showReviewPeriod: true,
              showEmail: true,
              showRatingDescription: true,
              isNotChosingEnabled: true,
              showLeaderName: true
          },
          reports: {
              root: "reports",
              separatePeerAndSubordinate: true
          },
          statistics: {
              show: true
          },
          users: {
              show: true
          }
      },
      t: {
          title: "#{title}#",
          main: {
            greeting: "Üdvözöljük a {company} értékelő rendszerében!",
            createAssessment: "Értékelések készítése"
          },
          assessments: {
              title: "Kérlek adj visszajelzést/értékelést a következő személyekről!"
          },
          assessment: {
              title:
                  "Az értékelt személy: "
          }
      }      
    }
  };
