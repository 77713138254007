import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeHelper } from '../helpers/employee.helper';
import { EmployeeForAssessments } from '../models/employee-for-assessments.model';
import { Employee } from '../models/employee.model';
import { State } from '../models/state.model';
import { StateService } from '../services';

@Pipe({name: 'employeeName'})
export class EmployeeNamePipe implements PipeTransform {

    private state: State;
    constructor(stateService: StateService) {
        this.state = stateService.state;
    }

  transform(value: EmployeeForAssessments, idPart = '') {
      if (!value) return '';
      if (value.isSelf) return 'Önértékelés';
      if (value.isLeader && !this.state.config.c.assessment.showLeaderName) return "Divízió vezető";
      
      if (idPart == 'identifier')      
        return value.name + (value.employeeIdentifier ? ' ' + value.employeeIdentifier : '');
      else if (idPart == 'email')
        return value.name + ' ' + value.email;
      else
        return value.name;
  }
}

@Pipe({name: 'employeeDisplayName'})
export class EmployeeDisplayNamePipe implements PipeTransform {
    
    constructor() {}

  transform(value: Employee, separator = ' ') {
     return EmployeeHelper.getDisplayName(value, separator);
  }
}

@Pipe({name: 'employeeEmail'})
export class EmployeeEmailPipe implements PipeTransform {

  transform(value: EmployeeForAssessments) {
      if (!value) return '';
      return !value.isSelf ? value.email : '';
  }
}

@Pipe({name: 'employeeIdentifier'})
export class EmployeeIdentifierPipe implements PipeTransform {

  transform(value: EmployeeForAssessments) {
      if (!value || value.isSelf) return '';

      return value.employeeIdentifier ?? '';
  }
}

@Pipe({name: 'employeeType'})
export class EmployeeTypePipe implements PipeTransform {

  transform(value: EmployeeForAssessments) {
      if (!value) return '';
      if (value.isSelf) return '';
      return !value.isContractor ? '' : 'szerződéses';
  }
}

@Pipe({name: 'employeeReviewState'})
export class EmployeeReviewStatePipe implements PipeTransform {

  transform(value: EmployeeForAssessments) {
      if (!value) return '';

      const values = ['', 'Félbehagyott', 'Befejezett'];
      return values[value.status];      
  }
}