import { Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from '../models/user-profile.model';
import { EmployeeHelper } from '../helpers/employee.helper';
import { Roles } from '../enums/roles.enum';

@Pipe({name: 'userDisplayName'})
export class UserDisplayNamePipe implements PipeTransform {

  transform(value: UserProfile) {
      if (!value) return '';
      return value.employee ? value.employee.name : value.email;
  }
}

@Pipe({name: 'roleDisplayName'})
export class RoleDisplayNamePipe implements PipeTransform {

  transform(value: Roles) {
      if (!value) return '';
      
      return EmployeeHelper.getRoleDisplayName(value);
  }
}
