import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmployeePositionCreateDelete, EmployeePositionGet } from "../models/employee-position-create-delete.model";
import { EmployeePosition } from "../models/employee-position.model";
import { DefaultDataService } from "./default-data.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root'
})
export class EmployeePositionService extends DefaultDataService<EmployeePosition> {
    
    constructor(
        http: HttpClient, 
        stateService: StateService,
    ) 
    {
        super(http, stateService);
        this.controllerName = 'employee-position';
    }

    public getListByEmployee(model: EmployeePositionGet): Observable<EmployeePosition[]> {
        return this.post<EmployeePosition[]>(`/api/${this.controllerName}/list`, model);
    }

    public addData(model: EmployeePositionCreateDelete): Observable<void> {        
        return this.put(`/api/${this.controllerName}/add`, model);
    }      

    public deleteData(model: EmployeePositionCreateDelete): Observable<void> {
        return this.post(`/api/${this.controllerName}/delete`, model);
    }
}