import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmployeeHierarchy, EmployeeHierarchyCreateDelete } from "../models/employee-hierarchy.model";
import { DefaultDataService } from "./default-data.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root'
})
export class EmployeeHierarchyService extends DefaultDataService<EmployeeHierarchy> {
    
    constructor(
        http: HttpClient, 
        stateService: StateService,
    ) 
    {
        super(http, stateService);
        this.controllerName = 'employee-hierarchy';
    }

    public getLeadersByEmployee(subordinateId: number, reviewPeriodId?: number): Observable<EmployeeHierarchy[]> {
        if (reviewPeriodId)
            return this.get<EmployeeHierarchy[]>(`/api/${this.controllerName}/leaders/${subordinateId}/${reviewPeriodId}`);
        else
            return this.get<EmployeeHierarchy[]>(`/api/${this.controllerName}/leaders/${subordinateId}`);
    }

    public addLeader(model: EmployeeHierarchyCreateDelete): Observable<void> {
        return this.put<void>(`/api/${this.controllerName}/addLeader`, model);
    }

    public deleteLeader(model: EmployeeHierarchyCreateDelete): Observable<void> {
        return this.post<void>(`/api/${this.controllerName}/delete`, model);
    }
    
}