
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { State } from '../../models/state.model';
import { AuthService } from '../../services/auth.service';
import { StateService } from '../../services/state.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppState } from 'src/app/store/app.state';
import { Subscription, filter, fromEvent, map, merge, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, IConfirmDialogModel } from 'src/app/shared/components/dialogs';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {    
    public state: State;            
    public showHeader = false;
    public isLoginPage = false;

    public year = (new Date()).getFullYear();

    networkStatus: boolean = false;
    networkStatus$: Subscription = Subscription.EMPTY;

    public connectionDialog: MatDialogRef<ConfirmDialogComponent, any>;

    // not stored in history
    public readonly excludedUrls = [
        'login', 'company/create', 'employee-add', 'assessment/'
    ];

    constructor(
        public appState: AppState,
        private authService: AuthService,
        stateService: StateService,
        private router: Router,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute,
        ccService: NgcCookieConsentService,
        private dialog: MatDialog,
    ) {    
        this.state = stateService.state;
        this.manageIcons();
    }

    ngOnInit() {
        console.log('App component init...');

        this.storeRouteHistory();
               
        this.router.events
        .pipe(
            filter(event => event instanceof NavigationEnd),
            untilDestroyed(this)
        )
        .subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.isLoginPage = this.router.url.includes('login');
                this.storeRouteHistory();

                this.showHeader = !this.isLoginPage;
            }
        });
        
        this.checkNetworkStatus();
    }

    ngOnDestroy(): void {
        this.networkStatus$.unsubscribe();
    }

    inExcludedUrls(url: string): boolean {
        this.excludedUrls.forEach(element => {
            if (url.includes(element))
                return true;
        });

        return false;
    }

    storeRouteHistory() {
        this.appState.prevLocation.value = this.appState.currentLocation.value;

        if (!this.inExcludedUrls(this.router.url) && this.appState.currentLocation.value != this.router.url)
            this.appState.currentLocation.value = this.router.url;
    }

    manageIcons() {
        // this.matIconRegistry.addSvgIcon(
        //     "user-identity",
        //     this.domSanitizer.bypassSecurityTrustResourceUrl("../../../assets/svg/user-identity.svg")
        //   );
    }

    checkNetworkStatus() {
        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(
          of(null),
          fromEvent(window, 'online'),
          fromEvent(window, 'offline')
        )
          .pipe(map(() => navigator.onLine))
          .subscribe(status => {
            console.log('status:', (status ? 'online' : 'offline'));            
            this.networkStatus = status;

            if (!status)
                this.showConnectionDialog();
            else
                this.closeConnectionDialog();
          });
    }

    showConnectionDialog() {        
        this.connectionDialog = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: {
                title: 'Kapcsolódási hiba',
                message: `Megszűnt az internet kapcsolat. Kérjük ellenőrizze a hálózati beállításokat.`,
                showCancel: false
            } as IConfirmDialogModel
          });
    }

    closeConnectionDialog(){
        if (this.connectionDialog)
            this.connectionDialog.close();
    }
}
