import { Action, ActionModel } from "./action.model";
import { GoalText, GoalTextModel } from "./goaltext.model";
import { GroupGoal, GroupGoalModel } from "./group-goal.model";

export interface Goal {
    id: number;

    questionId?: number;
    questionName: string;

    goalTextId?: number;
    goalText: GoalText;

    actions: Action[];

    groupGoalId?: number;
    goalPeriodId?: number;
    groupGoal: GroupGoal;       // selected group goal

    isKpi: boolean;    
    targetValue?: number;    
}

export class GoalModel implements Goal {
    id = 0;
    
    questionId = null;
    questionName: string;
    
    goalTextId = null;
    goalText = new GoalTextModel();

    actions = [];    

    groupGoalId = null;
    groupGoal: GroupGoal = null;
    goalPeriodId = null;

    isKpi = false;    
    targetValue = null;
}