<form [formGroup]="mainForm" *ngIf="loaded">
    <h1 mat-dialog-title>Felhasználói szerepkör</h1>
    <div mat-dialog-content>
  
        <app-search title="Cég *" formControlName="company" [showAddButton]="false" [inlineButtons]="false" [isAllowSearch]="true" [config]="searchCompanyConfig"
        ></app-search>       
                       
        <mat-form-field appearance="standard" class="width-max">
            <mat-label>Szerepkör</mat-label>
            <mat-select formControlName="roleId" disableOptionCentering class="drop-fix">
                <mat-option *ngFor="let item of roleList" [value]="item.id">{{ item.name | roleDisplayName }}</mat-option>
            </mat-select>
            <mat-error msg></mat-error>
        </mat-form-field>
  
    </div>
    <div mat-dialog-actions class="flex-h flex-end">
      <button mat-flat-button color="primary" [disabled]="mainForm.invalid" (click)="ok()" type="submit">Ok</button>
      <button mat-flat-button [mat-dialog-close]>Mégsem</button>
    </div>
  </form>
