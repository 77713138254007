<div class="container-fluid full-height" *ngIf="userProfile">

    <div *ngIf="!isMobile" class="main-menu">
        <div class="menu-left">
            
        </div>
        <div class="menu-right">
            <button mat-button *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']" [routerLink]="['/settings']">Admin</button>
    
            <button mat-button [matMenuTriggerFor]="ratingMenu">Értékelés</button>
            <mat-menu #ratingMenu="matMenu">
                <button mat-menu-item [routerLink]="['/']">{{ assessmentMenuTitle }}</button>
                <button mat-menu-item [routerLink]="['/goals']" *ngIf="hasElevatedAccess || hasPanoramaOrAura">Célkitűzés/követés</button>
            </mat-menu>
    
            <ng-container *ngIf="hasElevatedAccessOrBoss">
                <button mat-button [matMenuTriggerFor]="reportMenu">Riportok</button>
                <mat-menu #reportMenu="matMenu">
                    <button mat-menu-item [routerLink]="['/reports']">Egyéni riportok</button>
                    <button mat-menu-item [routerLink]="['/statistics']" *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">Statisztikák</button>
                </mat-menu>
            </ng-container>
    
            <button mat-button [routerLink]="['/company-selector']">{{ company?.name ?? 'Nincs cég' }}</button>
    
            <button mat-button [matMenuTriggerFor]="userMenu" [title]="role">
                {{ userProfile | userDisplayName }}<mat-icon>arrow_drop_down</mat-icon> <mat-icon>account_circle</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu">            
                <button mat-menu-item [routerLink]="['/employee']">Személyi adatlap</button>
                <button mat-menu-item *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']" [routerLink]="['/employee-list']">Dolgozók</button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="logOut()">Kijelentkezés</button>
            </mat-menu>
        </div>
    </div>

    <div *ngIf="isMobile" class="mobile-menu">
        
        <ul class="id-panel">
            <li>
                <div class="flex-h align-items-center justify-content-center">
                    <a [routerLink]="['/employee']" (click)="mobileMenuClick()">{{ userProfile | userDisplayName }}</a> <mat-icon>account_circle</mat-icon>
                </div>
                <span>{{ role }}</span>
            </li>
            <li class="flex-h align-items-center justify-content-center">
                <mat-icon>domain</mat-icon> <a [routerLink]="['/company-selector']" (click)="mobileMenuClick()">{{ company?.name ?? 'Nincs cég' }}</a>
            </li>
        </ul>
        
        <div class="menu-content">
            <mat-card class="items-card">
                <mat-card-content>
    
                    <ul class="items-panel">
                        <li *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
                            <a [routerLink]="['/settings']" (click)="mobileMenuClick()">Admin</a>
                        </li>
                        <li>
                            Értékelés
            
                            <ul>
                                <li>
                                    <a [routerLink]="['/']" (click)="mobileMenuClick()">{{ assessmentMenuTitle }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/goals']" (click)="mobileMenuClick()" *ngIf="hasElevatedAccess || hasPanoramaOrAura">Célkitűzés/követés</a>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="hasElevatedAccessOrBoss">
                            Riportok
            
                            <ul>
                                <li>
                                    <a [routerLink]="['/reports']" (click)="mobileMenuClick()">Egyéni riportok</a>
                                </li>
                                <li *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
                                    <a [routerLink]="['/statistics']" (click)="mobileMenuClick()">Statisztikák</a>
                                </li>
                            </ul>
                        </li>
            
                        <li *ngxPermissionsOnly="['SuperAdmin', 'Admin', 'Assistant']">
                            <a [routerLink]="['/employee-list']" (click)="mobileMenuClick()">Dolgozók</a>
                        </li>
                        <li>
                            <a href="#" (click)="logOut()">Kijelentkezés</a>
                        </li>
                    </ul>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
</div>
