import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { QuestionsForAssessment } from 'src/app/models/questions-for-assessment.model';
import { FreeText } from 'src/app/models/free-text.model';
import { Question } from 'src/app/models/question.model';
import { State } from 'src/app/models/state.model';
import { AssessmentService, StateService } from 'src/app/services';

import { Config } from '../../models/config.model';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { QuestionModeEnum } from 'src/app/enums/question-mode.enum';
import { AssessmentHelper } from 'src/app/helpers/assessment.helper';
import { AppState } from 'src/app/store/app.state';
import { ReviewPeriod } from 'src/app/models/review-period.model';
import { CompleteReview } from 'src/app/models/complete-review.model';
import { FreeTextService } from 'src/app/services/free-text.service';
import { AssessmentStatusEnum } from 'src/app/enums/assessment-status.enum';
import { QuestionSet } from 'src/app/models/questionset-model';
import { QuestionAssessmentStatus } from 'src/app/models/question-assessment-status.model';
import { ViewHelper } from 'src/app/shared/helpers/view.helper';
import { QuestionComponent } from 'src/app/components';
import { ReviewPeriodEmployee } from 'src/app/models/review-period-employee.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorMessageService } from 'src/app/shared/services';
import { IResult, IResultModel, Result, ResultModel } from 'src/app/models/general/result.model';
import { RouteHelper } from 'src/app/helpers/route.helper';
import { MatDialog } from '@angular/material/dialog';

@UntilDestroy()
@Component({
    selector: 'assessment',
    templateUrl: './assessment.component.html',
    styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit, AfterViewInit, OnDestroy {
    public data: QuestionsForAssessment;
    
    @HostBinding('class.success-mode')
    public success = false;
    public config: Config;
    public messages: string[] = [];
    public state: State;    
    public validationError = false;

    public lastQuestionWithAnswer?: number;
    public currentQuestion?: number;

    public isError = false;
    public errorMessage: string;
    
    public feedback: string;
    public progressValue = 0;
    public progress$: Observable<number>;
    public firstIncompleteQuestionIndex = -1;
    
    public initCompleted = false;
    public reviewPeriodEmployee: ReviewPeriodEmployee;

    public dataloaded: any;

    public errorObject: any;

    public allQuestionsCount: number;
    public completeCount: number;

    public displayReview = false;

    public reviewType: ReviewTypeEnum;
    
    //func    
    public reviewTypes = ReviewTypeEnum;
    public createQuestionOptions = AssessmentHelper.createQuestionOptions;
        
    public get isLastQuestionComplete(): boolean {
        return this.initCompleted && this.appState.questionAssessmentStatusList.value.length > 0 && this.isAllQuestionsCompleted;
    }

    public get isComplete(): boolean {
        return this.reviewPeriodEmployee && this.reviewPeriodEmployee.status === AssessmentStatusEnum.Closed;
    }
    
    @ViewChild('finish', { read: ElementRef })
    finishButton: ElementRef;

    @ViewChild('kpiform', { read: ElementRef })
    kpiForm: ElementRef;
    
    @ViewChildren('questions')
    questions: QueryList<QuestionComponent>;

    constructor(
        private stateService: StateService, 
        private assessmentService: AssessmentService,
        private freeTextService: FreeTextService,
        private appState: AppState,
        private msgService: ErrorMessageService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private cd: ChangeDetectorRef
        )
    {        
        this.config = this.stateService.state.config;
        this.state = this.stateService.state;
       
        this.route.params
        .pipe(
            switchMap(params => {
                const reviewPeriodEmployeeId = params['reviewPeriodEmployeeId'];                
                return this.assessmentService.getAssessmentData(reviewPeriodEmployeeId);
            }),
            untilDestroyed(this)
        )
        .subscribe({ next: (result) => {
                
                this.data = result.data;
                var resultData = this.data;

                // Reset the counter every time when the assesment page loads
                //this.appState.assessmentQuestionIndex.value = 0;
                this.reviewPeriodEmployee = resultData.reviewPeriodEmployee;
                this.reviewType = resultData.reviewPeriodEmployee.reviewPeriod.reviewType;

                this.appState.assessmentCurrentQuestionIndex.value = -1;
                this.firstIncompleteQuestionIndex = -1;
                this.initCompleted = false;
                
                this.displayReview = this.reviewType != ReviewTypeEnum.PersonalityTest || resultData.reviewPeriodEmployee.status != AssessmentStatusEnum.Closed;

                this.setupQuestionSets(resultData.questionSets);
                this.setProgress();

                if (this.reviewType == ReviewTypeEnum.TER) {
                    this.setupCompetencyAndActiviy(resultData.questionSets);
                }

                if (this.reviewType == ReviewTypeEnum.PersonalityTest) {
                    this.setupPersonalityTest(resultData.questionSets);
                }

                if (this.reviewType === ReviewTypeEnum.MilioStressMap || this.reviewType === ReviewTypeEnum.MilioTeamDysfunction)
                {
                    this.setupMilio(resultData.questionSets);
                }

                this.initCompleted = true;

               
            }, 
            error: (err: IResult<QuestionsForAssessment, Result<QuestionsForAssessment>>) => {
                this.isError = true;
                if (err?.error?.errorData?.key)
                {
                    const msg = this.msgService.getErrorMessage(err.error.errorData.key);
                    this.errorMessage = msg.text;
                }
                
            }
        });
    }
   

    //

    ngOnInit() {
        
        this.appState.assessmentCurrentQuestionIndex.obs$.subscribe(currentIndex => {            
            //this.scrollToContent();
            this.setProgress();
        });
        
    }

    ngOnDestroy() {
        if (this.dataloaded) {
          clearInterval(this.dataloaded);
        }
    }  

    ngAfterViewInit() {
        this.scrollOnFirstLoad();
    }

    scrollOnFirstLoad() {
        let tryCount = 0;
        
        this.dataloaded = setInterval(() => {

            if (this.finishButton && this.reviewPeriodEmployee && this.questions)
            {
                clearInterval(this.dataloaded);
                this.scrollToContent();
            }

            if (tryCount === 10) {
                clearInterval(this.dataloaded);
            }

            tryCount++;

        }, 1000);
    }

    public scrollToContent() {
        if (!this.reviewPeriodEmployee || this.isComplete || this.appState.assessmentCurrentQuestionIndex.value === 0) return;

        if (this.isAllQuestionsCompleted) {
            if (this.reviewPeriodEmployee.reviewPeriod.reviewType == ReviewTypeEnum.TER) {
                if (this.kpiForm) 
                    ViewHelper.scroll(this.kpiForm.nativeElement);
                else if (this.finishButton)                    
                    ViewHelper.scroll(this.finishButton.nativeElement); 
            }
            else
            {
                if (this.finishButton)                     
                    ViewHelper.scroll(this.finishButton.nativeElement); 
            }
        }
        else if (this.questions && this.questions.length > 0) {
           let cmp = AssessmentHelper.getCurrentQuestionComponent(this.questions, this.appState.assessmentCurrentQuestionIndex.value);

           if (cmp)
           {
                cmp.scrollHere();
           }
        }
    }

    

    public reviewPeriodHasDate(reviewPeriod: ReviewPeriod): boolean {
        return !!reviewPeriod.startDate && !!reviewPeriod.endDate;
    }
        
    public isCompetencyAndActivityReview(data: QuestionsForAssessment): boolean {
        return data.reviewPeriodEmployee.reviewPeriod.reviewType === ReviewTypeEnum.TER;
    }

    public get isAllQuestionsCompleted(): boolean {
        return this.appState.questionAssessmentStatusList.value.every(x => x.isComplete);
    }

    public isReviewCanComplete(data: QuestionsForAssessment): boolean {
        let res = data.reviewPeriodEmployee.status != AssessmentStatusEnum.Closed && this.isAllQuestionsCompleted;
        return res;
    }

    setProgress() {        
        this.allQuestionsCount = this.appState.questionAssessmentStatusList.value.length;
        this.completeCount = this.appState.questionAssessmentStatusList.value.filter(x => x.isComplete).length;

        this.progressValue = Math.floor((this.completeCount / this.allQuestionsCount) * 100);
    }

    setupCompetencyAndActiviy(qSets: QuestionSet[]) {
        // let activitySet = qSet.childQuestions.find(x => x.reviewType == ReviewTypeEnum.Activity);

        // if (activitySet)
        //     activitySet.description = 'A válaszlehetőségek melletti százalékérték azt mutatja, hogy az adott tevékenység az esetek kb. mekkora részére igaz. Magyarul: milyen gyakran csinálja jól.';
    }

    setupPersonalityTest(qSets: QuestionSet[]) {
        //this.setQuestions(qSet);
        //this.currentQuestion = this.lastQuestionWithAnswer;
    }

    setupMilio(qSets: QuestionSet[]) {
        const children = qSets[0].childQuestions[0].childQuestions;
        qSets[0].childQuestions.pop();
        qSets[0].childQuestions.push(...children);
    }

    setupQuestionSets(qSets: QuestionSet[]) {

        let questionStates: QuestionAssessmentStatus[] = [];



        qSets.forEach(qSet => {
            qSet.childQuestions.forEach(q => {
                this.setupQuestions(q, questionStates);
            });            
        });
        
        // set state
        this.appState.questionAssessmentStatusList.value = questionStates;
        this.appState.assessmentCurrentQuestionIndex.value = this.firstIncompleteQuestionIndex;
    }

    setupQuestions(question: Question, questionStates: QuestionAssessmentStatus[]) {

        question.childQuestions.forEach(q => {

            if (q.questionMode === QuestionModeEnum.SingleAnswer || q.questionMode === QuestionModeEnum.MultipleAnswers)
            {                                
                const isComplete = AssessmentHelper.isQuestionComplete(q);
                questionStates.push({ questionId: q.id, isComplete: isComplete, name: q.name });
                q.questionIndex = questionStates.length - 1;

                // Az első kitöltetlen kérdés indexét keressük
                if (this.firstIncompleteQuestionIndex == -1 && !isComplete) {
                    this.firstIncompleteQuestionIndex = q.questionIndex;
                }

                if (!q.assessmentData) {
                    q.assessmentData = AssessmentHelper.createAssessment(q, this.reviewPeriodEmployee.reviewPeriodEmployeeId);
                }
            }
            else 
            {
                this.setupQuestions(q, questionStates);
            }
            
        });
    }
    
    

    isFreeTextAllowed (freetext: FreeText) {
        return freetext && freetext.isFreeTextAllowed;
    };

    hasItems(arr: []): boolean {
        return arr && arr.length > 0;
    }
    
    freeTextChanged(model: FreeText) {        
        this.freeTextService.createOrUpdate(model).subscribe(() => {

        });
    }

    save(data: QuestionsForAssessment) {
        data.isSave = true;
        this.assessmentService.submit(data).subscribe((result) => {
            this.success = true;
        });
    }

    complete(data: QuestionsForAssessment) {
        this.messages = [];
        
        this.processQuestionSets(data.questionSets);
        
        // if (data.assessmentHead.reviewPeriod.reviewType == ReviewTypeEnum.TER && data.keyPerformanceIndicators) {
        //     data.keyPerformanceIndicators.forEach((kpi) => {
        //         if ( !kpi.achieved && !kpi.noAnswer && !kpi.kpiType.questionId ) {
        //             this.validationError = true;
        //             kpi.error = true;
        //         } else kpi.error = false;
        //     });
        // }

        if (!this.validationError) {
            
            const details = {
                reviewPeriodEmployeeId: data.reviewPeriodEmployee.reviewPeriodEmployeeId
            } as CompleteReview;

            this.assessmentService.completeReview(details).subscribe({next: (result) => {
                this.success = true;
            }, error: (err: IResultModel<ResultModel>) => {
                this.msgService.handleError<ResultModel>(err);
                RouteHelper.reloadCurrentRoute(this.router);
            }});            
        }
        else
            this.messages.push('Kérjük minden kérdést válaszoljon meg!');
    };

    processQuestionSets(questionSets: QuestionSet[]) {
        questionSets.forEach(questionSet => { 
            questionSet.childQuestions.forEach(qm => {
                this.processQuestions(qm);
            });            
        });
    }

    processQuestions(textblock: Question) {

        if (textblock.questionMode === QuestionModeEnum.Group || textblock.questionMode === QuestionModeEnum.ComplexQuestion)
        {            
            textblock.childQuestions.forEach(qm => {
                this.processQuestions(qm);
            });
        }
        else
        {
            //question
            if (!AssessmentHelper.isQuestionComplete(textblock)) {
                this.validationError = true;
                textblock.error = true;
            } 
            else { 
                textblock.error = false;
            }
        }
    }

    saveFeedback(data: QuestionsForAssessment) {
        this.assessmentService.saveFeedback(data.reviewPeriodEmployee.reviewPeriodEmployeeId, this.feedback).subscribe((result) => { 

        });
    }
   
    getReportLink(data: QuestionsForAssessment) {        
        if (data.reviewPeriodEmployee.reviewPeriod.reviewType == ReviewTypeEnum.PersonalityTest)
            return ['/reports/personality-test/details/', data.reviewPeriodEmployee.reviewPeriodEmployeeId];

        return [];
    }

    cancel() {
        this.router.navigate(['/assessments']);
    }
}
