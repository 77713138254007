import { Component, Input, OnInit } from '@angular/core';
import { AppState } from 'src/app/store/app.state';

@Component({
  selector: 'app-back-nav',
  templateUrl: './back-nav.component.html',
  styleUrls: ['./back-nav.component.scss']
})
export class BackNavComponent implements OnInit {

  constructor(private appState: AppState) { }

  @Input()
  public defaultLink = '/';

  @Input()
  public linkText = 'Vissza';

  public 

  ngOnInit(): void {
  }

  getLocation() {
    if (!this.appState.prevLocation?.value) return this.defaultLink;
      
    //all other cases we return the previous location
    return this.appState.prevLocation?.value;
  }
}
