import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FreeText } from "../models/free-text.model";
import { DefaultDataService } from "./default-data.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root'
})
export class FreeTextService extends DefaultDataService<FreeText> { 
    
    constructor(
        http: HttpClient, 
        stateService: StateService,
    ) 
    {
        super(http, stateService);
        this.controllerName = "free-text";
    }

    createOrUpdate(model: FreeText): Observable<void> {
        return this.post(`/api/${this.controllerName}/createOrUpdate`, model);
    }
}