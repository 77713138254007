<h1 mat-dialog-title>
    {{title}}
</h1>

<div mat-dialog-content>
    <p *ngIf="message">{{message}}</p>
    <div *ngIf="lines">
        <p *ngFor="let item of lines; index as i" [index]="i" [source]="item" [ngClass]="item.classList" >
            {{item.text}}
        </p>
    </div>
</div>

<div mat-dialog-actions [align]="'end'">
    <button mat-flat-button [color]="confirmButtonConfig.color" (click)="onConfirm()" *ngIf="choices.length == 0">{{ confirmButtonConfig.name }}</button>
    
    <ng-container *ngIf="choices.length > 0">
        <button mat-flat-button [color]="item.color" *ngFor="let item of choices" [value]="item" (click)="onCustom(item.key)">{{ item.name }}</button>
    </ng-container>
    
    <button mat-flat-button (click)="onDismiss()" *ngIf="showCancel">Mégsem</button>
</div>