import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Permissions } from './enums/permissions.enum';
import { AuthGuard, NoAuthGuard } from './guards';

import {     
    AssessmentComponent, 
    AssessmentsComponent, 
    ConfirmEmailComponent, 
    CreateRoleUserComponent,
    GoalsComponent, 
    LoginComponent,
    ReportsComponent,
    StatisticsComponent,
    EmployeeComponent,
    EmployeeAddComponent,
    SettingsComponent,
    PositionComponent,
    PasswordResetComponent,
    CompanySelectorComponent,
    EmployeeListComponent,
    CompanyPageComponent,
    SimpleSearchTestComponent,
    CancelUserDeleteComponent,
} from './pages';

import {
    ReportComponent, 
    PersonalityTestDetailsComponent,
    PersonalityTestDiagramComponent,
    TeamDysfunctionsComponent,
    TeamStressMapComponent,
} from './reports';

// export function getPermissions(permissions: Permissions[]) {
//     console.log(permissions);
//     return ['Admin'];
// }

const routes: Routes = [
    {
        path: '', 
        component: AssessmentsComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Értékelések készítése'
        }
    },    
    //{path: 'registration', component: RegistrationComponent},
    {
        path: 'login', 
        component: LoginComponent,
        canActivate: [NoAuthGuard],
        data: {
            title: 'Bejelentkezés'
        }
    },
    {
        path: 'password-reset/:email',
        component: PasswordResetComponent,
        data: {
            title: 'Új jelszó beállítása'
        }
    },
    {
        path: 'cancel-user-delete',
        component: CancelUserDeleteComponent,
        data: {
            title: 'Felhasználó törlésének leállítása'
        }
    },
    // {
    //     path: 'users', 
    //     component: UsersComponent,
    //     canActivate: [AuthGuard, NgxPermissionsGuard],
    //     data: {
    //         title: 'Felhasználók',
    //         permissions: {
    //             only: [Permissions.SuperAdmin, Permissions.Admin, Permissions.Assistant]
    //         }
    //     }
    // },
    {
        path: 'statistics', 
        component: StatisticsComponent,
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
            title: 'Statisztikák',
            permissions: {
                only: [Permissions.SuperAdmin, Permissions.Admin, Permissions.Assistant]
            }
        }
    },
    {
        path: 'confirmEmail/:userId', 
        component: ConfirmEmailComponent,
        data: {
            title: 'Email megerősítés'
        }
    },   
    {
        path: 'company-selector',
        component: CompanySelectorComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'company/create',
        component: CompanyPageComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'employee-list',
        component: EmployeeListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'simple-search-test',
        component: SimpleSearchTestComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'assessment/:reviewPeriodEmployeeId',
        component: AssessmentComponent,
        canActivate: [AuthGuard],
    },
        
    {
        path: 'reports/personality-test/diagram/:reviewPeriodEmployeeId',
        component: PersonalityTestDiagramComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'reports/personality-test/details/:reviewPeriodEmployeeId',
        component: PersonalityTestDetailsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'reports/team-dysfunctions/:reviewPeriodId',
        component: TeamDysfunctionsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'reports/team-stressmap/:reviewPeriodId',
        component: TeamStressMapComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'reports/:employeeId/:reviewPeriodId',
        component: ReportComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'reports', 
        component: ReportsComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Riportok'
        }
    },

    {
        path: 'goals', 
        component: GoalsComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Célkitűzés/nyomkövetés'
        }
    },
    {
        path: 'goals/:employeeId', 
        component: GoalsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'employee', 
        component: EmployeeComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Dolgozó adatlap'
        }
    },
    {
        path: 'employee-add',
        component: EmployeeAddComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Dolgozó hozzáadás'
        }
    },
    {
        path: 'employee/:employeeId', 
        component: EmployeeComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Dolgozó adatlap'
        }
    },    
    // {
    //     path: 'position',
    //     component: PositionComponent,
    //     canActivate: [AuthGuard],
    //     data: {
    //         title: 'Munkakörök'
    //     }
    // },
    // {
    //     path: 'position/:positionId',
    //     component: PositionComponent,
    //     canActivate: [AuthGuard],
    //     data: {
    //         title: 'Munkakör'
    //     }
    // },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Beállítások'
        }
    },
    { 
        path: '**', 
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}