import { Component, Input, OnInit } from '@angular/core';
import { Config } from 'src/app/models/config.model';
import { KpiAchieved } from 'src/app/models/kpi-achieved.model';
import { Kpi } from 'src/app/models/kpi.model';
import { State } from 'src/app/models/state.model';
import { KpiService } from 'src/app/services';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-kpi-form',
  templateUrl: './kpi-form.component.html',
  styleUrls: ['./kpi-form.component.scss']
})
export class KpiFormComponent implements OnInit {

  public state: State;
  public config: Config;

  @Input()
  public disabled = false;

  @Input()
  public list: Kpi[];

  constructor(private stateService: StateService, private kpiService: KpiService) {
    this.config = this.stateService.state.config;
    this.state = this.stateService.state;
  }

  ngOnInit(): void {
  }

  onSetKpi(indicator: Kpi) {
      if (indicator.noAnswer) {
          indicator.achieved = null;
          indicator.error = false;
      }
      indicator.isAssessed = indicator.achieved != null || indicator.noAnswer;
      
      this.saveKpi(indicator);
      //this.messages = [];
  };

  changeKpiText(indicator: Kpi) {
      indicator.noAnswer = false;

      if (!!indicator.achieved)
      {
          indicator.error = false;
          indicator.achieved = parseInt(indicator.achieved.toString());
          indicator.isAssessed = true;
          this.saveKpi(indicator);
      }
  }

  disableKpiEdit(indicator: Kpi): boolean {
    return (indicator.kpiType.questionId != null && indicator.kpiType.questionId != 0) || indicator.byEmployeeId != this.state.me.value.employee.id;
  }

  saveKpi(indicator: Kpi) {    
    
    let data = {
      kpiId: indicator.id,
      achieved: indicator.achieved,
      isAssessed: indicator.isAssessed
    } as KpiAchieved;

    this.kpiService.updateAchieved(data, indicator.aboutUserId).subscribe(result => {

    });
  }
}
