import { Component, OnInit, ViewChild } from '@angular/core';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { State } from 'src/app/models/state.model';
import { ErrorMessageService, TokenService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';
import { SettingsState } from 'src/app/store/settings.state';
import { StateService } from '../../services/state.service';
import { AngularFileUploaderComponent } from 'src/app/shared/components';
import { IResultModel, ResultModel } from 'src/app/models/general/result.model';
import { EmployeeImportResult } from 'src/app/models/other/employee-import-result.model';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDetailsDialogComponent } from 'src/app/dialogs/error-details-dialog/error-details-dialog.component';
import { ErrorDialogData } from 'src/app/models/dialog/error-dialog.model';

@Component({
    selector: 'uploads',
    templateUrl: './uploads.component.html',
    styleUrls: ['./uploads.component.scss']
})
export class UploadsComponent implements OnInit {
    public state: State;
    public afuConfig: any;    
    
    @ViewChild('fileUpload')
    private fileUpload:  AngularFileUploaderComponent;
    public docType: number | null = null;
   
    //config
    public assignEmployeesToReviewPeriod = false;
    public updateInReviewPeriod = false;

    public endpointList: UploadEndpoint[] = [
        {id: 1, name: '1. Skálák', url: '/api/files/upload-scales'},
        {id: 2, name: '2. Kompetencia-tevékenység és kérdésadatok', url: '/api/files/upload-positions/{rp}'},
        {id: 3, name: '3. Alkalmazott adatok', url: '/api/files/upload-employees/{rp}'},
        {id: 4, name: '4. KPI adatok', url: '/api/files/upload-kpi/{rp}'}
    ]

    public get reviewPeriodName(): string {
        return this.settingsState.reviewPeriod.value ? ReviewPeriodHelper.getDisplayText(this.settingsState.reviewPeriod.value) : '';
    }

    constructor(
        stateService: StateService, 
        public settingsState: SettingsState, 
        public appState: AppState, 
        private tokenService: TokenService, 
        private msgService: ErrorMessageService,
        private dialog: MatDialog,
        ) 
    {
        this.state = stateService.state;
    }
    
    ngOnInit() {
        this.setUploadConfig();
    }

    public getUploadUrl(id: number): string {
        let url = this.endpointList.filter(item => item.id === id)[0].url;

        const rp = this.settingsState.reviewPeriod.value;

        if (this.docType == 4) {
            if (rp) {
                url = url.replace('{rp}', rp.id.toString());
            }
        }
        else if (this.docType == 3) {
            if (rp && this.assignEmployeesToReviewPeriod) {
                url = url.replace('{rp}', rp.id.toString());
            } else
                url = url.replace('{rp}', '');
        }
        else if (this.docType == 2) {
            if (rp && this.updateInReviewPeriod) {
                url = url.replace('{rp}', rp.id.toString());
            } else
                url = url.replace('{rp}', '');
        }
        
        return this.state.config.rest_end_point + url;
    }   

    private setUploadConfig() {
        this.afuConfig = {
            multiple: false,
            formatsAllowed: ".xlsx",
            maxSize: "3",
            uploadAPI:  {
              url: '',
              method:"POST",
              headers: {             
                "Authorization" : `Bearer ${this.tokenService.getToken()}`
              },
              responseType: 'json',
            },
            theme: "dragNDrop",
            hideProgressBar: true,
            hideResetBtn: true,
            hideSelectBtn: true,
            fileNameIndex: true,
            replaceTexts: {
              selectFileBtn: 'Tallózás...',
              resetBtn: 'Reset',
              uploadBtn: 'Feltöltés',
              dragNDropBox: 'Drag N Drop',
              attachPinBtn: 'Attach Files...',
              afterUploadMsg_success: 'Sikeresen feltöltve!',
              afterUploadMsg_error: 'Feltöltés sikertelen!',
              sizeLimit: 'Méret limit'
            }
        };
    }
  
    public docUpload(data: any) {
        if (!data.ok) {
            this.fileUpload.enableUploadBtn = true;           
        }
        else {            
            this.settingsState.reloadReviewPeriodEmployeeList.value = true;
        }

        if(this.docType === 2 || this.docType === 4)
        {
            if (!data.ok)
            {                
                this.msgService.handleError<ResultModel>(data);
            }
        }

        if(this.docType === 3)
        {
            if (!data.ok)
                this.handleUploadError(data.error);
            else if (data.body.errorData.key === 'importHasSomeError' || data.body.errorData.key === 'importDataError')
                this.handleUploadError(data.body);
        }
    }

    public handleUploadError(result: EmployeeImportResult) {
        //const errorKey = err.error?.errorData?.key;

        /*if (errorKey)
        {
            const defaultMsg = this.msgService.getErrorMessage(errorKey);
            const dict = err.error.employeeErrorList;
            if (dict){

                const criticalErrors = ['badEmail', 'employeeExistsInDefaultCompany', 'employeeExistsInOtherCompany'];

                //check critical errors, these will stop the import process
                for (let key of criticalErrors) {
                    let list = dict[key];

                    if (list)
                    {
                        let msg = this.msgService.getErrorMessage(key);
                        let emailList = list.map(item => item.email).join(', ');
                        let textToDisplay = `${msg.text}\r\n (${emailList})`;

                        this.msgService.showSnackBarError(textToDisplay, 20000);
                        return;
                    }
                };

                //check other errors
                for (let key in dict) {
                    let value = dict[key];
                    
                    this.msgService.showSnackBarMessage(key);
                    // now only the first is interesting (later we can show all)
                    return;
                }
            }

            this.msgService.displayMessage(defaultMsg, 20000);
            return;
        }
        
        this.msgService.showSnackBarMessage('importError');*/

        const dialogData = {
            title: 'Import hiba',
            errorData: result?.errorData,
            employeeErrorList: result?.employeeErrorList
        } as ErrorDialogData;

        this.dialog.open(ErrorDetailsDialogComponent, {
            maxWidth: "600px",
            data: dialogData
          });
    }

    public resetUpload() {
        this.fileUpload.resetFileUpload();
        this.docType = null;
    }

    public docTypeChanged(value: number) {
        this.assignEmployeesToReviewPeriod = false;

        const url = this.getUploadUrl(value);
        this.fileUpload.config.uploadAPI.url = url;
        this.fileUpload.uploadAPI = url;
    }

    public assignEmployeesToReviewPeriodChanged() {
        if (this.docType === 3) {
            const url = this.getUploadUrl(this.docType);
            this.fileUpload.config.uploadAPI.url = url;
            this.fileUpload.uploadAPI = url;
        }
    }

    public updateInReviewPeriodChanged()
    {
        if (this.docType === 2) {
            const url = this.getUploadUrl(this.docType);
            this.fileUpload.config.uploadAPI.url = url;
            this.fileUpload.uploadAPI = url;
        }
    }

}

interface UploadEndpoint {
    id: number;
    name: string;
    url: string;    
}
