<header class="container-fluid shadow" [ngClass]="{'title-mode': !!title}">

    <div class="site-branding" >
        <div class="site-logo" [routerLink]="['']">
            <img [src]="state.config.logo" [alt]="state.config.t.title" width="121" height="32">
        </div>
    </div>

    <nav *ngIf="isNav">
        <app-menu *ngIf="state.isLoggedIn"></app-menu>
    </nav>
    
    <div class="title-cell" *ngIf="title">
        {{ title.toUpperCase() }}
    </div>

    <div class="hamburger-menu-button" *ngIf="!title">
        <button mat-icon-button class="hidden-print icon-button" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
</header>

<div class="menu-panel" [ngClass]="{'opened': isMenuOpen}">
    <app-menu *ngIf="state.isLoggedIn" [isMobile]="true" (menuItemClicked)="menuItemClicked()"></app-menu>
</div>

<div class="test-mode hidden-print" *ngIf="isTestMode" title="Teszt mód során minden kimenő email egy előre meghatározott címre érkezik">
    Teszt mód
</div>