<div class="container main-container" *ngIf="state.config.c.statistics.show">
    <h4 class="page-title">Statisztikák</h4>

    <mat-card class="card-light" *ngIf="exports$ | async as exports">
        <mat-card-content>
            <table mat-table [dataSource]="exports" class="table-default table-hover">

                <ng-container matColumnDef="reviewType">
                    <th mat-header-cell *matHeaderCellDef>Típus</th>
                    <td mat-cell *matCellDef="let element"><strong>{{ element.reviewType | reviewPeriodType }}</strong></td>
                </ng-container>

                <ng-container matColumnDef="reviewPeriodName">
                    <th mat-header-cell *matHeaderCellDef>Név</th>
                    <td mat-cell *matCellDef="let element"><strong>{{ element.reviewPeriodName }}</strong></td>
                </ng-container>

                <ng-container matColumnDef="reviewPeriodDateText">
                    <th mat-header-cell *matHeaderCellDef>Értékelési időszak</th>
                    <td mat-cell *matCellDef="let element"><strong>{{ element.reviewPeriodDateText }}</strong></td>
                </ng-container>

                <ng-container matColumnDef="export">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"><button mat-flat-button color="primary" (click)="downloadExport(element.reviewPeriodId)">Export</button></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>
