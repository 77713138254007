import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Export } from '../models/export.model';
import { DataService } from './data.service';
import { FileService } from './file.service';
import { StateService } from './state.service';

@Injectable({
    providedIn: 'root',
})
export class StatisticsService extends DataService {

    constructor(http: HttpClient, stateService: StateService, private fileService: FileService) {
        super(http, stateService);
    }

    //getAll() {
    //    return this.http.get(this.apiUrl + '/api/statistics');
    //}

    // statistics lista
    getExports(): Observable<Export[]> {
        return this.http.get<Export[]>(this.apiUrl + '/api/exports');
    }

    // statistics download
    getExport(reviewPeriodId: number) {
        return this.fileService.downloadFile(this.apiUrl + '/api/export/' + reviewPeriodId);
    }

    // statistics elmentése
    saveStatistics(response: HttpResponse<Blob>)
    {
        const filename = response.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');
        return this.fileService.saveAs(filename, response.body);
    }
}
