import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployeeForAssessments } from '../models/employee-for-assessments.model';
import { Report } from '../models/report.model';
import { PersonalityTestReport } from '../models/report/personality-test-report.model';
import { ReviewPeriodForEmployee } from '../models/review-period-for-employee.model';
import { DataService } from './data.service';
import { StateService } from './state.service';
import { QuestionCategoryResultsForReviewPeriod } from '../models/report/question-category-results-for-reviewperiod.model';

@Injectable({
    providedIn: 'root',
})
export class ReportService extends DataService {

    constructor(http: HttpClient, stateService: StateService) {
        super(http, stateService);
    }

    // reports for each review period
    getReportsForReviewPeriod(): Observable<ReviewPeriodForEmployee[]> {
        const url = `/api/report/review-period-list`;
        return this.get<ReviewPeriodForEmployee[]>(url);
    }

    // list of reports for each employee for one review period
    getReportsForEmployees(reviewPeriodId: number): Observable<EmployeeForAssessments[]> {
        const url = `/api/report/employee-list/${reviewPeriodId}`;
        return this.get<EmployeeForAssessments[]>(url);
    }

    // get data for one report
    getReport(employeeId, reviewPeriodId): Observable<Report> {
        const url = `/api/report/reports/${employeeId}/${reviewPeriodId}`;
        return this.get<Report>(url);
    }

    getPersonalityTestReport(reviewPeriodEmployeeIdList: number[]): Observable<PersonalityTestReport> {
        const url = `/api/report/personality-test`;
        return this.post<PersonalityTestReport>(url, { reviewPeriodEmployeeIdList: reviewPeriodEmployeeIdList });
    }
    
    checkReports(): Observable<boolean> {
        const url = "/api/report/show";
        return this.get(url);
    }

    getMilioReport(reviewPeriodIdList: number[]): Observable<QuestionCategoryResultsForReviewPeriod[]> {
        const url = `/api/report/milio`;
        return this.post<QuestionCategoryResultsForReviewPeriod[]>(url, { reviewPeriodIdList: reviewPeriodIdList });
    }
    
}
