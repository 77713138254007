import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ReviewPeriod } from "../models/review-period.model";
import { ReviewPeriodService } from "../services";
import { ReviewPeriodHelper } from "../helpers/review-period.helper";
import { StateValue } from "../shared/models/state-value.model";

@Injectable({
    providedIn: 'root'
})
export class SettingsState{
    public readonly reviewPeriod = new StateValue<ReviewPeriod>(null);
    public readonly reviewPeriodList = new StateValue<ReviewPeriod[]>(null);

    public readonly reloadReviewPeriodEmployeeList = new StateValue<boolean>(false);

    constructor(private reviewPeriodService: ReviewPeriodService) {
                
    }

    loadReviewPeriodList(): Observable<ReviewPeriod[]> {
        return this.reviewPeriodService.getReviewPeriodList().pipe(
            tap(list => {
                this.reviewPeriodList.value = list;

                if (!this.reviewPeriod.value) {
                    this.reviewPeriod.value = ReviewPeriodHelper.getCurrentReviewPeriod(list);
                } 
            })
        );
    }
        
}