import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { GroupGoal, GroupGoalModel } from 'src/app/models/group-goal.model';
import { GroupGoalsData } from 'src/app/models/group-goals-data.model';
import { GroupGoalService } from 'src/app/services/group-goal.service';

@Component({
  selector: 'app-group-goals',
  templateUrl: './group-goals.component.html',
  styleUrls: ['./group-goals.component.scss']
})
export class GroupGoalsComponent implements OnInit {

  @Input()
  public data: GroupGoalsData;

  public groupGoals: GroupGoal[];

  isAddGroupGoal = false;

  loaded = false;
  
  constructor(private groupGoalService: GroupGoalService) { }

  ngOnInit(): void {
    this.data.loading = true;
    this.groupGoalService.getGroupGoals(this.data.reviewPeriodId, this.data.employeeId)
    .subscribe((groupGoals) => {      

      this.data.groupGoals = groupGoals;
      this.groupGoals = groupGoals;
      this.data.loading = false;
      this.loaded = true;
    });
  }

  addNew() {
    //this.isAddGroupGoal = true;
    let found = this.groupGoals.find(x => x.id == 0);

    if (!found)
      this.groupGoals.push(new GroupGoalModel());
  }

  itemAdded(groupGoal: GroupGoal) {
    const index = this.groupGoals.findIndex(x => x.id == 0);
    this.groupGoals[index] = groupGoal;
  }

  itemDeleted(id: number) {
    this.groupGoals = this.groupGoals.filter(gg => gg.id != id);
  }
}
