<app-card #card title="Elkészült értékelések" [useExternalButtons]="true" [showEdit]="false" [disabled]="!settingState.reviewPeriod.value">
    <div>
        <div>
            <mat-form-field appearance="standard">
                <mat-label>Kereső</mat-label>
                <input matInput type="text" [formControl]="searchControl" autocomplete="off">
                <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Törlés" (click)="clearText()">
                  <mat-icon>close</mat-icon>
                </button>                
            </mat-form-field>

            <ng-container *ngIf="items$ | async as items">
                <div class="message-panel">
                    {{ searchMessage }}
                </div>
                <div class="scroll-panel">
                    <app-dynamic-list-item [actionState]="card.actionState" [noActions]="true" [noAction]="true" *ngFor="let item of items; index as i" [index]="i" [source]="item">
                        <div slot="readonly">
                            <ng-container *ngIf="reviewPeriod && (reviewPeriod.reviewType === reviewTypes.TER)">
                                <a class="secondary flex-horizontal mainitems" [routerLink]="['/reports', item.itemSource.id, reviewPeriod.id]">
                                    <div class="field-text">
                                        <span class="name"><strong>{{ item.itemSource.name }}</strong></span>
                                        <span class="email">({{ item.itemSource.email }}) </span>
                                        <span class="id">{{ item.itemSource.employeeIdentifier ?? '' }}</span>
                                    </div>
                                </a>
                            </ng-container>

                            <ng-container *ngIf="reviewPeriod && reviewPeriod.reviewType === reviewTypes.PersonalityTest">
                                <div class="flex-horizontal">
                                    <div class="field-text">
                                        <span class="name"><strong>{{ item.itemSource.name }}</strong></span>
                                        <span class="email" title="dolgozó email címe">{{ item.itemSource.email }} </span>
                                        <span class="id" title="dolgozó törzsszáma">{{ item.itemSource.employeeIdentifier ?? '' }}</span>
                                        <div class="clicking" title="Torzítás és Kattintgatás">
                                            Torzítás: <strong>{{ item.itemSource.distortion }}</strong>, Kattintgatás: <strong>{{ item.itemSource.clicking }}</strong>
                                        </div>                                        
                                        <div class="closeDate" *ngIf="item.itemSource.closedOn" title="befejezés dátuma">
                                            {{ item.itemSource.closedOn | date:'yyyy.MM.dd' }}
                                        </div>
                                    </div>
                                    <div class="field-icons flex-horizontal mainitems">
                                        <a mat-icon-button [routerLink]="['/reports/personality-test/details', item.itemSource.reviewPeriodEmployeeId]" *ngIf="item.itemSource.reviewPeriodEmployeeId">
                                            <mat-icon fontSet="material-icons-outlined" title="Egyéni riport">article_outline</mat-icon>
                                        </a>
                                        <a mat-icon-button [routerLink]="['/reports/personality-test/diagram', item.itemSource.reviewPeriodEmployeeId]" *ngIf="item.itemSource.reviewPeriodEmployeeId">
                                            <mat-icon fontSet="material-icons-outlined" title="Diagram riport">insert_chart_outline</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="reviewPeriod && (reviewPeriod.reviewType === reviewTypes.MilioStressMap || reviewPeriod.reviewType === reviewTypes.MilioTeamDysfunction)">
                                <div class="flex-horizontal">
                                    <div class="list-col flex-h align-items-center flex-fill">{{ item.itemSource.name }}</div>
                                    <div class="list-col flex-h align-items-center">{{ getStatus(item.itemSource.status) }}</div>
                                    <div class="field-icons flex-horizontal mainitems">
                                        <a mat-icon-button [routerLink]="['/reports/team-dysfunctions', item.itemSource.reviewPeriodId]" *ngIf="reviewPeriod.reviewType === reviewTypes.MilioTeamDysfunction">
                                            <mat-icon fontSet="material-icons-outlined" title="Állapotfelmérés">article_outline</mat-icon>
                                        </a>
                                        <a mat-icon-button [routerLink]="['/reports/team-stressmap', item.itemSource.reviewPeriodId]" *ngIf="reviewPeriod.reviewType === reviewTypes.MilioStressMap">
                                            <mat-icon fontSet="material-icons-outlined" title="Állapotfelmérés">article_outline</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </app-dynamic-list-item>
                </div>
                <mat-paginator #paginator [length]="total"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions"
                            (page)="pagingSettingsChanged($event)"
                            aria-label="Válasszon oldalt">
                </mat-paginator>
            </ng-container>
            
        </div>        
    </div>
</app-card>

