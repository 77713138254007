import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { StateService } from "./state.service";
import { Observable } from "rxjs";
import { CompanyUserRole } from "../models/company-user-role.model";
import { DefaultDataService } from "./default-data.service";
import { Search } from "../models/search.model";
import { PagedListResult } from "../models/paged-list-result.model";
import { Role } from "../models/role.model";
import { Result, ResultModel } from "../models/general/result.model";

@Injectable({
    providedIn: 'root',
})
export class UserRolesService extends DefaultDataService<CompanyUserRole> {

    constructor(
        http: HttpClient, 
        stateService: StateService,
    ) 
    {
        super(http, stateService);
        this.controllerName = 'user-roles';
    }

    public getList = (options: Search): Observable<PagedListResult<CompanyUserRole>> => {
        if (!options) options = {search: ''} as Search;

        return this.post<PagedListResult<CompanyUserRole>>(`/api/${this.controllerName}/list`, options, {headers: {'X-Skip-Interceptor':'1'}});
    }

    // public getList(userId: string): Observable<CompanyUserRole[]> {
    //     return this.get<CompanyUserRole[]>(`/api/${this.controllerName}/company-user-roles/${userId}`);
    // }

    public deleteUserRole(model: CompanyUserRole): Observable<ResultModel> {
        return this.post<ResultModel>(`/api/${this.controllerName}/delete`, model);
    }

    public getRoles(): Observable<Role[]> {
        return this.get<Role[]>(`/api/${this.controllerName}/roles`);
    }
}