import { Inject, Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FORM_ERRORS, MESSAGES } from '../helpers/messages';
import { IResult, IResultModel, Result, ResultModel } from 'src/app/models/general/result.model';
import { Message, MessageType } from '../models';


@Injectable({
    providedIn: 'root',
})
export class ErrorMessageService {
    constructor(
        @Inject(FORM_ERRORS) private form_errors, 
        @Inject(MESSAGES) private messages: {[key: string]: Message},
        private snackBar: MatSnackBar){
    }

    getFormErrorMessage(controlErrors: ValidationErrors, customErrors?: {[key: string]: string}): string {
        const firstError = Object.keys(controlErrors)[0];
        const getError = this.form_errors[firstError];
        const error = (customErrors && customErrors[firstError]) || getError(controlErrors[firstError]);
        return error;
    }

    getErrorMessage(errorKey: string): Message {
        return this.messages[errorKey] ? this.messages[errorKey] : { text: errorKey, type: MessageType.error };
    }

    getErrorText(errorKey: string): string {
        let msg = this.getErrorMessage(errorKey);
        return msg.text;
    }

    showSnackBarMessage(errorKey: string, duration = 5000) {
        const msg = this.getErrorMessage(errorKey);
        this.showSnackBar(msg.text, msg.type, duration);
    }

    showSnackBarError(message: string, duration = 5000) {
        this.showSnackBar(message, MessageType.error, duration);
    }

    showSnackBarInfo(message: string) {
        this.showSnackBar(message, MessageType.info);
    }

    showSnackBarWarning(message: string) {
        this.showSnackBar(message, MessageType.warning);
    }

    displayMessage(msg: Message, duration = 5000) {
        this.showSnackBar(msg.text, msg.type, duration);
    }
    
    public getFirstErrorFromResponse(response: any): string {
        const arr = response.error.errors as Array<string>;
        const firstKey = Object.keys(arr)[0];
        return arr[firstKey][0];
    }

    public getFirstValidationErrorKey(errors: {[key: string]: string[]}): string {
        const errorFieldName = Object.keys(errors)[0];
        return errors[errorFieldName][0];
    }

    public showErrorResponse(response: any) {
        if (response.status === 403)
            this.showSnackBarMessage('insufficientPrivileges');
        else if (response.error) {
            const msg = this.getFirstErrorFromResponse(response);
            this.showSnackBarError(msg);
        }
        else
        {
            this.showSnackBarMessage('unsuccessfulAction');
        }
    }

    public getErrorResponseText(response: any) {
        let errorMsg = '';
        if (response.status === 403)
        {
            errorMsg = this.getErrorText('insufficientPrivileges');
        }
        else if (response.error) {
            errorMsg = this.getFirstErrorFromResponse(response);
        }
        else
        {
            errorMsg = this.getErrorText('unsuccessfulAction');
        }

        return errorMsg;
    }

    private showSnackBar(message: string, type: MessageType, duration = 5000) {
        this.snackBar.open(message, '', {
            panelClass: this.getSnackBarCssClass(type),
            duration: duration
        })
    }

    private getSnackBarCssClass(type: MessageType): string
    {
        if (type === MessageType.info) {
            return 'snackbar-info';
        }
        else if (type === MessageType.success) {
            return 'snackbar-success';
        }
        else if (type === MessageType.warning) {
            return 'snackbar-warning';
        }
        else
            return '';  // default is an error
    }
    
    public handleError<TError extends ResultModel>(err: IResultModel<TError>) {
        if (err.status === 403)
            this.showSnackBarMessage('insufficientPrivileges');
        else if (err && err.error)
        {
            let errorData = err.error.errorData;

            if (errorData)
            {
                if (errorData.key)
                {
                    const errorKey = this.messages[errorData.key] ? errorData.key : 'unsuccessfulAction';
                    this.showSnackBarMessage(errorKey);
                }
                else if (errorData.msg)
                {
                    this.showSnackBarError(errorData.msg);
                }
                else
                {
                    this.showSnackBarMessage('unsuccessfulAction');
                }
            }
            else
                this.showErrorResponse(err);
        }
        else
            this.showSnackBarMessage('unsuccessfulAction');
    }

    public handleErrorWithData<TData, TError extends Result<TData>>(err: IResult<TData, TError>) {    
        
        if (err.status === 403)
            this.showSnackBarMessage('insufficientPrivileges');
        else if (err && err.error)
        {
            let errorData = err.error.errorData;

            if (errorData)
            {
                if (errorData.key)
                {
                    const errorKey = this.messages[errorData.key] ? errorData.key : 'unsuccessfulAction';
                    this.showSnackBarMessage(errorKey);
                }
                else if (errorData.msg)
                {
                    this.showSnackBarError(errorData.msg);
                }
                else
                {
                    this.showSnackBarMessage('unsuccessfulAction');
                }
            }
            else if (err.error.errors)
            {
                const errorKey = this.getFirstValidationErrorKey(err.error.errors) ?? 'unsuccessfulAction';
                this.showSnackBarMessage(errorKey);
            }
            else
                this.showErrorResponse(err);
        }
        else
            this.showSnackBarMessage('unsuccessfulAction');
    }

    public getError<TError extends ResultModel>(err: IResultModel<TError>): string {
        let errorMsg = '';
        
        if (err.status === 403)
        {
            errorMsg = this.getErrorText('insufficientPrivileges');
        }
        else if (err && err.error)
        {
            let errorData = err.error.errorData;

            if (errorData)
            {
                if (errorData.key)
                {
                    const errorKey = this.messages[errorData.key] ? errorData.key : 'unsuccessfulAction';
                    errorMsg = this.getErrorText(errorKey);
                }
                else if (errorData.msg)
                {
                    errorMsg = errorData.msg;
                }
                else
                {
                    errorMsg = this.getErrorText('unsuccessfulAction');
                }
            }
            else
            {
               errorMsg = this.getErrorResponseText(err);
            }
        }
        else
        {
            errorMsg = this.getErrorText('unsuccessfulAction');
        }

        return errorMsg;
    }
}