import { Observable } from "rxjs";
import { PagedListResult } from "src/app/models/paged-list-result.model";
import { Search } from "src/app/models/search.model";
import { SearchOption } from "./search-option.model";

export class SearchConfig<T, TId> {
    public getList: (options?: Search) => Observable<PagedListResult<T>>;
    public mapItem: (model: T) => SearchOption<T, TId>;
    public options?: Search;
}
