import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, tap } from 'rxjs';
import { ColorHelper } from 'src/app/helpers/color.helper';
import { QuestionCategoryResult } from 'src/app/models/question-category-result.model';
import { PersonLegend } from 'src/app/models/report/person-legend.model';
import { ReportBlock } from 'src/app/models/report/report-block.model';
import { ReportOptions } from 'src/app/models/report/report-options.model';
import { ReportService } from 'src/app/services';
import { TextGuideService } from 'src/app/services/text-guide.service';

@UntilDestroy()
@Component({
  selector: 'app-personality-test-details',
  templateUrl: './personality-test-details.component.html',
  styleUrls: ['./personality-test-details.component.scss']
})
export class PersonalityTestDetailsComponent implements OnInit {

  public colors: string[] = ['#433696', '#17a5e3', '##F09443', '#2B9443', '#D94646'];
  
  public legend: PersonLegend[] = [];
  public reportOptions: ReportOptions;

  public data$: Observable<ReportBlock[]>;

  public reportStructure: ReportBlock[] = [
    {
      title: 'Motiváció & problémamegoldás',
      factorOrder: ['MOT003', 'MOT006', 'MOT008', 'MOT007', 'MOT004', 'MOT005', '-', 'MOT001', '-', 'MOT009', 'MOT002'],
      rowResultBottom: true
    },
    {
     title: 'Viselkedés',
     factorOrder: ['VIS002', 'VIS007', 'VIS006', 'VIS005', 'VIS004', 'VIS003', '-', 'VIS001'],
     rowResultBottom: true
    },
    {
      title: 'kiegészítő faktorok',
      subtitle: 'Határozottság: ',
      factorOrder: ['COM', '-', 'CONF'],
      rowResultBottom: false
    }
  ];

  public confidenceTitles = [
    { title: 'Elsődleges kommunikációs stílus', index: 0, code: 'COM', text: 'Az eredmények alapján Önre a legtöbb helyzetben jellemző kommunikációs stílus' },
    { title: 'Másodlagos kommunikációs stílus', index: 1, code: 'COM', text: 'Az eredmények alapján további, Önre gyakran jellemző kommunikációs stílus' },
    { title: 'Elsődleges konfliktuskezelési stílus', index: 0, code: 'CONF', text: 'Az eredmények alapján Önre a legtöbb helyzetben jellemző konfliktuskezelési stílus' },
    { title: 'Másodlagos konfliktuskezelési stílus', index: 1, code: 'CONF', text: 'Az eredmények alapján további, Önre gyakran jellemző konfliktuskezelési stílus' }
  ];

  constructor(private reportService: ReportService, private route: ActivatedRoute, private guideService: TextGuideService) { }

  ngOnInit(): void {
    this.route.params
    .pipe(untilDestroyed(this))
    .subscribe(params => {
      const reviewPeriodEmployeeId: number | null = parseInt(params['reviewPeriodEmployeeId']);

      this.reportOptions = this.guideService.getGuideAsReportOptions('assessment:SZILUETT');

      if (reviewPeriodEmployeeId)
      {          
        
        this.data$ = this.reportService.getPersonalityTestReport([reviewPeriodEmployeeId]).pipe(
          map(data => {

            const dataForEmployee = data.items[0];            

            this.reportOptions.showCreatedByPerson = true;
            
            // legend
            dataForEmployee.color = ColorHelper.getChartLegendColor(this.colors, 0);
            this.legend.push({ person: dataForEmployee.employee, colorOptions: dataForEmployee.color, reviewDate: dataForEmployee.reviewDate });

            const result: ReportBlock[] = [];

            // report structure
            this.reportStructure.forEach(block => {
              
              const resultItem = { ...block };

              resultItem.items = [];              
              resultItem.factorOrder.forEach(factorCode => {
                
                if (factorCode != '-')
                {
                  const item = dataForEmployee.categoryResults.find(x => x.questionCategory.code == factorCode);

                  if (factorCode === 'COM' || factorCode === 'CONF') {
                    let index = 0;

                    if (item && item.bestChildren) {
                      item.bestChildren.forEach(best => {
                        const confTitle = this.confidenceTitles.find(x => x.code == factorCode && x.index == index);
                        
                        const value = best.questionCategory.name;
                        best.questionCategory.name = confTitle.title;
                        best.resultText = confTitle.text + ': <b>' + value + '</b>';
                        resultItem.items.push(best);
  
                        index++;
                      });
                    }


                  }
                  else
                  {
                    resultItem.items.push(item);
                  }
                }
                else
                {
                  resultItem.items.push({questionCategory: null} as QuestionCategoryResult);
                }
                
              });

              result.push(resultItem);
              
            });
            
            return result;
          })
        );

      }


    });

  }

}
