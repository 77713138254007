import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ChartLegendItem, PersonLegend } from 'src/app/models/report/person-legend.model';
import { ReportOptions } from 'src/app/models/report/report-options.model';

@Component({
  selector: 'app-report-frame',
  templateUrl: './report-frame.component.html',
  styleUrls: ['./report-frame.component.scss']
})
export class ReportFrameComponent implements OnInit {    

  @Input() 
  public headerRatio21 = false;

  @Input() public reportTypeName: string;    
  @Input() public reportOptions: ReportOptions;

  @Input() public reviewDate?: Date;

  @Input() public showLegend = false;
  @Input() public showLegendIcon = true;
  @Input() public legend: PersonLegend[] | ChartLegendItem[] = [];
  @Input() public colors: string[] = ['#8BD658', '#5B9BD5', '#D59A1C'];
  @Input() public noContentCss = false; 

  @HostBinding('class.no-background')
  @Input() public isNoBackground = false;

  constructor() { }

  ngOnInit(): void {
  }

}
