import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { EmployeeReviewDialogComponent } from 'src/app/dialogs';
import { ReviewTypeEnum } from 'src/app/enums/review-type.enum';
import { ReviewPeriodHelper } from 'src/app/helpers/review-period.helper';
import { EmployeeForAssessments } from 'src/app/models/employee-for-assessments.model';
import { Employee } from 'src/app/models/employee.model';
import { OpenAssessmentEmployee } from 'src/app/models/open-assessment-employee.model';
import { PagingRequest } from 'src/app/models/paging-request.model';
import { ReviewPeriodEmployee } from 'src/app/models/review-period-employee.model';
import { Search } from 'src/app/models/search.model';
import { State } from 'src/app/models/state.model';
import { AssessmentService, StateService } from 'src/app/services';
import { DateHelper } from 'src/app/shared/helpers/date.helper';
import { ListItem } from 'src/app/shared/models/list-item.model';
import { ErrorMessageService } from 'src/app/shared/services';
import { AppState } from 'src/app/store/app.state';
import { SettingsState } from 'src/app/store/settings.state';

@UntilDestroy()
@Component({
  selector: 'app-missing-reviews',
  templateUrl: './missing-reviews.component.html',
  styleUrls: ['./missing-reviews.component.scss']
})
export class MissingReviewsComponent implements OnInit {

  private readonly itemsSubject = new BehaviorSubject<ListItem<OpenAssessmentEmployee>[]>([]);
  public readonly items$ = this.itemsSubject.asObservable();

  public searchControl: UntypedFormControl;

  public minCharCount = 3;

  public viewMode?: string = 'byEmployee';

  // paging
  public total = 0;
  public pageSize = 10;
  public pageSizeOptions = [5, 10, 20, 50];
  public page = 0;
  public state: State;
  
  public searchMessage = '';
  
  public get isValidCharacterCount(): boolean {
    return this.searchControl.value.length === 0 || this.searchControl.value.length >= this.minCharCount;
  }

  @ViewChild('paginator')
  paginator: MatPaginator;
    
  public get searchValue(): string {
    return this.searchControl.value;
  }

  public get hasSubitems(): boolean {
    return this.settingState.reviewPeriod?.value.reviewType == ReviewTypeEnum.TER;
  }

  public setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  public get isPanoramaOrAura(): boolean {
    return this.settingState.reviewPeriod?.value?.reviewType == ReviewTypeEnum.TER;
  }

  public get isEditable(): boolean {
    return ReviewPeriodHelper.isBlockEditable(this.settingState, this.state);
  }

  constructor(
    public settingState: SettingsState, 
    public appState: AppState, 
    public stateService: StateService,
    private assessmentService: AssessmentService, 
    private msgService: ErrorMessageService,
    private dialog: MatDialog,
    ) {
      this.state = stateService.state;
  }

  ngOnInit(): void {    
    this.searchControl = new UntypedFormControl('');
    
    combineLatest([this.settingState.reviewPeriod.obs$, this.settingState.reloadReviewPeriodEmployeeList.obs$])
    .pipe(untilDestroyed(this))
    .subscribe(([reviewPeriod, reload]) => {
      if (reviewPeriod || reload)
        this.loadList();
    });

    this.searchControl.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.firstPage();
          this.setValidationMessage();
        }),
        switchMap(searchString => searchString.length === 0 || searchString.length >= this.minCharCount ? this.employeeSearch(searchString): of([]))
    ).subscribe(itemArray => {
      this.itemsSubject.next(itemArray);    
    });
    
  }

  public pagingSettingsChanged(data: PageEvent)
  {
    this.page = data.pageIndex;
    this.pageSize = data.pageSize;
    this.loadList();
  }

  private employeeSearch(searchString: string): Observable<ListItem<OpenAssessmentEmployee>[]> {
    if (!this.settingState.reviewPeriod.value) return of([]);
    
    return this.assessmentService.getOpen(this.buildPageRequest(searchString)).pipe(
      tap(data => {
        this.total = data.total;
        this.searchMessage = this.getResultMessage(data.list.length);
      }),
      map(result => result.list.map(x => new ListItem(x)) )
    );    
  }

  private buildPageRequest(searchString: string): Search {
    let data = {
      search: searchString,
      reviewPeriodId: this.settingState.reviewPeriod.value.id,
      viewMode: this.viewMode,
      pagingRequestInfo: {
        pageSize: this.pageSize,
        page: this.page + 1
      } as PagingRequest
    } as Search;

    return data;
  }

  public clearText() {
    this.searchControl.setValue('');
    //this.loadList();
  }

  public isDateExpired(date: Date): boolean {
    if (!date) return false;
    
    return DateHelper.isDateExpired(date);
 }

  private loadList() {
    this.employeeSearch(this.searchControl.value).subscribe(itemArray => {
      this.itemsSubject.next(itemArray);      
    });
  }
   
  public setValidationMessage() {
    if (!this.isValidCharacterCount)
      this.searchMessage = `Minimum ${ this.minCharCount } karakter szükséges`;
  }

  public getResultMessage(count: number): string {
      if (this.total > count)
        return `${ this.total } találatból ${ count } megjelenítve, kérem szűkítse a keresési feltételt.`;
      else if (count === 0)
        return 'Nincs találat';
      else
        return '';
  }

  public rowEdit(openAssessmentItem: OpenAssessmentEmployee, employee: EmployeeForAssessments) {
    
    let rpe: ReviewPeriodEmployee;

    if (this.viewMode == 'byEmployee')
    {
      rpe = {
        reviewPeriodEmployeeId: employee.reviewPeriodEmployeeId ?? openAssessmentItem.mainItem.reviewPeriodEmployeeId,
        byEmployee: openAssessmentItem.mainItem as Employee,
        aboutEmployee: employee as Employee,
        reviewPeriod: this.settingState.reviewPeriod.value
      } as ReviewPeriodEmployee;
    }
    else
    {
      rpe = {
        reviewPeriodEmployeeId: employee.reviewPeriodEmployeeId ?? openAssessmentItem.mainItem.reviewPeriodEmployeeId,
        byEmployee: employee as Employee,
        aboutEmployee: openAssessmentItem.mainItem as Employee,
        reviewPeriod: this.settingState.reviewPeriod.value
      } as ReviewPeriodEmployee;
    }

    const dialogRef = this.dialog.open(EmployeeReviewDialogComponent, {
      minWidth: "500px",
      data: rpe,
    });

    dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
      if (dialogResult)
      {         
        this.settingState.reloadReviewPeriodEmployeeList.value = true;
      }
    });

  }

  public add() {

    const data = {
      reviewPeriod: this.settingState.reviewPeriod.value,
    } as ReviewPeriodEmployee;

    const dialogRef = this.dialog.open(EmployeeReviewDialogComponent, {
      minWidth: "500px",      
      data: data
    });

    dialogRef.afterClosed().subscribe( (dialogResult: boolean) => {
      if (dialogResult)
      {         
        this.settingState.reloadReviewPeriodEmployeeList.value = true;
      }
   });
  }

  public rowDelete(rpeId: number) {
    this.assessmentService.deleteReviewPeriodEmployee(rpeId).subscribe({
      next: () => {
        this.settingState.reloadReviewPeriodEmployeeList.value = true;
      },
      error: (error) => {
        this.msgService.showSnackBarMessage('unsuccessfulAction');
      }
    });    
  }

  public viewByEmployee() {
    this.viewMode = 'byEmployee';
    this.loadList();
  }

  public viewAboutEmployee() {
    this.viewMode = 'aboutEmployee';
    this.loadList();
  }
  
}
