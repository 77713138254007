import { Component, Input, OnInit } from '@angular/core';
import { PersonLegend } from 'src/app/models/report/person-legend.model';

@Component({
  selector: 'app-review-date',
  templateUrl: './review-date.component.html',
  styleUrls: ['./review-date.component.scss']
})
export class ReviewDateComponent implements OnInit {

  @Input() 
  public legend: PersonLegend[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
