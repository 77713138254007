import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-freetext-panel',
  templateUrl: './freetext-panel.component.html',
  styleUrls: ['./freetext-panel.component.scss']
})
export class FreetextPanelComponent implements OnInit {

  @Input()
  public subTitle: string;

  @Input()
  public freeTexts: string[] = [];

  @Input()
  public showSubTitle = false;

  @Input()
  public noMargin = false;

  constructor() { }

  ngOnInit(): void {
  }

}
