<app-card #card title="Elérhetőségek" [formGroup]="detailForm" *ngIf="model$ | async as model" (ok)="save()">

    <div class="flex-vertical" slot="edit">
        <mat-form-field appearance="standard">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" autocomplete="off" required>
            <mat-error msg></mat-error>
        </mat-form-field>
    </div>
    
    <div slot="readonly">
        <app-read-only-field icon="email" label="Email" [value]="model.email"></app-read-only-field>        
    </div>

</app-card>
