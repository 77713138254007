import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoalTypeEnum } from 'src/app/enums/goal-type.enum';
import { GroupGoal, GroupGoalModel } from 'src/app/models/group-goal.model';
import { GroupGoalsData } from 'src/app/models/group-goals-data.model';
import { GoalService } from 'src/app/services';
import { GroupGoalService } from 'src/app/services/group-goal.service';
import { ErrorMessageService } from 'src/app/shared/services';

@Component({
  selector: 'app-group-goal-edit',
  templateUrl: './group-goal-edit.component.html',
  styleUrls: ['./group-goal-edit.component.scss']
})
export class GroupGoalEditComponent implements OnInit {
  
  @Input()
  public groupGoal: GroupGoal;

  @Input()
  public data: GroupGoalsData;

  @Output()
  public added = new EventEmitter<GroupGoal>();

  @Output()
  public updated = new EventEmitter<GroupGoal>();

  @Output()
  public deleted = new EventEmitter<number>();

  public goalTextId?: number;
  public goalText: string;
  public parentGroupGoalId?: number;

  constructor(private groupGoalService: GroupGoalService, private goalService: GoalService, private msgService: ErrorMessageService) { }

  ngOnInit(): void {
    this.goalText = this.groupGoal.goalText.name;
    this.goalTextId = this.groupGoal.goalText.id;
  }

  addGroupGoal() {
    this.data.loading = true;

    let model = new GroupGoalModel();
    model.goalText.name = this.goalText;
    model.goalText.goalType = GoalTypeEnum.GroupGoal;
    model.reviewPeriodId = this.data.reviewPeriodId;
    model.employeeId = this.data.employeeId;
    model.parentGroupGoalId = this.parentGroupGoalId;

    this.groupGoalService.addGroupGoal(model).subscribe((groupGoal) => {
        this.data.loading = false;
        this.added.emit(groupGoal);
    }, (error) => {
        console.log(error);
        this.data.loading = false;
    });        
};

deleteGroupGoal() {
    if (this.groupGoal.id == 0)
    {
        this.deleted.emit(this.groupGoal.id);
        return;
    }

    if (confirm("Biztosan törli a csoportcélt?")) {
        this.data.loading = true;
        this.groupGoalService.deleteGroupGoal(this.groupGoal.id).subscribe({
            next: (result) => {
                this.data.loading = false;
                this.deleted.emit(this.groupGoal.id);
            },
            error: (e) => {
                this.data.loading = false;
                
                this.msgService.showSnackBarError('A csapatcél nem törölhető mert már létezik hozzá kapcsolódó cél vagy csapatcél!', 7000);
            },
            complete: () => {} 
        });
    }
};  

updateGroupGoal() {
    if (this.goalText) {
        let model = {...this.groupGoal};
        model.goalText.name = this.goalText;
        model.goalText.goalType = GoalTypeEnum.GroupGoal;
        model.parentGroupGoalId = this.parentGroupGoalId;

        this.data.loading = true;
        this.groupGoalService.updateGroupGoal(model).subscribe((result) => {
            this.data.loading = false;
            this.updated.emit(model);
        }, (error) => {
            if (confirm('Hiba történt a mentés során, megpróbálja újra?')) {
                this.updateGroupGoal();
            }
            console.log(error);
            this.data.loading = false;
        });
    }
};

groupGoalChanged(e: any) {    
    this.saveGroupGoal();
}

parentGroupGoalChanged(parentGroupGoal: GroupGoal) {
    if (!parentGroupGoal)
    {
        this.parentGroupGoalId = null;
    }
    else
    {
        this.parentGroupGoalId = parentGroupGoal.id;
    }

    this.saveGroupGoal();
}

saveGroupGoal() {
  if (this.goalTextId || this.goalText) {
    this.groupGoal.id ? this.updateGroupGoal() : this.addGroupGoal();
  }
}



}
