import { fromEvent, map, Observable, startWith } from "rxjs";

export abstract class ViewHelper {

    public static scroll(el: HTMLElement) {
        el.scrollIntoView({behavior:'smooth', block: 'center' });
    }

    public static media(query: string): Observable<boolean> {
        const mediaQuery = window.matchMedia(query);
        return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
          startWith(mediaQuery),
          map((list: MediaQueryList) => list.matches)
        );
    }

    public static scrollToTop() {
      window.scrollTo({ top: 0, behavior:'smooth' });
    }
}