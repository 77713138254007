<mat-card class="question alert {{ getQuestionStateCss(textblock) }}" *ngIf="!isGroup" #questionElement
        [ngClass]="{
            'card-light': !isInnerQuestion, 
            'card-inner-question' : isInnerQuestion,
            'question-current' : isCurrent,
            'question-inactive' : isInactive
        }">
    <div class="header-actions">
        <div class="header-buttons">
            <button mat-icon-button color="primary" class="icon-bt-md" (click)="showEditor(textblock)" *ngIf="editable">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </div>    
    <mat-card-content>
        <!-- quesionIndex: {{ textblock.questionIndex}} current: {{ currentQuestionIndex }} -->
        <div class="question-content" 
                [ngClass]="{
                    'flex-h': !(isInnerQuestion && textblock.reviewType === reviewTypes.MilioStressMap),
                    'ratings-only': !textblock.name, 
                    'complex-question': isComplexQuestion, 
                    'inner-question': isInnerQuestion,
                    'ratings-to-bottom': !isInnerQuestion }">
            <div class="title-panel">
                <mat-card-title class="question-title-bar" [ngClass]="{'vertical-title': isInnerQuestion && textblock.reviewType === reviewTypes.MilioStressMap}">
                    <div>
                        <span class="line-index">{{ getQuestionIndex() }}</span> <span *ngIf="questionOptions.showId">{{ '[' + textblock.id + '] '}}</span>
                        <span class="line-index">{{ getQuestionCategoryName(textblock.questionCategoryId) }}</span>
                        <span [ngClass]="{
                            'font-semibold': isInnerQuestion && textblock.reviewType === reviewTypes.MilioStressMap
                        }" [innerHtml]="showHtmlContent(textblock.name)"> </span> <app-toggle-button [block]="dyn" *ngIf="textblock.description"></app-toggle-button>
                    </div>
                    <app-text-edit-panel [content]="textblock.name" *ngIf="titleEditMode" (ok)="editTitleTextOk($event, textblock)" (cancel)="editTextCancel()"></app-text-edit-panel>                       
                    
                </mat-card-title>
                <mat-card-subtitle><dynamic-text #dyn class="no-bottom-margin">{{ textblock.description }}</dynamic-text></mat-card-subtitle>
            </div>
            <div class="rating-panel" [ngClass]="{'flex-h': textblock.isMinMaxValueTitle}">
                <div class="min-title value-text" *ngIf="textblock.isMinMaxValueTitle">{{ getFirstRatingTitle() }}</div>
                <mat-radio-group [disabled]="isInactive || saveInProgress"
                    class="radio-frame"
                    *ngIf="!isComplexQuestion && isSingleAnswer && ratings" 
                    aria-label="Válasszon" 
                    class="{{ 'rad-' + textblock.reviewType }}" 
                    [ngClass]="{
                        'radiobuttons-with-text': !textblock.isMinMaxValueTitle,
                        'radiobuttons-with-no-text': textblock.isMinMaxValueTitle,
                        'isVerticalRadio': isCompetencyAndActivityReview || isPersBlock3And4
                    }"
                    [name]="'q' + textblock.id" 
                    [(ngModel)]="textblock.assessmentData.answerId" 
                    (ngModelChange)="onRatingChange($event, textblock)" >
                                                                                                         
                    <mat-radio-button *ngFor="let rating of ratings" [value]="rating.id" [title]="rating.name" [ngClass]="{'radio-full-width': isPersBlock3And4}">{{ displayRatingName(rating) }}</mat-radio-button>
                    <mat-radio-button class="notChosing" [value]="noAnswer" *ngIf="isCompetencyAndActivityReview">nem választok</mat-radio-button>
                </mat-radio-group>
                <div class="max-title value-text" *ngIf="textblock.isMinMaxValueTitle">{{ getLastRatingTitle() }}</div>
            </div>
            <div class="flex-h radio-labels" *ngIf="textblock.isMinMaxValueTitle">
                <div class="min-title value-text">{{ getFirstRatingTitle() }}</div>
                <div class="max-title value-text">{{ getLastRatingTitle() }}</div>
            </div>

            <div class="text-center">{{ getSelectedRatingText() }}&nbsp;</div>
        </div>

        <div *ngIf="isComplexQuestion">
            <app-question #complexChildren *ngFor="let tb of textblock.childQuestions; index as i" [textblock]="tb" 
            [editable]="editable" 
            [index]="i+1" 
            [parentIndexList]="getIndexList()"
            [parentTextblock]="textblock" 
            [questionOptions]="questionOptions" 
            [level]="level+1"></app-question>
        </div>
            
    </mat-card-content>
</mat-card>

<div class="{{ 'question-level-' + level }} {{ 'question-' + textblock.id }}" *ngIf="isGroup">
    
    <mat-card class="card-group card-light" *ngIf="textblock.name || editable">
        <div class="header-actions">
            <div class="header-buttons">
                <button mat-icon-button color="primary" class="icon-bt-md" (click)="showEditor(textblock)" *ngIf="editable">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
        <mat-card-content>
            <div>
                <span *ngIf="questionOptions.showId">{{ '[' + textblock.id + '] '}}</span>
                <div class="group-text group-level-{{ level }}" [innerHtml]="showGroupHtmlContent(textblock.name)"> </div> <app-toggle-button [block]="groupDyn" *ngIf="textblock.description"></app-toggle-button>
            </div>

            <app-text-edit-panel [content]="textblock.name" *ngIf="titleEditMode" (ok)="editTitleTextOk($event, textblock)" (cancel)="editTextCancel()"></app-text-edit-panel>
           
            <div *ngIf="textblock.description">
                <dynamic-text #groupDyn class="no-bottom-margin" [opened]="true"><em>{{ textblock.description }}</em></dynamic-text>
            </div>
        </mat-card-content>
    </mat-card>

    <div [ngClass]="{'question-2col-layout': textblock.is2col }">
        <app-question #groupChildren *ngFor="let tb of textblock.childQuestions; index as i" 
            [textblock]="tb" 
            [editable]="editable" 
            [index]="i+1" 
            [parentIndexList]="getIndexList()"
            [parentTextblock]="textblock" 
            [questionOptions]="questionOptions" [level]="level+1"></app-question>
    </div>

</div>
