import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISelectorListItem } from 'src/app/shared/models/selector-list-item.model';

@Component({
  selector: 'app-selector-dialog',
  templateUrl: './selector-dialog.component.html',
  styleUrls: ['./selector-dialog.component.scss']
})
export class SelectorDialogComponent implements OnInit {

  title: string;
  items: ISelectorListItem[] = [];
  selected: ISelectorListItem;

  constructor(public dialogRef: MatDialogRef<SelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISelectorDialogModel) { 
      this.title = data.title;
      this.items = data.items;
      this.selected = data.selected;
    }

  ngOnInit(): void {
  }

  onSelect(item: ISelectorListItem): void {
    // Close the dialog, return true
    let res = {
      selectedItem: item.id ? item.id : null
    } as ISelectorDialogResult;

    this.dialogRef.close(res);
  }
}

export interface ISelectorDialogResult {
  selectedItem?: number;
}

export interface ISelectorDialogModel {
  title: string;
  selected: ISelectorListItem;
  items: ISelectorListItem[];
}
