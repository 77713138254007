import { BehaviorSubject, Observable } from "rxjs";

export class StateValue<T> {
    private subject: BehaviorSubject<T>;
    public readonly obs$: Observable<T>;

    public get value(): T {
        return this.subject.getValue();
    }

    public set value(val: T) {
        this.subject.next(val);
    }
    
    constructor(initVal: T) {
        this.subject = new BehaviorSubject<T>(initVal);
        this.obs$ = this.subject.asObservable();
    }
}