<div *ngIf="textGuide">
    <div class="title-panel">
        <div class="guide-logo">
            <img [src]="textGuide.logoLink" [alt]="textGuide.title">
        </div>
        <div class="guide-head-text">
            <h1>
                <span [innerHTML]="textGuide.title"></span><sup *ngIf="textGuide.titleIndexText">{{ textGuide.titleIndexText }}</sup>
            </h1>
            <div class="subtitle" [innerHTML]="textGuide.subtitle"></div>
        </div>
    </div>
                
    <section class="guide-body flex-h">
        <div class="left-panel">
            <dynamic-text [opened]="true" [useCard]="false" [content]="textGuide.urls[0]"></dynamic-text>
        </div>
        <div class="right-panel" [ngStyle]="{'flex': '0 0 ' + (textGuide.rightPanelRatio ?? 45) + '%' }">
            <dynamic-text [opened]="true" [useCard]="false" [content]="textGuide.urls[1]"></dynamic-text>
        </div>
    </section>
</div>
