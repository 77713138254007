<div class="container main-container">

    <mat-card class="card-light">
        <mat-card-title>
            Felhasználó törlési folyamat állapota    
        </mat-card-title>
        <mat-card-content>
            <ng-container *ngIf="result">
                <div *ngIf="msg === ''">
                    <p>A törlési folyamat sikeresen leállítva!</p>
                </div>
        
                <div *ngIf="msg">
                    <p>{{ msg }}</p>
                </div>
            </ng-container>
        
            
        </mat-card-content>
        <mat-card-footer>
            <div class="action-buttons">
                <a mat-flat-button [routerLink]="['/']" color="primary">
                    Vissza a főoldalra
                </a>
            </div>
        </mat-card-footer>
    </mat-card>
        
</div>
